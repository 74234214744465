/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Program2, Program2FromJSON, Program2ToJSON } from './'

/**
 *
 * @export
 * @interface ListStudent
 */
export interface ListStudent {
  /**
   * The unique identifier of the student.
   * @type {number}
   * @memberof ListStudent
   */
  studentKey: number
  /**
   * The first name of the student.
   * @type {string}
   * @memberof ListStudent
   */
  firstName: string
  /**
   * The date of birth of the student.
   * @type {Date}
   * @memberof ListStudent
   */
  dateOfBirth: Date
  /**
   * The number indicating student's month of birth. Indexed from 0 to 11.
   * @type {number}
   * @memberof ListStudent
   */
  birthMonth: number
  /**
   * The number indicating student's year of birth.
   * @type {number}
   * @memberof ListStudent
   */
  birthYear: number
  /**
   * The programs a student has been associated to.
   * @type {Array<Program2>}
   * @memberof ListStudent
   */
  programs: Array<Program2>
}

export function ListStudentFromJSON(json: any): ListStudent {
  return ListStudentFromJSONTyped(json, false)
}

export function ListStudentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ListStudent {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentKey: json['studentKey'],
    firstName: json['firstName'],
    dateOfBirth: new Date(json['dateOfBirth']),
    birthMonth: json['birthMonth'],
    birthYear: json['birthYear'],
    programs: (json['programs'] as Array<any>).map(Program2FromJSON),
  }
}

export function ListStudentToJSON(value?: ListStudent | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    studentKey: value.studentKey,
    firstName: value.firstName,
    dateOfBirth: value.dateOfBirth.toISOString().substr(0, 10),
    birthMonth: value.birthMonth,
    birthYear: value.birthYear,
    programs: (value.programs as Array<any>).map(Program2ToJSON),
  }
}
