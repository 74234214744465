/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  AgreementCreate,
  AgreementCreateToJSON,
  AgreementPreview,
  AgreementPreviewFromJSON,
  AgreementRecord,
  AgreementRecordFromJSON,
  AgreementSigningUrl,
  AgreementSigningUrlFromJSON,
  AgreementStatus,
  AgreementStatusFromJSON,
  AgreementTemplateFormFields,
  AgreementTemplateFormFieldsFromJSON,
  CompleteAgreementResponse,
  CompleteAgreementResponseFromJSON,
} from '../models'
import * as runtime from '../runtime'

export interface CompleteAgreementRequest
  extends runtime.BaseRequestParameters {
  uuid: string
}

export interface CreateAgreementRequest extends runtime.BaseRequestParameters {
  body?: AgreementCreate
}

export interface DeleteAgreementRequest extends runtime.BaseRequestParameters {
  agreementId: string
}

export interface FetchAgreementPreviewRequest
  extends runtime.BaseRequestParameters {
  agreementId: string
}

export interface FetchAgreementSigningUrlRequest
  extends runtime.BaseRequestParameters {
  agreementId: string
}

export interface FetchAgreementStatusRequest
  extends runtime.BaseRequestParameters {
  agreementId: string
}

export interface FetchAgreementTemplateFormFieldsRequest
  extends runtime.BaseRequestParameters {
  agreementTemplateId: string
  agreementDocumentId: string
}

/**
 *
 */
export class AgreementsApi extends runtime.BaseAPI {
  /**
   * This marks an agreement as completed and creates an actor record for the user with new role, in the contracted validity window.  The logged in user must be the user associated with the signerUserKey on the agreementRecord and the agreement must be active and signed.  This also adds the new actorKey to the session and updates the lastSelectActorKey value, so the user can have access to there new role.  The endpoint returns the userKey and the new actorKey in order to update the login session on the FE
   * Complete an open agreement.
   */
  async completeAgreementRaw(
    requestParameters: CompleteAgreementRequest
  ): Promise<runtime.ApiResponse<CompleteAgreementResponse>> {
    if (
      requestParameters.uuid === null ||
      requestParameters.uuid === undefined
    ) {
      throw new runtime.RequiredError(
        'uuid',
        'Required parameter requestParameters.uuid was null or undefined when calling completeAgreement.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/agreements/{uuid}/complete`.replace(
        `{${'uuid'}}`,
        encodeURIComponent(String(requestParameters.uuid))
      ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CompleteAgreementResponseFromJSON(jsonValue)
    )
  }

  /**
   * This marks an agreement as completed and creates an actor record for the user with new role, in the contracted validity window.  The logged in user must be the user associated with the signerUserKey on the agreementRecord and the agreement must be active and signed.  This also adds the new actorKey to the session and updates the lastSelectActorKey value, so the user can have access to there new role.  The endpoint returns the userKey and the new actorKey in order to update the login session on the FE
   * Complete an open agreement.
   */
  async completeAgreement(
    requestParameters: CompleteAgreementRequest
  ): Promise<CompleteAgreementResponse> {
    const response = await this.completeAgreementRaw(requestParameters)
    return await response.value()
  }

  /**
   * Create a new Agreement.
   */
  async createAgreementRaw(
    requestParameters: CreateAgreementRequest
  ): Promise<runtime.ApiResponse<AgreementRecord>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/agreements`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: AgreementCreateToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AgreementRecordFromJSON(jsonValue)
    )
  }

  /**
   * Create a new Agreement.
   */
  async createAgreement(
    requestParameters: CreateAgreementRequest
  ): Promise<AgreementRecord> {
    const response = await this.createAgreementRaw(requestParameters)
    return await response.value()
  }

  /**
   * Cancel the adobe sign record and delete an agreement from agreement records that currently has a status of `draft`.
   */
  async deleteAgreementRaw(
    requestParameters: DeleteAgreementRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.agreementId === null ||
      requestParameters.agreementId === undefined
    ) {
      throw new runtime.RequiredError(
        'agreementId',
        'Required parameter requestParameters.agreementId was null or undefined when calling deleteAgreement.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/agreements/{agreementId}`.replace(
        `{${'agreementId'}}`,
        encodeURIComponent(String(requestParameters.agreementId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Cancel the adobe sign record and delete an agreement from agreement records that currently has a status of `draft`.
   */
  async deleteAgreement(
    requestParameters: DeleteAgreementRequest
  ): Promise<void> {
    await this.deleteAgreementRaw(requestParameters)
  }

  /**
   * Returns a download link for a pdf preview for the specified agreementId.
   */
  async fetchAgreementPreviewRaw(
    requestParameters: FetchAgreementPreviewRequest
  ): Promise<runtime.ApiResponse<AgreementPreview>> {
    if (
      requestParameters.agreementId === null ||
      requestParameters.agreementId === undefined
    ) {
      throw new runtime.RequiredError(
        'agreementId',
        'Required parameter requestParameters.agreementId was null or undefined when calling fetchAgreementPreview.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/agreements/{agreementId}/preview`.replace(
        `{${'agreementId'}}`,
        encodeURIComponent(String(requestParameters.agreementId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AgreementPreviewFromJSON(jsonValue)
    )
  }

  /**
   * Returns a download link for a pdf preview for the specified agreementId.
   */
  async fetchAgreementPreview(
    requestParameters: FetchAgreementPreviewRequest
  ): Promise<AgreementPreview> {
    const response = await this.fetchAgreementPreviewRaw(requestParameters)
    return await response.value()
  }

  /**
   * Returns the signing url for an agreement.
   */
  async fetchAgreementSigningUrlRaw(
    requestParameters: FetchAgreementSigningUrlRequest
  ): Promise<runtime.ApiResponse<AgreementSigningUrl>> {
    if (
      requestParameters.agreementId === null ||
      requestParameters.agreementId === undefined
    ) {
      throw new runtime.RequiredError(
        'agreementId',
        'Required parameter requestParameters.agreementId was null or undefined when calling fetchAgreementSigningUrl.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/agreements/{agreementId}/signingUrl`.replace(
        `{${'agreementId'}}`,
        encodeURIComponent(String(requestParameters.agreementId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AgreementSigningUrlFromJSON(jsonValue)
    )
  }

  /**
   * Returns the signing url for an agreement.
   */
  async fetchAgreementSigningUrl(
    requestParameters: FetchAgreementSigningUrlRequest
  ): Promise<AgreementSigningUrl> {
    const response = await this.fetchAgreementSigningUrlRaw(requestParameters)
    return await response.value()
  }

  /**
   * Returns the current status for an agreement for the specified agreementId.
   */
  async fetchAgreementStatusRaw(
    requestParameters: FetchAgreementStatusRequest
  ): Promise<runtime.ApiResponse<AgreementStatus>> {
    if (
      requestParameters.agreementId === null ||
      requestParameters.agreementId === undefined
    ) {
      throw new runtime.RequiredError(
        'agreementId',
        'Required parameter requestParameters.agreementId was null or undefined when calling fetchAgreementStatus.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/agreements/{agreementId}/status`.replace(
        `{${'agreementId'}}`,
        encodeURIComponent(String(requestParameters.agreementId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AgreementStatusFromJSON(jsonValue)
    )
  }

  /**
   * Returns the current status for an agreement for the specified agreementId.
   */
  async fetchAgreementStatus(
    requestParameters: FetchAgreementStatusRequest
  ): Promise<AgreementStatus> {
    const response = await this.fetchAgreementStatusRaw(requestParameters)
    return await response.value()
  }

  /**
   * List of form fields that can be prefilled for an Adobe Sign agreement.
   */
  async fetchAgreementTemplateFormFieldsRaw(
    requestParameters: FetchAgreementTemplateFormFieldsRequest
  ): Promise<runtime.ApiResponse<AgreementTemplateFormFields>> {
    if (
      requestParameters.agreementTemplateId === null ||
      requestParameters.agreementTemplateId === undefined
    ) {
      throw new runtime.RequiredError(
        'agreementTemplateId',
        'Required parameter requestParameters.agreementTemplateId was null or undefined when calling fetchAgreementTemplateFormFields.'
      )
    }

    if (
      requestParameters.agreementDocumentId === null ||
      requestParameters.agreementDocumentId === undefined
    ) {
      throw new runtime.RequiredError(
        'agreementDocumentId',
        'Required parameter requestParameters.agreementDocumentId was null or undefined when calling fetchAgreementTemplateFormFields.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/agreements/templates/{agreementTemplateId}/{agreementDocumentId}/formFields`
        .replace(
          `{${'agreementTemplateId'}}`,
          encodeURIComponent(String(requestParameters.agreementTemplateId))
        )
        .replace(
          `{${'agreementDocumentId'}}`,
          encodeURIComponent(String(requestParameters.agreementDocumentId))
        ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AgreementTemplateFormFieldsFromJSON(jsonValue)
    )
  }

  /**
   * List of form fields that can be prefilled for an Adobe Sign agreement.
   */
  async fetchAgreementTemplateFormFields(
    requestParameters: FetchAgreementTemplateFormFieldsRequest
  ): Promise<AgreementTemplateFormFields> {
    const response = await this.fetchAgreementTemplateFormFieldsRaw(
      requestParameters
    )
    return await response.value()
  }
}
