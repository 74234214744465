import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import { useTranslation } from 'react-i18next'
import CardFormHeader from '../Card/CardFormHeader'
import AmountDue from '../Account/Billing/AmountDue'
import getLocaleCurrencyForAmount from '../../utils/getLocaleCurrencyForAmount'
import {
  CashAmount,
  InvoiceInfo,
  OrderByDirection,
  PaginationResponse,
} from '../../swagger'
import { dateToSlashString } from '../../utils/dateUtility'
import { useAuth } from '../Routes/AuthProvider'
import { Action } from '../Table/RowActions'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ConfirmationModal from '../Modals/ConfirmationModal'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import { TuitionPaymentAction } from './FamilyProfile'
import {
  ActionableTable,
  ActionableTableColumn,
} from '../Table/ActionableTable'
import { GridValueFormatterParams } from '@mui/x-data-grid'
import { Collapse, Paper, Typography, useTheme } from '@mui/material'
import WrappedAlert from '../Alerts/WrappedAlert'
import useAlert from '../../hooks/useAlert'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { statusTour, tourConfig } from './InvoiceDirectorMenu'
import { InvoicePaymentModalVariant } from '../Modals/InvoicePaymentModal'

export interface TuitionBillingRow {
  date: Date
  transaction: string
  program: string
  description: string
  amount: CashAmount
  paymentKey?: number
  isDirectorDiscount?: boolean
  handleClick: () => void
}

interface TuitionBillingTableProps {
  tuitionBillingData: TuitionBillingRow[]
  ariaLabelledBy?: string
  performTuitionPaymentAction: ({
    action,
    paymentData,
    callback,
    paymentKey,
  }: TuitionPaymentAction) => Promise<void>
  invoice: InvoiceInfo
  invoiceDirectorMenu?: JSX.Element
  navigateToInvoice: () => void
  addTour?: boolean
  isTourFinished?: boolean
  handleTourFinished?: (value: boolean) => void
  updateVariantForDelete?: (value: number) => void
}

/**
 * Shows a Program Director/Tutor their history of invoices sent to families and any payments received.
 * Table located in UI under Family > Family Profile.
 */
const TuitionBillingTable: React.FC<TuitionBillingTableProps> = ({
  tuitionBillingData,
  performTuitionPaymentAction,
  invoice,
  navigateToInvoice,
  invoiceDirectorMenu,
  addTour,
  isTourFinished,
  handleTourFinished,
  updateVariantForDelete,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const pageSize = 10
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSize)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [selectedPaymentKey, setSelectedPaymentKey] = useState<number>()
  const [isDiscount, setIsDiscount] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState(true)
  const { permissionAbility } = useAuth()
  const showOnDesktop = useShowOnDesktop()

  const canSendTuitionInvoice = permissionAbility.can(
    'sendTuitionInvoice',
    'Payment'
  )

  const handleChangePage = (pagination: PaginationResponse) => {
    setPage(pagination.page)
    setRowsPerPage(pagination.pageSize)
  }

  const actionableTableColumnsHeader: ActionableTableColumn[] = [
    {
      fieldName: 'date',
      columnHeaderName: t('TuitionBillingTable.TableHeader.Date', 'Date'),
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        return `${dateToSlashString(
          params.value
        )}, ${params.value.toLocaleString(undefined, {
          timeStyle: 'short',
        } as Intl.DateTimeFormatOptions)}`
      },
    },
    {
      fieldName: 'transaction',
      columnHeaderName: t(
        'TuitionBillingTable.TableHeader.Transaction',
        'Transaction'
      ),
    },
    {
      fieldName: 'program',
      columnHeaderName: t(
        'TuitionBillingTable.TableHeader.Program',
        'Program(s)'
      ),
    },
    {
      fieldName: 'description',
      columnHeaderName: t(
        'TuitionBillingTable.TableHeader.Description',
        'Description'
      ),
    },
    {
      fieldName: 'amount',
      columnHeaderName: t('TuitionBillingTable.TableHeader.Amount', 'Amount'),
      valueFormatter: (params: GridValueFormatterParams<CashAmount>) => {
        return getLocaleCurrencyForAmount(
          params.value.amount,
          params.value.currencyCode
        )
      },
    },
  ]

  const rowActions: Action<TuitionBillingRow>[] = [
    {
      actionName: t('TuitionBillingTable.RowActions.View', 'Edit'),
      actionKey: 'edit',
      actionFunction: (row) => row?.handleClick(),
      menuItemIcon: <EditIcon />,
    },
    {
      actionName: t('TuitionBillingTable.RowActions.Delete', 'Delete'),
      actionKey: 'delete',
      actionFunction: (row) => {
        setIsDiscount(row?.isDirectorDiscount ?? false)
        setIsConfirmationOpen(true)
        setSelectedPaymentKey(row?.paymentKey)
        updateVariantForDelete?.(
          row?.isDirectorDiscount
            ? InvoicePaymentModalVariant.DeleteDiscount
            : InvoicePaymentModalVariant.DeletePayment
        )
      },
      menuItemIcon: <DeleteIcon />,
      hide: () => {
        return !permissionAbility.can(
          isDiscount ? 'deleteTuitionCredit' : 'deleteTuitionPayment',
          'Payment'
        )
      },
    },
  ]

  const tableData =
    rowsPerPage > 0
      ? tuitionBillingData.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : tuitionBillingData

  const confirmationModalTitle = t(
    'TuitionBillingTable.ConfirmationModal.AriaLabel',
    '{{title}}',
    {
      title: `Are you sure you want to delete this ${
        isDiscount ? 'discount' : 'payment'
      }?`,
    }
  )
  const hideAlert = () => {
    setShowAlert(false)
  }
  const invoiceTitle = t('TuitionBillingTable.header.Title', 'Invoice')

  const { message, severity, labelButton, colorButton, onClick } = useAlert({
    lastSendEmail: !!invoice ? invoice.lastSentEmailDate : undefined,
    lastInvoiceLineModifiedDate: !!invoice
      ? invoice.lastInvoiceLineModifiedDate
      : undefined,
    navigateToInvoice,
    hideAlert,
  })

  const isTabInvitesTourCompleted = localStorage.getItem(
    tourConfig.joyRideTourId
  )
  const stepsForInvoice = localStorage.getItem(
    tourConfig.invoiceStepsCompletionStatus
  )
  let rowActionsTourSteps = undefined
  /**
   * If only one step has been shown, let's show the second step once
   * a payment has been added.
   */

  const steps = !!stepsForInvoice
    ? (JSON.parse(stepsForInvoice ?? '') as statusTour)
    : { stepOne: false, stepTwo: false }

  if (steps.stepOne && !steps.stepTwo) {
    localStorage.removeItem(tourConfig.joyRideTourId)
    rowActionsTourSteps = [
      {
        target: `.${tourConfig.tourClassForInvoiceTableMenu}`,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        title: t(
          'TuitionBillingTable.Tour.StepOne.Title',
          `View button has moved!`
        ),
        content: t(
          'TuitionBillingTable.Tour.StepOne.Content',
          `Click here to see new Edit button.`
        ),
        disableBeacon: true,
        disableScrolling: false,
      },
    ]
  }

  // Take necessary columns for tour in mobile version
  let actionableTableColumns = actionableTableColumnsHeader

  if (addTour && !showOnDesktop) {
    if (!isTabInvitesTourCompleted && !isTourFinished) {
      actionableTableColumns =
        (invoice.payments ?? []).length > 0
          ? actionableTableColumnsHeader.slice(-1)
          : actionableTableColumnsHeader
    }
  }

  const completeSteps = () => {
    localStorage.setItem(
      tourConfig.invoiceStepsCompletionStatus,
      JSON.stringify({ stepOne: true, stepTwo: true })
    )
    handleTourFinished?.(true)
  }

  return (
    <Card aria-label={`invoiceCard-${invoice?.invoiceKey}`}>
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        ariaLabel={confirmationModalTitle}
        dialogTitle={confirmationModalTitle}
        dialogContent={null}
        dialogActions={
          <ActionButtons
            primaryButtonLabel={ContainedButtonVariant.YesDelete}
            secondaryButtonLabel={TextButtonVariant.NoCancel}
            secondaryClick={() => setIsConfirmationOpen(false)}
          />
        }
        handleFormSubmit={async (e) => {
          e.preventDefault()
          await performTuitionPaymentAction({
            action: 'delete',
            paymentKey: selectedPaymentKey,
            callback: () => {
              setIsConfirmationOpen(false)
            },
          })
        }}
      />
      <Box mt={2.5} mx={2.5}>
        <CardFormHeader
          header={
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              {!!invoice && (
                <Box pr={1} mb={2}>
                  <Typography component="p" variant="h5">
                    {invoiceTitle} #{invoice.invoiceKey}
                  </Typography>
                </Box>
              )}
              <Box>
                <AmountDue
                  amountDue={invoice?.amountOwed?.amount ?? 0}
                  currencyCode={invoice?.amountOwed?.currencyCode ?? 'USD'}
                />
              </Box>
              {canSendTuitionInvoice && !!invoice && (
                <Collapse in={showAlert}>
                  <Paper
                    elevation={0}
                    sx={{
                      width: 'clamp(100%, 40vw, 50vw)',
                      transition: 'width 0.3s, background-color 0.3s',
                      marginTop: theme.spacing(3),
                      marginBottom: theme.spacing(3),
                    }}
                  >
                    <WrappedAlert
                      severity={severity}
                      message={message}
                      forAction={
                        <TextButton
                          id="buttonActionAlert"
                          variant={labelButton}
                          onClick={onClick}
                          css={{ color: colorButton }}
                        />
                      }
                    />
                  </Paper>
                </Collapse>
              )}
            </Box>
          }
          menu={invoiceDirectorMenu}
          menuContainerCss={{
            [theme.breakpoints.down('sm')]: {
              right: theme.spacing(4),
              position: 'absolute',
            },
          }}
        />
      </Box>
      <ActionableTable
        columns={actionableTableColumns}
        rows={tableData}
        rowActionsTourSteps={rowActionsTourSteps}
        joyRideTourId={addTour ? tourConfig.joyRideTourId : undefined}
        isTourCompleted={addTour ? isTourFinished ?? false : undefined}
        onCompleteTour={completeSteps}
        /**
         * this is the second step of the tour, that's why
         * we only assign the identifier for the second step here
         */
        tourClass={`TuitionBillingTable-tour`}
        noResultsMessage={t(
          'TuitionBillingTable.Empty',
          'No activity. Log any payments that have been made.'
        )}
        rowActions={canSendTuitionInvoice ? rowActions : []}
        pagination={{
          page,
          pageSize,
          totalCount: tuitionBillingData.length,
          orderBy: [{ date: OrderByDirection.Asc }],
        }}
        handlePaginationChange={handleChangePage}
      />
    </Card>
  )
}

export default TuitionBillingTable
