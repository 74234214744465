import React from 'react'
import { useTheme } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import fullName from '../../utils/fullName'
import {
  CommunityListing,
  CommunityListingProgramsEnum,
} from '../../swagger/models/CommunityListing'
import formatAddress from '../../utils/formatAddress'
import Tooltip from '@mui/material/Tooltip'
import { useNavigate } from 'react-router'
import Header from '../Elements/Header'
import TableFooterPagination from '../Pagination/TableFooterPagination'
import TableHeader from '../Table/TableHeader'
import TableHeaders from '../Interfaces/TableHeaders'
import { Box } from '@mui/material'
import { useCommunitiesContext } from '../Context/CommunitiesContext'

interface CommunitySummaryTableProps {
  communities: CommunityListing[]
  title?: string
  id?: string
  pageSize: number
  totalCount: number
  handleRowsPerPage: (rowAmount: number) => void
  onPageChange: (newPage: number) => void
  selectedYear?: number
  pagination?: boolean
  resetTablePagination: (newPage: number) => void
  tableFooterPage: number
}

const baseTen = 10

const CommunitySummaryTable: React.FC<CommunitySummaryTableProps> = ({
  title,
  communities,
  pageSize,
  onPageChange,
  totalCount,
  handleRowsPerPage,
  pagination,
  resetTablePagination,
  tableFooterPage,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const { updateSelectedCommunityKey } = useCommunitiesContext()

  const toCommunityDetails = (communityKey: number, communityName: string) => {
    updateSelectedCommunityKey(communityKey)
    navigate(
      {
        pathname: `/communities/community-details/${communityKey}/overview`,
      },
      {
        state: { communityKey, communityName },
      }
    )
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    onPageChange(newPage + 1)
    resetTablePagination(newPage)
  }

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleRowsPerPage(parseInt(event.target.value, baseTen))
  }

  const tableHeaders: TableHeaders[] = [
    {
      label: t('Communities.CommunitiesTable.Community.Header', 'Community'),
      align: 'left',
      cssProps: {
        minWidth: '174px',
        color: theme.palette.textOrIcon.tableHeader,
        padding: theme.spacing(2, 1, 2, 2),
      },
    },
    {
      label: t('Communities.CommunitiesTable.Id.Header', 'Id'),
      align: 'left',
      cssProps: {
        minWidth: '174px',
        color: theme.palette.textOrIcon.tableHeader,
        padding: theme.spacing(2, 1, 2, 2),
      },
    },
    {
      label: t('Communities.CommunitiesTable.Address.Header', 'Address'),
      align: 'left',
      cssProps: {
        minWidth: '266px',
        color: theme.palette.textOrIcon.tableHeader,
        padding: theme.spacing(2, 1),
      },
    },
    {
      label: t('Communities.CommunitiesTable.SupportRep.Header', 'Support Rep'),
      align: 'left',
      cssProps: {
        minWidth: '174px',
        color: theme.palette.textOrIcon.tableHeader,
        padding: theme.spacing(2, 1),
      },
    },
    {
      label: t('Communities.CommunitiesTable.Programs.Header', 'Programs'),
      align: 'left',
      cssProps: {
        minWidth: '88px',
        color: theme.palette.textOrIcon.tableHeader,
        padding: theme.spacing(2, 2, 2, 1),
      },
    },
  ]

  const commonIconProps = {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    '&:nth-of-type(1)': {
      marginLeft: 0,
    },
  }

  return (
    <section aria-labelledby={title}>
      <Header id={title} headerName={title} component="h3" />
      {communities.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{
            marginRight: theme.spacing(2),
          }}
        >
          <Table aria-labelledby={title}>
            <TableHead
              sx={{
                padding: theme.spacing(2),
              }}
            >
              <TableHeader tableHeaders={tableHeaders} />
            </TableHead>
            <TableBody>
              {communities?.map((row) => (
                <TableRow
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  key={row.id}
                  onClick={() => toCommunityDetails(row.id, row.name)}
                >
                  <TableCell
                    sx={{
                      minWidth: '174px',
                      color: theme.palette.primary.main,
                      padding: theme.spacing(2, 1, 2, 2),
                    }}
                  >
                    <Typography variant="body1" component="p">
                      {row.name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: '174px',
                      color: theme.palette.primary.main,
                      padding: theme.spacing(2, 1, 2, 2),
                    }}
                  >
                    <Typography variant="body1" component="p">
                      {row.id}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: '266px',
                      color: theme.palette.primary.main,
                      padding: theme.spacing(2, 1),
                    }}
                  >
                    <Typography variant="body1" component="p">
                      {formatAddress(row.address)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: '88px',
                      color: theme.palette.primary.main,
                      padding: theme.spacing(2, 2, 2, 1),
                    }}
                  >
                    <Typography variant="body1" component="p">
                      {fullName(row.supportRepresentative)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    data-testid="programsCommunities"
                    sx={{
                      minWidth: '88px',
                      color: theme.palette.primary.main,
                      padding: theme.spacing(2, 2, 2, 1),
                    }}
                  >
                    {row.programs.includes(
                      CommunityListingProgramsEnum.Foundations
                    ) ? (
                      <Tooltip title="Foundations">
                        <Box
                          sx={{
                            ...commonIconProps,
                            backgroundColor: theme.palette.program.foundations,
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Box
                        sx={{
                          ...commonIconProps,
                        }}
                      />
                    )}
                    {row.programs.includes(
                      CommunityListingProgramsEnum.Essentials
                    ) ? (
                      <Tooltip title="Essentials">
                        <Box
                          sx={{
                            ...commonIconProps,
                            backgroundColor: theme.palette.program.essentials,
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Box
                        sx={{
                          ...commonIconProps,
                        }}
                      />
                    )}
                    {row.programs.includes(
                      CommunityListingProgramsEnum.Challenge
                    ) ? (
                      <Tooltip title="Challenge">
                        <Box
                          sx={{
                            ...commonIconProps,
                            backgroundColor: theme.palette.program.challenge,
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Box
                        sx={{
                          ...commonIconProps,
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            {pagination && totalCount > pageSize && (
              <TableFooterPagination
                count={totalCount}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                page={tableFooterPage}
                rowsPerPage={pageSize}
                colSpan={tableHeaders.length}
              />
            )}
          </Table>
        </TableContainer>
      ) : (
        <Typography
          color={theme.palette.textOrIcon.noResultsFound}
          variant="subtitle2"
          component="p"
        >
          {t(
            'Communities.CommunitiesImEnrolledIn.Search.NoResultsFound',
            'No Results Found'
          )}
        </Typography>
      )}
    </section>
  )
}

export default CommunitySummaryTable
