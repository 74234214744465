import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import BasicModal from './BasicModal'
import { Box, MenuItem, TextField, Typography, useTheme } from '@mui/material'
import { TextButtonVariant } from '../Buttons/TextButton'
import {
  PaymentStartRequestBodyForTypeEnum,
  PaymentStatusKey,
  PaymentMethodKey,
} from '../../swagger'
import { dateToDashString } from '../../utils/dateUtility'
import ProgramOption from '../Interfaces/ProgramOption'
import { Payment } from '../Interfaces/Payment'
import { reinterpretYearMonthDayAsLocalTime } from '../../utils/reinterpretYearMonthDayAsLocalTime'
import { TFunction } from 'i18next'
import { amountFromInput } from '../../utils/amountFromInput'
import getLocaleCurrencyForAmount from '../../utils/getLocaleCurrencyForAmount'
import { getAmountFromLocaleCurrency } from '../../utils/getAmountFromLocalCurrency'
import DropDown, { DropDownVariant, MenuOption } from '../Menus/DropDown'
import stringEscapedForRegExp from '../../utils/stringEscapedForRegExp'
import { OperationIds } from '../../swagger/operationIdEnum'
import { LoadingContext } from '../Context/LoadingContext'
import useLoadingContext from '../../hooks/useLoadingContext'
import {
  useSnackbarContext,
  SnackbarSeverity,
} from '../Context/SnackbarContext'
import { extractedErrorObject, paymentsApi } from '../../api/swagger'

export enum TransactionOption {
  PaymentReceived = 'paymentReceived',
  IssueRefund = 'issueRefund',
}

export const emptyPayment = {
  paymentKey: -1,
  begunAt: new Date(),
  userKey: -1,
  paymentMethodKey: PaymentMethodKey.Adj,
  amount: {
    amount: 0,
    currencyCode: 'USD',
  },
  tax: {
    amount: 0,
    currencyCode: 'USD',
  },
  paymentStatusKey: PaymentStatusKey.Wip,
} as Payment

interface TransactionModalProps {
  userKey?: number
  isLicensingVariant: boolean
  isOpen: boolean
  programOptions: ProgramOption[]
  onClose: () => void
  payment?: Payment
  selectedPrograms?: ProgramOption[]
  licensingPaymentKey?: number
  enrollmentPaymentKey?: number
}

const getPaymentStatusKeyFromString = (statusKeyString: string) => {
  switch (statusKeyString) {
    case PaymentStatusKey.Err:
      return PaymentStatusKey.Err
    case PaymentStatusKey.Fail:
      return PaymentStatusKey.Fail
    case PaymentStatusKey.Held:
      return PaymentStatusKey.Held
    case PaymentStatusKey.Paid:
      return PaymentStatusKey.Paid
    case PaymentStatusKey.Pend:
      return PaymentStatusKey.Pend
    case PaymentStatusKey.Wip:
      return PaymentStatusKey.Wip
  }
}

export const getLabelForTransactionOption = (
  option: TransactionOption,
  t: TFunction
): string => {
  switch (option) {
    case TransactionOption.PaymentReceived:
      return t(
        'TransactionModal.TransactionOption.PaymentReceived',
        'Enter a Payment Received'
      )
    case TransactionOption.IssueRefund:
    default:
      return t('TransactionModal.TransactionOption.IssueRefund', 'Issue Refund')
  }
}

export const TransactionModal: React.FC<TransactionModalProps> = ({
  userKey,
  isLicensingVariant,
  isOpen,
  onClose,
  programOptions,
  payment,
  selectedPrograms,
}) => {
  const { t } = useTranslation()
  const filename = 'TransactionModal'
  const theme = useTheme()

  // If we have payment information, we're editing.
  const isEdit = !!payment
  // Manual transactions always have payment method N/A. We cannot delete these, and really, we shouldn't edit anything but Acumatica Order #
  // Design Ref: https://www.figma.com/file/mLwiCvECJxB1MIf7aT0PmZ/ProjectONE?node-id=15067%3A89926
  const isManualTransaction =
    !isEdit || (isEdit && payment?.paymentMethodKey === PaymentMethodKey.Adj)

  const getPaymentMethodFromKey = (key: string) => {
    switch (key) {
      case PaymentMethodKey.Ach:
        return t(
          'TransactionModal.MethodOfPayment.BankTransfer',
          'Bank Transfer'
        )
      case PaymentMethodKey.Cc:
        return t('TransactionModal.MethodOfPayment.CreditCard', 'Credit Card')
      default:
        return ''
    }
  }

  const [reasonVoidPayment, setReasonVoidPayment] = useState('')

  const paymentMethod = !!payment
    ? getPaymentMethodFromKey(payment.paymentMethodKey)
    : ''

  const transactionId = !!payment ? payment.transactionId : ''
  const paidBy = !!payment ? payment.paidBy : ''

  const transactionOptions = [
    {
      key: 1,
      name: getLabelForTransactionOption(TransactionOption.PaymentReceived, t),
    },
    {
      key: 2,
      name: getLabelForTransactionOption(TransactionOption.IssueRefund, t),
    },
  ]

  // If we have a transaction with a positive or zero amount: it is a payment, negative: it is a refund, no transaction: require a selection (default '')
  const [transactionType, setTransactionType] = useState(
    !!payment
      ? payment.amount.amount >= 0
        ? transactionOptions[0].name
        : transactionOptions[1].name
      : ''
  )
  const [transactionData, setTransactionData] = useState<Payment>(
    payment ?? emptyPayment
  )

  const [programOrEnrollments, setProgramOrEnrollments] = useState<{
    /** String of the value of program or enrollment selections */
    selectionName: string
    /** Keys associated to the selected programs/enrollments */
    keys: string[]
  }>({
    // handles multiple enrollments OR one program (for licensing)
    selectionName: selectedPrograms?.map((it) => it.name).join(',') ?? '',
    keys:
      selectedPrograms?.map((it) => {
        return isLicensingVariant
          ? `${it.programKey}`
          : `${it.programKey} - ${it.studentKey}`
      }) ?? [],
  })

  // Currency amount string.
  const [amount, setAmount] = useState(
    getLocaleCurrencyForAmount(
      transactionData.amount.amount ?? 0,
      transactionData.amount.currencyCode ?? 'USD'
    )
  )

  const [tax, setTax] = useState(
    getLocaleCurrencyForAmount(
      transactionData.tax.amount ?? 0,
      transactionData.tax.currencyCode ?? 'USD'
    )
  )

  // Configure the date received if the payment exists.
  const [transactionDate, setTransactionDate] = useState(
    !!payment
      ? dateToDashString(reinterpretYearMonthDayAsLocalTime(payment?.begunAt))
      : ''
  )

  // If an error occurs when submitting the transaction for creation or update
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()

  const [isDeleteTransaction, setIsDeleteTransaction] = useState(false)
  const [isVoidOrRestoreTransaction, setIsVoidOrRestoreTransaction] =
    useState(false)

  const validateAmount = () => {
    // Verify amount is non-empty
    if (!!amount) {
      const amountFromCurrency = getAmountFromLocaleCurrency(amount)
      /**
       * Verify amount is not 0. It can be negative, for a refund issued, or
       *  positive, for a payment received. If a user is trying to modify a
       *  transaction to be 0, they should delete it instead.
       */
      if (amountFromCurrency !== 0) {
        /**
         *  For now we only care that something is non-zero. We don't want to do
         *  tricky math around total amount due. It'd be easy math for adding a
         *  transaction, but for edits that could get complex.
         */
        return true
      }
    }
    return false
  }

  /**
   *  Create is disabled if we have no:
   *    - Amount (number)
   *    - TransactionType
   *    - positive _for.programKey - if Licensing
   *    - _for.enrollments - if Enrollments
   */
  const isCreateEnabled =
    validateAmount() &&
    !!transactionType &&
    programOrEnrollments.keys.length >= 1
  const isUpdateEnabled =
    validateAmount() &&
    !!transactionDate &&
    programOrEnrollments.keys.length >= 1

  const [transactionTypeChanged, setTransactionTypeChanged] = useState(false)
  const [enterPressed, setEnterPressed] = useState(false)
  const issueRefundSelected = transactionType === transactionOptions[1].name

  /**
   * When transaction type changes, update the amount field based on whether
   * we are issuing a refund or a payment received.
   */
  useEffect(() => {
    if ((transactionTypeChanged || enterPressed) && !!amount) {
      const refinedAmountString = amountFromInput(amount)
      const refinedAmount = issueRefundSelected
        ? +`-${refinedAmountString}`
        : +refinedAmountString

      const refinedTaxString = amountFromInput(tax)
      const refinedTax = issueRefundSelected
        ? +`-${refinedTaxString}`
        : +refinedTaxString
      setAmount(
        getLocaleCurrencyForAmount(
          refinedAmount,
          transactionData.amount.currencyCode ?? 'USD'
        )
      )
      setTax(
        getLocaleCurrencyForAmount(
          refinedTax,
          transactionData.tax.currencyCode ?? 'USD'
        )
      )
      setTransactionData((prevTransactionData) => ({
        ...prevTransactionData,
        amount: {
          ...prevTransactionData.amount,
          amount: refinedAmount,
        },
      }))
      setTransactionTypeChanged(false)
      setEnterPressed(false)
    }
  }, [
    issueRefundSelected,
    transactionData,
    amount,
    transactionTypeChanged,
    enterPressed,
    tax,
  ])

  const { addLoadingIds, removeLoadingIds } = React.useContext(LoadingContext)

  const handleFormSubmit = async (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (isDeleteTransaction) {
      addLoadingIds([deletePaymentLoadingId])
    } else if (isVoidOrRestoreTransaction || isEdit) {
      addLoadingIds([updatePaymentLoadingId])
    }
  }

  const handleDeleteUpdateAndCreatePayment = async () => {
    const type = isLicensingVariant
      ? PaymentStartRequestBodyForTypeEnum.Licensing
      : PaymentStartRequestBodyForTypeEnum.Enrollment

    try {
      if (isDeleteTransaction) {
        await paymentsApi.deletePayment({
          paymentKey: transactionData.paymentKey,
        })
      } else if (isVoidOrRestoreTransaction) {
        await paymentsApi.updatePayment({
          body: {
            ...transactionData,
            paymentStatusKey:
              payment?.paymentStatusKey === PaymentStatusKey.Paid
                ? PaymentStatusKey.Void
                : PaymentStatusKey.Paid,
            desc: reasonVoidPayment,
          },
        })
      } else if (isEdit) {
        // !!!: ENROLLMENTS cannot be updated. We do not support figuring out which enrollments have changed, so we are currently
        // disabling changing enrollments for manual transactions. If a user messes up, they should create a new manual transaction.

        // We don't care about the PaymentResponse here since, if we have that, we'll have fetched billing again.
        await paymentsApi.updatePayment({
          body: {
            ...transactionData,
            ...(isLicensingVariant && {
              licensingPayment: {
                programKey: +programOrEnrollments.keys[0],
                licensingPaymentKey: transactionData.licensingPayment
                  ?.licensingPaymentKey as number,
              },
            }),
            begunAt: new Date(transactionDate),
            userKey: userKey ?? -1,
            amount: {
              ...transactionData.tax,
              amount: getAmountFromLocaleCurrency(amount),
            },
            tax: {
              ...transactionData.tax,
              amount: getAmountFromLocaleCurrency(tax),
            },
            paymentStatusKey: getPaymentStatusKeyFromString(
              transactionData.paymentStatusKey
            ) as PaymentStatusKey,
          },
        })
      } else {
        const startPaymentData: Pick<Payment, 'orderId' | 'desc'> = {
          ...(!!transactionData.orderId && {
            orderId: transactionData.orderId,
          }),
          ...(!!transactionData.desc && { desc: transactionData.desc }),
        }
        await paymentsApi.startPayment({
          userKey,
          body: {
            _for: {
              type,
              ...(isLicensingVariant && {
                programKey: +programOrEnrollments.keys[0],
              }),
              ...(!isLicensingVariant && {
                enrollments: programOrEnrollments.keys.map((enrollmentKey) => {
                  const [programKey, studentKey] = enrollmentKey.split('-')
                  return {
                    programKey: +programKey,
                    studentKey: +studentKey,
                  }
                }),
              }),
            },
            ...startPaymentData,
            amount: getAmountFromLocaleCurrency(amount), // Take away the $,
            tax: getAmountFromLocaleCurrency(tax), // Take away the $,
            // This will always be ADJ for manual adjustments
            method: PaymentMethodKey.Adj,
          },
        })
      }
      onClose()
    } catch (e) {
      const errorObj = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message:
          (e as unknown as Error).message ??
          t(
            'TransactionModal.ValidationMessage.AddTransaction',
            'Something went wrong while adding the transaction.'
          ),
      }
      setSnackbarState?.(true)
      setSnackbarMessage?.(errorObj.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
    } finally {
      removeLoadingIds([
        startPaymentLoadingId,
        updatePaymentLoadingId,
        deletePaymentLoadingId,
      ])
    }
  }

  const deletePaymentLoadingId = `${filename}-${OperationIds.DeletePayment}`
  const startPaymentLoadingId = `${filename}-${OperationIds.StartPayment}`
  const updatePaymentLoadingId = `${filename}-${OperationIds.UpdatePayment}`
  useLoadingContext({
    asyncFunction: handleDeleteUpdateAndCreatePayment,
    loadingId: deletePaymentLoadingId,
  })
  useLoadingContext({
    asyncFunction: handleDeleteUpdateAndCreatePayment,
    loadingId: startPaymentLoadingId,
  })
  useLoadingContext({
    asyncFunction: handleDeleteUpdateAndCreatePayment,
    loadingId: updatePaymentLoadingId,
  })

  const handleKeyboardEvent = (event: React.KeyboardEvent<Element>) => {
    if (event.key === 'Enter') {
      setEnterPressed(true)
    }
  }

  const handleAmountInputBlur = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    // When the amount field loses focus, update paymentData state with a numerical version of stringAmount
    const refinedAmountString = amountFromInput(event.target.value)
    const refinedAmount = issueRefundSelected
      ? +`-${refinedAmountString}`
      : +refinedAmountString

    if (event.target.name === 'amount') {
      setAmount(
        getLocaleCurrencyForAmount(
          refinedAmount,
          transactionData.amount.currencyCode ?? 'USD'
        )
      )
    } else {
      setTax(
        getLocaleCurrencyForAmount(
          /** tax cannot be negative */
          refinedAmount < 0 ? refinedAmount * -1 : refinedAmount,
          transactionData.amount.currencyCode ?? 'USD'
        )
      )
    }
    setTransactionData((prevState) => ({
      ...prevState,
      [event.target.name]: {
        amount: refinedAmount,
        currencyCode: prevState.amount.currencyCode ?? 'USD',
      },
    }))
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'amount':
        // Purposefully allow anything here, since we will parse onBlur
        setAmount(event.target.value)
        setTransactionData({
          ...transactionData,
          [event.target.name]: {
            amount: +event.target.value,
            currencyCode: transactionData.amount.currencyCode ?? 'USD',
          },
        })
        break
      case 'tax':
        // Purposefully allow anything here, since we will parse onBlur
        setTax(event.target.value)
        setTransactionData({
          ...transactionData,
          [event.target.name]: {
            amount: +event.target.value,
            currencyCode: transactionData.tax.currencyCode ?? 'USD',
          },
        })
        break
      case 'description':
        setTransactionData({
          ...transactionData,
          // The transactionData object is of type Payment with 'desc' as the description prop
          [event.target.name.slice(0, 4)]: event.target.value,
        })
        break
      case 'begunAt':
        setTransactionDate(event.target.value)
        break
      case 'transactionType':
        setTransactionType(event.target.value)
        setTransactionTypeChanged(true)
        break
      default:
        setTransactionData({
          ...transactionData,
          [event.target.name]: event.target.value,
        })
        break
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleProgramChange = (selection: string | string[], _: string) => {
    if (!isLicensingVariant) {
      const option = programOptions.find((option) => {
        const currentSelection = selection[(selection as string[]).length - 1]

        // look for type and date separately, just so parentheses are not interpreted incorrectly.
        return new RegExp(stringEscapedForRegExp(option.name), 'i').test(
          currentSelection
        )
      })
      /**
       * Option contains the programKey and studentKey (if enrollments) required
       * to determine if a program or enrollment was already selected.
       */
      if (option) {
        const { programKey, studentKey } = option
        setProgramOrEnrollments((currentProgramOrEnrollments) => {
          // Set the updated keys initially to the current keys
          const updatedKey = currentProgramOrEnrollments.keys

          /** Find the index of the existing key for enrollments. This is a combination
           * of the programKey and studentKey
           */
          const idIndex = currentProgramOrEnrollments.keys.findIndex(
            (key) => `${programKey} - ${studentKey}` === key
          )

          // A positive index means we found the key and should remove it
          if (idIndex >= 0) {
            updatedKey.splice(idIndex, 1)
          } else {
            // A negative means we should add it.
            updatedKey.push(`${programKey} - ${studentKey}`)
          }

          // Join the selection with commas since multi-selects require comma separated values.
          // Also, set the keys to the updated keys
          return {
            selectionName: (selection as string[]).join(','),
            keys: updatedKey,
          }
        })
      }
    } else {
      const id = programOptions.find((option) => {
        const currentSelection = selection as string

        // look for type and date separately, just so parentheses are not interpreted incorrectly.
        return new RegExp(stringEscapedForRegExp(option.name), 'i').test(
          currentSelection
        )
      })?.programKey
      setProgramOrEnrollments({
        selectionName: selection as string,
        keys: [`${id}`],
      })
    }
  }

  const resetModalState = () => {
    setTransactionData(payment ?? emptyPayment)
    setProgramOrEnrollments({ selectionName: '', keys: [] })
  }

  const deleteTransaction = () => {
    setIsDeleteTransaction(true)
  }

  const voidOrRestoreTransaction = () => {
    setIsVoidOrRestoreTransaction(true)
  }

  const cancelDeleteTransaction = () => {
    setIsDeleteTransaction(false)
  }

  const cancelVoidOrRestoreTransaction = () => {
    setIsVoidOrRestoreTransaction(false)
  }

  const DialogContent = () => {
    if (isDeleteTransaction) return null

    if (isVoidOrRestoreTransaction) {
      const isVoid = payment?.paymentStatusKey === PaymentStatusKey.Paid

      if (isVoid) {
        return isLicensingVariant ? (
          <Box>
            <Typography component="p">
              {t(
                'EditTransactionModal.Content.VoidPayment',
                `Voiding this payment will make it no longer be considered paid.`
              )}
            </Typography>
          </Box>
        ) : (
          <Box>
            <Box mb={2}>
              <Typography component="p">
                {t(
                  'EditTransactionModal.Content.VoidPayment',
                  `Voiding this payment will make it no longer be considered paid. To confirm this void you must enter a reason.`
                )}
              </Typography>
            </Box>
            <TextField
              id="description"
              name="reasonVoidPayment"
              inputProps={{ maxLength: 60, minLength: 6 }}
              label={t('TransactionModal.VoidPayment', 'Reason')}
              multiline
              rows={8}
              variant="outlined"
              value={reasonVoidPayment}
              onChange={(e) => setReasonVoidPayment(e.target.value)}
              fullWidth
            />
          </Box>
        )
      } else {
        return isLicensingVariant ? (
          <Box>
            <Typography component="p">
              {t(
                'EditTransactionModal.Content.RestorePayment',
                `Restoring this payment will make it be considered paid.`
              )}
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography component="p">
              {t(
                'EditTransactionModal.Content.RestorePayment',
                `Restoring this payment will make it be considered paid.`
              )}
            </Typography>
          </Box>
        )
      }
    }

    const dateReceivedField = (
      <Box pb={3.5} key="dateReceived">
        <TextField
          id="dateReceived"
          name="begunAt"
          variant="filled"
          label={t('EditTransactionModal.Label.DateReceived', 'Date Received')}
          InputLabelProps={{ shrink: true }}
          type="date"
          value={transactionDate}
          onChange={handleInputChange}
          disabled={!isManualTransaction}
          fullWidth
        />
      </Box>
    )

    const transactionTypeField = (
      <Box pb={3.5} key="transactionField">
        <TextField
          id="transactionField"
          name="transactionType"
          variant="filled"
          label={t('TransactionModal.Label.Transaction', 'Transaction')}
          value={transactionType}
          onChange={handleInputChange}
          fullWidth
          select
        >
          {!!transactionOptions && transactionOptions.length > 0 ? (
            transactionOptions.map((option) => (
              <MenuItem key={option.key} value={option.name}>
                {option.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>
              {t('TransactionModal.MenuItem.NoOptions', 'No Options')}
            </MenuItem>
          )}
        </TextField>
      </Box>
    )

    const amountField = (
      <Box width="100%" pb={3.5} key="amount">
        <TextField
          id="amount"
          name="amount"
          label={
            issueRefundSelected
              ? t(
                  'TransactionModal.Label.RefundAmount',
                  'Refund Amount (excluding tax)'
                )
              : t(
                  'TransactionModal.Label.PaymentAmount',
                  'Payment Amount (excluding tax)'
                )
          }
          variant="filled"
          // Display negative amount for a refund
          value={amount}
          onChange={handleInputChange}
          onBlur={handleAmountInputBlur}
          onKeyPress={handleKeyboardEvent}
          disabled={!isManualTransaction}
          fullWidth
        />
      </Box>
    )

    const taxAmountField = (
      <Box width="100%" pb={3.5} key="taxAmount">
        <TextField
          id="taxAmount"
          name="tax"
          label={t('TransactionModal.Label.TaxAmount', 'Tax Amount')}
          variant="filled"
          value={tax}
          onChange={handleInputChange}
          onBlur={handleAmountInputBlur}
          onKeyPress={handleKeyboardEvent}
          disabled={!isManualTransaction}
          fullWidth
        />
      </Box>
    )

    const descriptionField = (
      <Box width="100%" pb={3.5} key="description">
        <TextField
          id="description"
          name="description"
          inputProps={{ maxLength: 60 }}
          label={
            issueRefundSelected
              ? t(
                  'TransactionModal.Label.RefundDescription',
                  'Refund Description'
                )
              : t(
                  'TransactionModal.Label.PaymentDescription',
                  'Payment Description'
                )
          }
          multiline
          rows={4}
          variant="filled"
          value={transactionData?.desc ?? ''}
          onChange={handleInputChange}
          fullWidth
          helperText={t(
            'TransactionModal.Description.HelperText.MaxCharacters',
            '60 characters max'
          )}
        />
      </Box>
    )

    const programField = (
      <Box pb={3.5} key="program">
        <DropDown
          id={isLicensingVariant ? 'program' : 'enrollments'}
          menuOptions={programOptions.map((it) => {
            return {
              id: isLicensingVariant
                ? it.programKey
                : `${it.programKey} - { ${it.studentKey}}`,
              name: it.name,
            } as MenuOption
          })}
          value={programOrEnrollments.selectionName}
          label={
            isLicensingVariant
              ? t('TransactionModal.Label.Program', 'Program')
              : t('TransactionModal.Label.Enrollments', 'Enrollment(s)')
          }
          defaultValue=""
          handleSelection={handleProgramChange}
          variant={DropDownVariant.FormField}
          disabled={
            !isManualTransaction ||
            // Don't allow enrollments to change. Force a deletion of a manual transaction
            (isEdit && !isLicensingVariant)
          }
          multiple={!isLicensingVariant}
          useOpen={true}
          cssProps={{
            padding: 0,
            height: 'auto',
          }}
          formControlProps={{
            [theme.breakpoints.down('sm')]: {
              margin: theme.spacing(0),
              width: '100%',
            },
            [theme.breakpoints.up('sm')]: {
              margin: theme.spacing(0),
              width: '100%',
            },
          }}
        />
      </Box>
    )

    const acumaticaOrderNumberField = (
      <Box pb={3.5} key="acumaticaOrderNumberField">
        <TextField
          id="acumaticaOrderNumberField"
          name="orderId"
          variant="filled"
          label={t(
            'TransactionModal.Label.AcumaticaOrderNumber',
            'Acumatica Order # (Optional)'
          )}
          value={transactionData.orderId ?? ''}
          onChange={handleInputChange}
          fullWidth
        />
      </Box>
    )

    const paymentMethodField = (
      <Box pb={3.5} key="paymentMethodField">
        <TextField
          id="paymentMethodField"
          name="paymentMethod"
          variant="filled"
          label={t('TransactionModal.Label.PaymentMethod', 'PaymentMethod')}
          value={paymentMethod}
          fullWidth
          // Always disabled since this is for non-manual payments
          disabled
        />
      </Box>
    )

    const transactionIdField = (
      <Box pb={3.5} key="transactionIdField">
        <TextField
          id="transactionIdField"
          name="transactionId"
          variant="filled"
          label={t('TransactionModal.Label.TransactionId', 'Transaction ID')}
          value={transactionId}
          fullWidth
          // Always disabled since this is for non-manual payments
          disabled
        />
      </Box>
    )

    const paidByField = (
      <Box key="paidByField">
        <TextField
          id="paidByField"
          name="paidBy"
          variant="filled"
          label={t('TransactionModal.Label.PaidBy', 'Paid By')}
          value={paidBy}
          fullWidth
          // Always disabled since this is for non-manual payments
          disabled
        />
      </Box>
    )

    const addEditFields = [
      amountField,
      taxAmountField,
      descriptionField,
      programField,
      acumaticaOrderNumberField,
    ]

    const editTransactionFields = [dateReceivedField, ...addEditFields]

    const addTransactionFields = [
      transactionTypeField,
      ...(!!transactionType ? addEditFields : []),
    ]

    const nonManualTransactionFields = [
      dateReceivedField,
      amountField,
      taxAmountField,
      paymentMethodField,
      descriptionField,
      programField,
      acumaticaOrderNumberField,
      transactionIdField,
      paidByField,
    ]

    return (
      <Box>
        {isEdit ? (
          // If we are editing and it's not a manual transaction, show non-manual fields
          !isManualTransaction ? (
            <Box>{nonManualTransactionFields.map((it) => it)}</Box>
          ) : (
            <Box>{editTransactionFields.map((it) => it)}</Box>
          )
        ) : (
          <Box>{addTransactionFields.map((it) => it)}</Box>
        )}
      </Box>
    )
  }

  const DialogTitle = () => {
    if (isDeleteTransaction) {
      return t(
        'TransactionModal.Heading.DeleteTransaction',
        'Are you sure you want to delete this transaction?'
      )
    } else if (isVoidOrRestoreTransaction) {
      return payment?.paymentStatusKey === PaymentStatusKey.Paid
        ? t(
            'EditTransactionModal.title.VoidTransaction',
            'Are you sure you want to void this transaction?'
          )
        : t(
            'EditTransactionModal.title.RestoreTransaction',
            'Are you sure you want to restore this transaction?'
          )
    } else if (isEdit) {
      if (issueRefundSelected) {
        return t('EditTransactionModal.Title.RefundIssued', 'Refund Issued')
      } else {
        return t(
          'EditTransactionModal.Title.PaymentReceived',
          'Payment Received'
        )
      }
    } else {
      return t(
        'TransactionModal.Title.AddNewTransaction',
        'Add New Transaction'
      )
    }
  }

  const DialogActions = () => {
    let primaryButtonLabel = ContainedButtonVariant.Create
    let secondaryClick = onClose
    let tertiaryClick = undefined
    let secondaryButtonLabel = undefined
    let tertiaryButtonLabel = undefined
    let disablePrimaryButton = false

    // Rather than flip isEdit and isDeleteTransaction, just cover the bases and
    // say isDeleteTransaction 'overrides' isEdit
    if (!isDeleteTransaction && !isVoidOrRestoreTransaction) {
      if (isEdit) {
        if (issueRefundSelected) {
          primaryButtonLabel = ContainedButtonVariant.UpdateRefund
          secondaryButtonLabel = isManualTransaction
            ? TextButtonVariant.DeleteRefund
            : TextButtonVariant.Cancel
        } else {
          primaryButtonLabel = ContainedButtonVariant.UpdatePayment

          if (isManualTransaction) {
            secondaryButtonLabel = TextButtonVariant.DeletePayment
          } else if (payment?.paymentStatusKey === PaymentStatusKey.Paid) {
            secondaryButtonLabel = TextButtonVariant.VoidPayment
            secondaryClick = voidOrRestoreTransaction
            tertiaryButtonLabel = TextButtonVariant.Cancel
            tertiaryClick = onClose
          } else if (payment?.paymentStatusKey === PaymentStatusKey.Void) {
            secondaryButtonLabel = TextButtonVariant.RestorePayment
            secondaryClick = voidOrRestoreTransaction
            tertiaryButtonLabel = TextButtonVariant.Cancel
            tertiaryClick = onClose
          }

          tertiaryButtonLabel = TextButtonVariant.Cancel
          tertiaryClick = onClose
        }

        if (isManualTransaction) {
          secondaryClick = deleteTransaction
          tertiaryButtonLabel = TextButtonVariant.Cancel
          tertiaryClick = onClose
        }
        disablePrimaryButton = !isUpdateEnabled
      } else {
        disablePrimaryButton = !isCreateEnabled
        secondaryClick = onClose
        secondaryButtonLabel = TextButtonVariant.Cancel
        tertiaryButtonLabel = undefined
        tertiaryClick = undefined
      }
    } else if (isDeleteTransaction) {
      primaryButtonLabel = ContainedButtonVariant.YesDelete
      secondaryButtonLabel = TextButtonVariant.NoCancel
      secondaryClick = cancelDeleteTransaction
      tertiaryButtonLabel = undefined
      tertiaryClick = undefined
    } else if (isVoidOrRestoreTransaction) {
      primaryButtonLabel = ContainedButtonVariant.Confirm
      secondaryButtonLabel = TextButtonVariant.NoCancel
      secondaryClick = cancelVoidOrRestoreTransaction
      tertiaryButtonLabel = undefined
      tertiaryClick = undefined
      if (!isLicensingVariant) {
        disablePrimaryButton =
          reasonVoidPayment.length === 0 &&
          payment?.paymentStatusKey === PaymentStatusKey.Paid
      }
    }

    return (
      <ActionButtons
        primaryButtonLabel={primaryButtonLabel}
        disablePrimaryButton={disablePrimaryButton}
        secondaryClick={secondaryClick}
        secondaryButtonLabel={secondaryButtonLabel}
        tertiaryButtonLabel={tertiaryButtonLabel}
        tertiaryClick={tertiaryClick}
        alwaysStack
        primaryButtonLoadingId={
          isDeleteTransaction
            ? deletePaymentLoadingId
            : isEdit || isVoidOrRestoreTransaction
            ? updatePaymentLoadingId
            : startPaymentLoadingId
        }
        useBaseButton
      />
    )
  }

  return (
    <>
      <BasicModal
        isOpen={isOpen}
        handleFormSubmit={handleFormSubmit}
        maxWidth="xs"
        dialogContent={DialogContent()}
        dialogActions={DialogActions()}
        ariaLabel={DialogTitle()}
        dialogTitle={DialogTitle()}
        afterClose={resetModalState}
        labelledBy="addTransaction-form-title"
      />
    </>
  )
}

export default TransactionModal
