import React from 'react'
import CommunityFormCard, {
  CommunityFormCardVariants,
} from '../Card/CommunityFormCard'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import EmptyPage from '../Elements/EmptyPage'
import { useCommunitiesContext } from '../Context/CommunitiesContext'

export const CommunityDetailsTab: React.FunctionComponent = () => {
  const { t } = useTranslation()

  const {
    academicYear,
    communityDetails,
    handleCancel,
    handleSave,
    resetFieldsForCancel,
    communityAbility,
  } = useCommunitiesContext()

  const noCommunityDetailsMessage = (
    <Typography
      variant="subtitle1"
      component="p"
      style={{ whiteSpace: 'pre-line' }}
    >
      {t(
        'Communities.CommunityDetails.NoDetails',
        'Program enrollment not found.\nPlease complete enrollment to view Communities.'
      )}
    </Typography>
  )

  if (communityDetails) {
    return (
      <>
        <CommunityFormCard
          title={t(
            'Communities.CommunityDetails.FormCard.Header',
            'Community Details'
          )}
          variant={CommunityFormCardVariants.EditCommunity}
          communityDetails={communityDetails}
          handleSave={handleSave}
          communityAbility={communityAbility}
          academicYear={academicYear}
          resetFieldsForCancel={resetFieldsForCancel}
          handleCancel={handleCancel}
        />
      </>
    )
  }
  return <EmptyPage message={noCommunityDetailsMessage} />
}

export default CommunityDetailsTab
