/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  UserRoleAssignment,
  UserRoleAssignmentFromJSON,
  UserRoleAssignmentToJSON,
} from './'

/**
 *
 * @export
 * @interface UserAccountDetails
 */
export interface UserAccountDetails {
  /**
   * The userKey assigned by the system when the user was first created.
   * @type {number}
   * @memberof UserAccountDetails
   */
  userKey: number
  /**
   * The personal email the user registered with.
   * @type {string}
   * @memberof UserAccountDetails
   */
  username: string
  /**
   * An official, CC-assigned and -managed email address. This cannot be set or changed by most users.
   * @type {string}
   * @memberof UserAccountDetails
   */
  email?: string
  /**
   * The first name of the user.
   * @type {string}
   * @memberof UserAccountDetails
   */
  firstName?: string
  /**
   * The last name of the user.
   * @type {string}
   * @memberof UserAccountDetails
   */
  lastName?: string
  /**
   * If true, this user has activated two factor authentication on their account.
   * @type {boolean}
   * @memberof UserAccountDetails
   */
  isTwoFactorAuthenticationActive?: boolean
  /**
   * If true, the user account cannot be logged into. If false, the user account is active and can be logged into. Defaults to false.
   * @type {boolean}
   * @memberof UserAccountDetails
   */
  isDeactivated?: boolean
  /**
   * The date in which the user account was created in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof UserAccountDetails
   */
  accountCreated: Date
  /**
   * The active roles that this user account currently has.
   * @type {Array<UserRoleAssignment>}
   * @memberof UserAccountDetails
   */
  roles: Array<UserRoleAssignment>
}

export function UserAccountDetailsFromJSON(json: any): UserAccountDetails {
  return UserAccountDetailsFromJSONTyped(json, false)
}

export function UserAccountDetailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserAccountDetails {
  if (json === undefined || json === null) {
    return json
  }
  return {
    userKey: json['userKey'],
    username: json['username'],
    email: !exists(json, 'email') ? undefined : json['email'],
    firstName: !exists(json, 'firstName') ? undefined : json['firstName'],
    lastName: !exists(json, 'lastName') ? undefined : json['lastName'],
    isTwoFactorAuthenticationActive: !exists(
      json,
      'isTwoFactorAuthenticationActive'
    )
      ? undefined
      : json['isTwoFactorAuthenticationActive'],
    isDeactivated: !exists(json, 'isDeactivated')
      ? undefined
      : json['isDeactivated'],
    accountCreated: new Date(json['accountCreated']),
    roles: (json['roles'] as Array<any>).map(UserRoleAssignmentFromJSON),
  }
}

export function UserAccountDetailsToJSON(
  value?: UserAccountDetails | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    userKey: value.userKey,
    username: value.username,
    email: value.email,
    firstName: value.firstName,
    lastName: value.lastName,
    isTwoFactorAuthenticationActive: value.isTwoFactorAuthenticationActive,
    isDeactivated: value.isDeactivated,
    accountCreated: value.accountCreated.toISOString().substr(0, 10),
    roles: (value.roles as Array<any>).map(UserRoleAssignmentToJSON),
  }
}
