/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  MembersInformation,
  MembersInformationFromJSON,
  MembersInformationToJSON,
} from './'

/**
 *
 * @export
 * @interface ParticipantSetsInfo
 */
export interface ParticipantSetsInfo {
  /**
   *
   * @type {Array<MembersInformation>}
   * @memberof ParticipantSetsInfo
   */
  memberInfos?: Array<MembersInformation>
  /**
   *
   * @type {string}
   * @memberof ParticipantSetsInfo
   */
  order?: string
  /**
   *
   * @type {string}
   * @memberof ParticipantSetsInfo
   */
  role?: string
  /**
   *
   * @type {string}
   * @memberof ParticipantSetsInfo
   */
  status?: string
  /**
   *
   * @type {string}
   * @memberof ParticipantSetsInfo
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ParticipantSetsInfo
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ParticipantSetsInfo
   */
  privateMessage?: string
}

export function ParticipantSetsInfoFromJSON(json: any): ParticipantSetsInfo {
  return ParticipantSetsInfoFromJSONTyped(json, false)
}

export function ParticipantSetsInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ParticipantSetsInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    memberInfos: !exists(json, 'memberInfos')
      ? undefined
      : (json['memberInfos'] as Array<any>).map(MembersInformationFromJSON),
    order: !exists(json, 'order') ? undefined : json['order'],
    role: !exists(json, 'role') ? undefined : json['role'],
    status: !exists(json, 'status') ? undefined : json['status'],
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    privateMessage: !exists(json, 'privateMessage')
      ? undefined
      : json['privateMessage'],
  }
}

export function ParticipantSetsInfoToJSON(
  value?: ParticipantSetsInfo | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    memberInfos:
      value.memberInfos === undefined
        ? undefined
        : (value.memberInfos as Array<any>).map(MembersInformationToJSON),
    order: value.order,
    role: value.role,
    status: value.status,
    id: value.id,
    name: value.name,
    privateMessage: value.privateMessage,
  }
}
