import React, { useState, useEffect, useRef, useCallback } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Header, { HeaderVariant } from '../../Elements/Header'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import {
  UserProfile,
  UserProfileEditBody,
  Address,
  Student,
  EditStudentRequestBody,
} from '../../../swagger'
import { extractedErrorObject, studentApi } from '../../../api/swagger'
import { buildAvatarUrl, updateUserProfile } from '../../../api/user'
import AddressCard from '../../Card/AddressCard'
import ProfileCard, { ProfileCardVariant } from '../../Card/ProfileCard'
import useDynamicFieldStates from '../../../hooks/useDynamicFieldStates'
import { DynamicFieldStates } from '../../Interfaces/DynamicFieldStates'
import { SnackbarSeverity } from '../../Alerts/SnackbarAlert'
import { FieldValidity } from '../../Interfaces/FieldValidity'
import { emptyAddressInformation } from '../../Address/AddressModal'
import ChildrenSummaryCard, {
  ChildrenSummaryCardVariant,
  KidPrograms,
} from '../../Card/ChildrenSummaryCard'
import { useAuth } from '../../Routes/AuthProvider'
import { useSnackbarContext } from '../../Context/SnackbarContext'
import { useNotistackSnackbarKeyContext } from '../../Context/NotistackSnackbarKeyProvider'
import ProfileVisibilityCard from '../../Card/ProfileVisibilityCard'
import LoadingProgress from '../../Elements/LoadingProgress'
import { LoadingContext } from '../../Context/LoadingContext'
import { useLoadingIds } from '../../../hooks/useLoadingIds'
import { useMountEffect } from '../../../hooks/useMountEffect'
import CountryInformationCard from '../../Card/CountryInformationCard'
import { initialCountrySelected } from '../../Menus/CountryDropDown'
import { useAccountContext } from '../../Context/AccountContext'
import { useUser } from '../../../UserContext'
import {
  setBirthMonthAsRequired,
  setBirthYearAsRequired,
} from '../../Families/ChildrenSummaryCardForFamilies'
import { useSpeedDialMenuContext } from '../../Context/SpeedDialMenuContext'
import { useSpeedDialCommonActions } from '../../Elements/SpeedDialActions'
import useLoadingContext from '../../../hooks/useLoadingContext'
import { useUnmountEffect } from '../../../hooks/useUnmountEffect'
import WarningIcon from '@mui/icons-material/Warning'
import Typography from '@mui/material/Typography'

export const emptyUserInformation = {
  id: -1,
  username: '',
  firstName: '',
  lastName: '',
  phone: '',
  isProfilePrivate: false,
  isPhoneNumberPrivate: false,
  isLoginEmailPrivate: false,
  countryOfResidence: '',
  countryOfCitizenship: '',
}

const dynamicStateChanged = (
  state: DynamicFieldStates,
  initialState: DynamicFieldStates | undefined
) => {
  if (!initialState) {
    return false
  }
  return Object.keys(state).some(
    (stateKey) => state[stateKey] !== initialState[stateKey]
  )
}

interface UserInfoValidity {
  firstName: FieldValidity
  lastName: FieldValidity
  phone: FieldValidity
}

const initialValidity: UserInfoValidity = {
  firstName: { input: true },
  lastName: { input: true },
  phone: { input: true },
}

const ProfileTab: React.FC = () => {
  const { t } = useTranslation()
  const avatarUrl = buildAvatarUrl()
  const { featureAbility } = useAuth()

  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  /** FIXME: Update LoadingContext to have a useLoadingContext and update existing useLoadingContext to have a different name suitable for its use */
  const { addLoadingIds, loadingIds } = React.useContext(LoadingContext)
  const { ProfileTab } = useLoadingIds()
  const profileJoyrideCompleteId = 'profile-joyride-complete'

  const { AccountContext, UserContext } = useLoadingIds()
  const {
    updateActions,
    resetContextToDefaults,
    updateJoyrideSteps,
    updateJoyrideCompleteId,
  } = useSpeedDialMenuContext()

  const initialUserInformation = useRef<UserProfile>(emptyUserInformation)
  const initialUserAddress = useRef<Address>(emptyAddressInformation)

  const [userInformation, setUserInformation] =
    useState<UserProfile>(emptyUserInformation)
  const [isUserInformationValid, setIsUserInformationValid] =
    useState<UserInfoValidity>(initialValidity)
  const [address, setAddress] = useState<Address>(emptyAddressInformation)

  const [isEditMode, setIsEditMode] = useState(false)

  const [childrenToUpdate, setChildrenToUpdate] = useState<
    EditStudentRequestBody[]
  >([])
  const { addSnackbarKey, enqueueSnackbar } = useNotistackSnackbarKeyContext()

  /**
   * Keeps track of whether user has edited any fields.
   * Used to enable the "Save" button only if edits have been made.
   */
  const [deltaChangeOccurred, setDeltaChangeOccurred] = useState(false)
  const { uniqueUserRoles: roles, user, setUser } = useUser()

  const { userDetails } = useAuth()

  const { students } = useAccountContext()

  const errorMessage = t(
    'Account.Profile.ValidationMessage.Default',
    'Something went wrong. Please make sure you have filled out the required fields.'
  )

  /**
   * Initialize fields with user info
   */
  useEffect(() => {
    if (!!user && userInformation.id < 0) {
      const userInfo = {
        id: user?.id,
        username: user?.username,
        firstName: user?.firstName,
        lastName: user?.lastName,
        phone: user?.phone,
        isProfilePrivate: user?.isProfilePrivate,
        isPhoneNumberPrivate: user?.isPhoneNumberPrivate,
        isLoginEmailPrivate: user?.isLoginEmailPrivate,
        countryOfResidence: !!user?.countryOfResidence
          ? user?.countryOfResidence
          : initialCountrySelected.name,
        countryOfCitizenship: !!user?.countryOfCitizenship
          ? user?.countryOfCitizenship
          : initialCountrySelected.name,
      }

      // Store initial user profile info
      setUserInformation(userInfo)
      initialUserInformation.current = userInfo

      if (user.address) {
        // Store initial address info
        setAddress(user.address)
        initialUserAddress.current = user.address
      }
    }
  }, [user, userInformation.id])

  useMountEffect(() => {
    updateJoyrideCompleteId(profileJoyrideCompleteId)
    updateJoyrideSteps([
      {
        target: '.MuiSpeedDial-fab',
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        content: t(
          'ProfileTab.SpeedDialTour.StepZero.Content',
          'Click here to see the edit button.'
        ),
        placement: 'left',
        title: t(
          'ProfileTab.SpeedDialTour.StepZero.Title',
          'Edit button has moved!'
        ),
        disableBeacon: true,
        disableScrolling: true,
      },
      {
        target: '.edit-action',
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        content: t(
          'ProfileTab.SpeedDialTour.StepOne.Content',
          `It's as easy as that.`
        ),
        placement: 'left',
        title: t('ProfileTab.SpeedDialTour.StepOne.Title', 'Click to edit!'),
        disableBeacon: true,
        disableScrolling: true,
      },
      {
        target: '.save-action',
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        content: t(
          'ProfileTab.SpeedDialTour.StepTwo.Content',
          `Dont forget to save your changes.`
        ),
        placement: 'left',
        title: t('ProfileTab.SpeedDialTour.StepTwo.Title', 'Save is here too!'),
        disableBeacon: true,
        disableScrolling: true,
      },
    ])
  })

  const initialChildNameStates = useRef<DynamicFieldStates>()
  const initialChildBirthMonthStates = useRef<DynamicFieldStates>()
  const initialChildBirthYearStates = useRef<DynamicFieldStates>()
  const initialChildHideStates = useRef<DynamicFieldStates>()

  const [isInitialChildNamesLoad, setIsInitialChildNamesLoad] = useState(true)
  const [isInitialChildBirthYearLoad, setIsInitialChildBirthYearLoad] =
    useState(true)

  const { fieldStates: childNameStates, setFieldStates: setChildNameStates } =
    useDynamicFieldStates({
      fields: students.length
        ? students.map((kid) => {
            return {
              label: `${kid.studentKey}-name`,
              state: {
                state: kid.firstName,
                isValid: {
                  input: true,
                  length: true,
                  maxLength: true,
                  minLength: true,
                  email: true,
                },
                required: true,
              },
            }
          })
        : [],
      isInitialLoad: isInitialChildNamesLoad,
      setIsInitialLoad: setIsInitialChildNamesLoad,
    })
  const {
    fieldStates: childBirthYearStates,
    setFieldStates: setChildBirthYearStates,
  } = useDynamicFieldStates({
    fields: students.length
      ? students.map((kid) => {
          const birthYear = kid.birthYear?.toString() ?? ''
          return {
            label: `${kid.studentKey}-birthYear`,
            state: {
              state: birthYear,
              isValid: {
                input: true,
                length: true,
                maxLength: true,
                minLength: true,
                email: true,
              },
              required: false,
            },
          }
        })
      : [],
    isInitialLoad: isInitialChildBirthYearLoad,
    setIsInitialLoad: setIsInitialChildBirthYearLoad,
  })

  const [isInitialChildBirthMonthLoad, setIsInitialChildBirthMonthLoad] =
    useState(true)
  const {
    fieldStates: childBirthMonthStates,
    setFieldStates: setChildBirthMonthStates,
  } = useDynamicFieldStates({
    fields: students.length
      ? students.map((kid) => {
          return {
            label: `${kid.studentKey}-birthMonth`,
            state: {
              state: kid.birthMonth?.toString() ?? '',
              isValid: {
                input: true,
                length: true,
                maxLength: true,
                minLength: true,
                email: true,
              },
              required: true,
            },
          }
        })
      : [],
    isInitialLoad: isInitialChildBirthMonthLoad,
    setIsInitialLoad: setIsInitialChildBirthMonthLoad,
  })

  const [isInitialChildHideLoad, setIsInitialChildHideLoad] = useState(true)
  const { fieldStates: childHideStates, setFieldStates: setChildHideStates } =
    useDynamicFieldStates({
      fields: students.length
        ? students.map((kid) => {
            return {
              label: `${kid.studentKey}-hide`,
              state: {
                state: kid?.hidden?.toString() ?? 'false',
                isValid: {
                  input: true,
                  length: true,
                  maxLength: true,
                  minLength: true,
                  email: true,
                },
                required: true,
              },
            }
          })
        : [],
      isInitialLoad: isInitialChildHideLoad,
      setIsInitialLoad: setIsInitialChildHideLoad,
    })

  const [storeInitialChildNames, setStoreInitialChildNames] = useState(true)
  const [storeInitialChildHide, setStoreInitialChildHide] = useState(true)
  const [storeInitialChildBirthMonths, setStoreInitialChildBirthMonths] =
    useState(true)
  const [storeInitialChildBirthYears, setStoreInitialChildBirthYears] =
    useState(true)

  /**
   * Store initial values once Dynamic Fields have been populated
   */
  useEffect(() => {
    if (storeInitialChildNames && Object.keys(childNameStates).length !== 0) {
      initialChildNameStates.current = childNameStates
      setStoreInitialChildNames(false)
    }
  }, [childNameStates, storeInitialChildNames])

  const studentProgramsForEnrollments: KidPrograms[] = students.length
    ? students?.flatMap((kid) =>
        kid.programs?.length
          ? kid.programs?.map((program) => ({
              studentKey: kid.studentKey,
              programKey: program.programKey,
              status: program.enrollmentStatus,
            }))
          : []
      )
    : []

  const [isInitialEnrollmentLoad, setIsInitialEnrollmentLoad] = useState(true)
  /**
   * This is to ensure that the `enrollmentStates` are always updated whenever
   * the state of the `students` (from AccountContext) changes.
   */
  useEffect(() => {
    setIsInitialEnrollmentLoad(true)
  }, [students])

  const { fieldStates: enrollmentStates } = useDynamicFieldStates({
    fields: studentProgramsForEnrollments.map((it) => {
      return {
        label: `${it.programKey}-${it.studentKey}`,
        state: {
          state: it.status,
          isValid: {
            input: true,
            length: true,
            maxLength: true,
            minLength: true,
            email: true,
          },
          required: true,
        },
      }
    }),
    isInitialLoad: isInitialEnrollmentLoad,
    setIsInitialLoad: setIsInitialEnrollmentLoad,
  })

  /**
   * Store initial values once Dynamic Fields have been populated
   */
  useEffect(() => {
    if (
      storeInitialChildBirthMonths &&
      Object.keys(childBirthMonthStates).length !== 0
    ) {
      initialChildBirthMonthStates.current = childBirthMonthStates
      setStoreInitialChildBirthMonths(false)
    }
  }, [childBirthMonthStates, storeInitialChildBirthMonths])

  useEffect(() => {
    if (
      storeInitialChildBirthYears &&
      Object.keys(childBirthYearStates).length !== 0
    ) {
      initialChildBirthYearStates.current = childBirthYearStates
      setStoreInitialChildBirthYears(false)
    }
  }, [childBirthYearStates, storeInitialChildBirthYears])

  useEffect(() => {
    if (storeInitialChildHide && Object.keys(childHideStates).length !== 0) {
      initialChildHideStates.current = childHideStates
      setStoreInitialChildHide(false)
    }
  }, [childHideStates, storeInitialChildHide])

  /**
   * Detect a change
   */
  useEffect(() => {
    if (
      userInformation.firstName !== initialUserInformation.current.firstName ||
      userInformation.lastName !== initialUserInformation.current.lastName ||
      userInformation.phone !== initialUserInformation.current.phone ||
      userInformation.isProfilePrivate !==
        initialUserInformation.current.isProfilePrivate ||
      userInformation.isPhoneNumberPrivate !==
        initialUserInformation.current.isPhoneNumberPrivate ||
      userInformation.isLoginEmailPrivate !==
        initialUserInformation.current.isLoginEmailPrivate ||
      userInformation.countryOfResidence !==
        initialUserInformation.current.countryOfResidence ||
      userInformation.countryOfCitizenship !==
        initialUserInformation.current.countryOfCitizenship ||
      address?.streetAddress1 !== initialUserAddress.current.streetAddress1 ||
      address?.streetAddress2 !== initialUserAddress.current.streetAddress2 ||
      address?.city !== initialUserAddress.current.city ||
      address?.countryCode !== initialUserAddress.current.countryCode ||
      address?.state !== initialUserAddress.current.state ||
      address?.zip !== initialUserAddress.current.zip ||
      dynamicStateChanged(childNameStates, initialChildNameStates.current) ||
      dynamicStateChanged(
        childBirthMonthStates,
        initialChildBirthMonthStates.current
      ) ||
      dynamicStateChanged(
        childBirthYearStates,
        initialChildBirthYearStates.current
      ) ||
      dynamicStateChanged(childHideStates, initialChildHideStates.current)
    ) {
      setDeltaChangeOccurred(true)
    } else {
      setDeltaChangeOccurred(false)
    }
  }, [
    userInformation,
    initialUserInformation,
    address,
    initialUserAddress,
    childNameStates,
    childHideStates,
    initialChildHideStates,
    initialChildNameStates,
    childBirthMonthStates,
    initialChildBirthMonthStates,
    childBirthYearStates,
    initialChildBirthYearStates,
  ])

  const handleFieldValueChange = (fieldId: string, value: string) => {
    const [id, fieldType] = fieldId.split('-')
    const birthMonthFieldId = `${id}-birthMonth`
    const birthYearFieldId = `${id}-birthYear`
    //Get the edited child information from the children array
    prepareChildrenToUpdate(fieldId, value, students, childrenToUpdate)
    switch (fieldType) {
      case 'name':
        setChildNameStates({
          ...childNameStates,
          [fieldId]: {
            ...childNameStates[fieldId],
            state: value,
          },
        })
        setBirthYearAsRequired(
          birthYearFieldId,
          childBirthYearStates,
          setChildBirthYearStates
        )
        setBirthMonthAsRequired(
          birthMonthFieldId,
          childBirthMonthStates,
          setChildBirthMonthStates
        )
        break
      case 'birthYear':
        setChildBirthYearStates({
          ...childBirthYearStates,
          [fieldId]: {
            ...childBirthYearStates[fieldId],
            state: value,
            required: true,
            isValid: {
              ...childBirthYearStates[fieldId].isValid,
              input: !isNaN(Number(value)) && value !== '',
            },
          },
        })
        setBirthMonthAsRequired(
          birthMonthFieldId,
          childBirthMonthStates,
          setChildBirthMonthStates
        )
        break
      case 'birthMonth':
        setChildBirthMonthStates({
          ...childBirthMonthStates,
          [fieldId]: {
            ...childBirthMonthStates[fieldId],
            state: value,
            required: true,
            isValid: {
              ...childBirthMonthStates[fieldId].isValid,
              input: !isNaN(Number(value)) && value !== '',
            },
          },
        })
        setBirthYearAsRequired(
          birthYearFieldId,
          childBirthYearStates,
          setChildBirthYearStates
        )
        break
    }
  }

  const resetChildrenFieldStates = () => {
    setChildNameStates({
      ...initialChildNameStates.current,
    })
    setChildBirthMonthStates({
      ...initialChildBirthMonthStates.current,
    })
    setChildBirthYearStates({
      ...initialChildBirthYearStates.current,
    })
    setChildHideStates({
      ...initialChildHideStates.current,
    })
  }

  const handleEditProfile = () => {
    setIsEditMode(true)
  }

  const handleAddressEntry = (enteredAddress: Address) => {
    setAddress(enteredAddress)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    const newValue = name === 'phone' ? value.replace(/[A-Z]/gi, '') : value
    setUserInformation((currInfo) => ({
      ...currInfo,
      [name]: newValue,
    }))
  }

  const handleCancel = () => {
    setUserInformation(initialUserInformation.current)
    setIsUserInformationValid(initialValidity)
    setAddress(initialUserAddress.current)
    resetChildrenFieldStates()
    setIsEditMode(false)
  }

  const handleProfileChange = async () => {
    const areValidChildren = childrenToUpdate.every(
      ({ birthMonth, birthYear }) =>
        !isNaN(Number(birthMonth)) && !isNaN(Number(birthYear))
    )
    const { firstName, lastName, phone } = userInformation
    setIsUserInformationValid((prevValidity) => ({
      ...prevValidity,
      firstName: { input: !!firstName },
      lastName: { input: !!lastName },
      phone: { input: !!phone },
    }))

    const areValidFields =
      [firstName, lastName, phone].every((value) => value !== '') &&
      areValidChildren

    if (!areValidFields) {
      const e = {
        code: 'UnknownError',
        message: errorMessage,
      }
      setSnackbarState?.(true)
      setSnackbarMessage?.(e.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)

      return
    }

    let updatedUserInfo: UserProfileEditBody = {
      ...userInformation,
    }

    // Only include address if user has entered one
    if (!!address.streetAddress1) {
      updatedUserInfo = {
        ...updatedUserInfo,
        address: {
          locationName: '',
          streetAddress1: address.streetAddress1,
          streetAddress2: address.streetAddress2,
          city: address.city,
          state: address.state,
          zip: address.zip,
          countryCode: address.countryCode,
        },
      }
    }
    try {
      // Is the call successful?
      await updateUserProfile(updatedUserInfo)

      //Updating modified children
      const updateResults = await Promise.allSettled(
        childrenToUpdate.map(async (body) => {
          try {
            return await studentApi.editStudent({ body })
          } catch (error) {
            const errorObj = (await extractedErrorObject(error)) ?? {
              code: 'Unknown',
              message: (error as unknown as Error).message,
            }
            // Used to set the reject reason
            throw new Error(errorObj.message).message
          }
        })
      )

      // Separate rejections from success messages
      const rejections = updateResults.filter((it) => it.status === 'rejected')
      const errorMessage: string[] = []
      for (const rejection of rejections) {
        errorMessage.push(`${(rejection as PromiseRejectedResult).reason} \n`)
      }

      for (const msg of errorMessage) {
        addSnackbarKey(enqueueSnackbar(msg, { variant: 'error' }))
      }

      const successMessage: string[] = []
      const successes = updateResults.filter((it) => it.status === 'fulfilled')
      for (const success of successes) {
        successMessage.push(
          `${
            (success as PromiseFulfilledResult<EditStudentRequestBody>).value
              .firstName
          } was updated successfully. \n`
        )
      }

      for (const msg of successMessage) {
        addSnackbarKey(enqueueSnackbar(msg, { variant: 'success' }))
      }

      // Update initial reference to userInformation
      initialUserInformation.current = updatedUserInfo as UserProfile
      setUser(updatedUserInfo as UserProfile)
      //Show the profile success message
      addSnackbarKey(
        enqueueSnackbar(
          t(
            'ProfileCard.ProfileInformation.UpdateSuccess',
            'Successfully updated profile information'
          ),
          { variant: 'success' }
        )
      )

      // Turn edit mode off clean setChildrenToUpdate and save mode off
      setChildrenToUpdate([])
      setIsEditMode(false)
    } catch (e) {
      const errorObj = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message:
          (e as unknown as Error).message ??
          t(
            'ProfileCard.Error.SaveChanges',
            'Error occurred attempting to save changes to user profile.'
          ),
      }
      addSnackbarKey(enqueueSnackbar(errorObj.message, { variant: 'error' }))
    } finally {
      if (
        loadingIds.has(ProfileTab.editUserProfile) &&
        (phone?.length as number) >= 10
      ) {
        // All this trigger asynchronous functions for correct use
        // ReFetch students for family after successful profile update and children update
        addLoadingIds([AccountContext.fetchStudentsForFamily])
        // Store a new memorized(useRef) reference for children data triggering a function
        setStoreInitialChildNames(true)
        setStoreInitialChildBirthMonths(true)
        setStoreInitialChildBirthYears(true)
        setStoreInitialChildHide(true)
        // Do not show save and cancel buttons of the speedDial setting state to false-
        // using it to trigger speedDial context properties
        setDeltaChangeOccurred(false)
      }
    }
  }

  /**
   * Validates the field data and, if validated, adds the loadingId for saving.
   */
  const saveActionClick = useCallback(() => {
    addLoadingIds([ProfileTab.editUserProfile])
  }, [addLoadingIds, ProfileTab.editUserProfile])

  useLoadingContext({
    asyncFunction: handleProfileChange,
    loadingId: ProfileTab.editUserProfile,
  })

  const { editAction, saveAction, cancelAction } = useSpeedDialCommonActions({
    saveProps: {
      onClick: saveActionClick,
      onTourClick: () => {
        handleCancel()
      },
      selector: 'save-action',
      hidden: !deltaChangeOccurred,
    },
    cancelProps: {
      onClick: handleCancel,
      selector: 'cancel-action',
    },
    editProps: {
      onClick: handleEditProfile,
      pauseTourBriefly: true,
      onTourClick: () => {
        handleEditProfile()
        setDeltaChangeOccurred(true)
      },
      selector: 'edit-action',
    },
  })

  /** When userInformation changes, update the button reference to use the proper user information */
  useEffect(() => {
    saveAction.current.onClick = saveActionClick
  }, [deltaChangeOccurred, saveAction, saveActionClick])

  /** FIXME: Include unmountEffect which rids us of actions */
  useMountEffect(() => {
    /**
     * Adding our initial endpoints which load data
     * This also refreshes our data if we click on another tab and come back.
     */
    addLoadingIds([
      AccountContext.fetchStudentsForFamily,
      UserContext.fetchUserCommunities,
    ])
  })

  useUnmountEffect(() => {
    resetContextToDefaults()
  })

  useEffect(() => {
    const handleActionUpdates = () => {
      saveAction.current.hidden = !deltaChangeOccurred
      updateActions(
        isEditMode
          ? [saveAction.current, cancelAction.current]
          : [editAction.current]
      )
    }
    handleActionUpdates()
  }, [
    isEditMode,
    updateActions,
    cancelAction,
    saveAction,
    editAction,
    deltaChangeOccurred,
  ])

  if (
    loadingIds.has(UserContext.fetchUserCommunities) ||
    loadingIds.has(AccountContext.fetchStudentsForFamily)
  ) {
    return <LoadingProgress />
  }

  return (
    <>
      <ProfileCard
        variant={ProfileCardVariant.Account}
        userInfo={userInformation}
        userRolesForCommunities={roles}
        avatarUrl={avatarUrl}
        rightContent={
          <>
            <Header
              id="profileInformation"
              variant={HeaderVariant.Card}
              headerName={t(
                'ProfileCard.Header.ProfileInformation',
                'Profile Information'
              )}
              component="h3"
            />
            <Box mb={3}>
              <Grid container spacing={3}>
                <Grid item container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="firstName"
                      name="firstName"
                      label={t('ProfileCard.Field.Firstname', 'First Name')}
                      variant="filled"
                      value={userInformation?.firstName}
                      onChange={handleInputChange}
                      error={!isUserInformationValid.firstName.input}
                      helperText={
                        !isUserInformationValid.firstName.input
                          ? t(
                              'Account.Profile.ValidationMessage.FirstName',
                              'First Name cannot be empty'
                            )
                          : null
                      }
                      disabled={!isEditMode}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="lastName"
                      name="lastName"
                      label={t('ProfileCard.Field.Lastname', 'Last Name')}
                      variant="filled"
                      value={userInformation?.lastName}
                      onChange={handleInputChange}
                      error={!isUserInformationValid.lastName.input}
                      helperText={
                        !isUserInformationValid.lastName.input
                          ? t(
                              'Account.Profile.ValidationMessage.LastName',
                              'Last Name cannot be empty'
                            )
                          : null
                      }
                      disabled={!isEditMode}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid item container alignItems="center" spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="phone"
                      name="phone"
                      label={t('ProfileCard.Field.PhoneNumber', 'Phone Number')}
                      variant="filled"
                      value={userInformation?.phone}
                      onChange={handleInputChange}
                      error={!isUserInformationValid.phone.input}
                      helperText={
                        !isUserInformationValid.phone.input
                          ? t(
                              'Account.Profile.ValidationMessage.PhoneNumber',
                              'Phone Number cannot be empty'
                            )
                          : null
                      }
                      disabled={!isEditMode}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </>
        }
      />
      <CountryInformationCard
        user={userInformation}
        setUserInformation={setUserInformation}
        isEditMode={isEditMode}
      />
      <AddressCard
        isEditMode={isEditMode}
        address={address}
        onAddressConfirm={handleAddressEntry}
        headerProps={{
          headerName: t('Profile.ProfileTab.Location.Header', 'Location'),
          id: 'profile-location-header',
        }}
        additionalInformation={
          <>
            <Box mr={0.75}>
              <WarningIcon />
            </Box>
            <Typography variant="body2">
              {t(
                'ProfileTab.Location.Subtitle',
                'Your location information is NEVER shared and visible only to you and your CC leadership.'
              )}
            </Typography>
          </>
        }
      />
      {userDetails.actingAs === 'parent' && (
        <ChildrenSummaryCard
          variant={ChildrenSummaryCardVariant.Account}
          title={t('Account.Profile.ChildrenTable.Header', 'Children')}
          kids={students}
          isEditMode={isEditMode}
          childNameStates={childNameStates}
          childHideStates={childHideStates}
          childBirthMonthStates={childBirthMonthStates}
          childBirthYearStates={childBirthYearStates}
          handleFieldValueChange={handleFieldValueChange}
          enrollmentStates={enrollmentStates}
        />
      )}
      {featureAbility.can('profileVisibility', 'Feature') &&
        userInformation.id > 0 && (
          <ProfileVisibilityCard
            user={userInformation}
            disableSwitches={!isEditMode}
            setUser={setUserInformation}
          />
        )}
    </>
  )
}

function prepareChildrenToUpdate(
  fieldId: string,
  value: string,
  children: Student[],
  childrenToUpdate: EditStudentRequestBody[]
): void {
  const [studentKey, fieldType] = fieldId.split('-')
  const child: Student | undefined = children.find(
    (student) => student.studentKey === parseInt(studentKey)
  )

  if (!!child) {
    let updatedChild = {
      studentKey: child.studentKey,
      firstName: fieldType === 'name' ? value : child.firstName,
      birthMonth:
        fieldType === 'birthMonth' ? Number(value) : Number(child.birthMonth),
      birthYear:
        fieldType === 'birthYear' ? Number(value) : Number(child.birthYear),

      //it verify if the date was change, if the date that already in use is valid date since we are showing invalid date in the children array
      dateOfBirth:
        fieldType === 'dob' && value
          ? new Date(value)
          : //added since child.dateOfBirth can be a date object with invalid Date which is not a valid date
          !isNaN(child.dateOfBirth.getMonth())
          ? child.dateOfBirth
          : undefined,
    }
    //Check if the child is already added and update the child with  the new information.
    const findIndexToUpdate = childrenToUpdate.find((childToUpdate, index) => {
      if (childToUpdate.studentKey === child.studentKey) {
        updatedChild = {
          studentKey: child.studentKey,
          firstName: fieldType === 'name' ? value : childToUpdate.firstName,
          birthMonth:
            fieldType === 'birthMonth'
              ? Number(value)
              : Number(childToUpdate.birthMonth),
          birthYear:
            fieldType === 'birthYear'
              ? Number(value)
              : Number(childToUpdate.birthYear),
          //it verify if the date was change, if the date that already in use is valid date since we are showing invalid date in the children array
          dateOfBirth:
            fieldType === 'dob' && value
              ? new Date(value)
              : childToUpdate.dateOfBirth,
        }

        return (childrenToUpdate[index] = updatedChild)
      }

      return false
    })

    if (!findIndexToUpdate) {
      childrenToUpdate.push(updatedChild)
    }
  }
}

export default ProfileTab
