import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useContext,
} from 'react'
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from 'react-i18next'
import ResponsiveFab from '../Buttons/ResponsiveFab'
import { fetchCommunities } from '../../api/communities'
import { Can } from '@casl/react'
import { useAuth } from '../Routes/AuthProvider'
import { addIconFudgeFactor } from '../../utils/addIconFudgeFactor'
import { Communities, OrderByDirection } from '../../swagger'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { useSnackbarOnNavigation } from '../../hooks/useSnackbarOnNavigation'
import CommunitiesTab from './CommunitiesTab'
import LoadingProgress from '../Elements/LoadingProgress'
import { useAcademicYears } from '../../hooks/useAcademicYears'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import ConfirmationModal from '../Modals/ConfirmationModal'
import ActionButtons from '../Buttons/ActionButtons'
import { defaultPageSize } from '../Pagination/TableFooterPagination'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { Typography } from '@mui/material'
import TitleContext from '../../TitleContext'
import { useCommunitiesContext } from '../Context/CommunitiesContext'
import { useUser } from '../../UserContext'
import { extractedErrorObject } from '../../api/swagger'

export enum SortOptions {
  communityName = 'Sort By: Community Name',
  srLastName = 'Sort By: SR Last Name',
}

enum MemberType {
  member = 'member',
  manager = 'manager',
}

export const isCommunityMemberOrManagerDuringAnyAcademicYear = (
  communities: Communities,
  reference: string
): boolean => {
  // If empty memberCommunities the result is false
  if (communities && reference === MemberType.member) {
    return !!communities.memberCommunities.length
  } else if (communities && reference === MemberType.manager) {
    return !!communities.managedCommunities.length
  }
  return false
}

const defaultSortProperty = 'name'
const defaultSortOrder = OrderByDirection.Asc
const firstFooterPage = 0

const UserCommunities: React.FunctionComponent = () => {
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()
  const { t } = useTranslation()
  const { permissionAbility } = useAuth()
  useSnackbarOnNavigation()
  const initialCommunities: Communities = useMemo(
    () => ({
      managedCommunities: [],
      memberCommunities: [],
    }),
    []
  )
  const navigate = useNavigate()
  const location = useLocation()
  const [communities, setCommunities] =
    useState<Communities>(initialCommunities)

  // const [roles, setRoles] = useState(Array<UserCommunity>())
  const userContext = useUser()
  const { uniqueUserRoles: roles } = userContext

  const [isLoading, setIsLoading] = useState(true)

  const errorMessageFetchUserCommunities = t(
    'Communities.Error.FetchCommunities',
    'An error occurred while retrieving communities.'
  )
  const errorMessageFetchAcademicYears = t(
    'Communities.Error.FetchAcademicYears',
    'An error occurred while retrieving academic years.'
  )

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [sortCommunityBy, setSortCommunityBy] = useState([
    `${defaultSortProperty} ${defaultSortOrder}`,
  ])

  const title = t('Communities.Title', 'Communities')
  const { useTitleEffect } = useContext(TitleContext)
  useTitleEffect(title)

  const [tableFooterPage, setTableFooterPage] = useState(firstFooterPage)

  /**
   * We use this to trigger the useEffect for fetching community data programmatically.
   * The value itself is not significant, however when the value changes it will trigger the useEffect
   */
  const [triggerRefetch, setTriggerRefetch] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    academicYear,
    updateAcademicYear,
    updateSearch,
    search: searchQuery,
  } = useCommunitiesContext()
  const { selectedAcademicYear, academicYears, setSelectedAcademicYear } =
    useAcademicYears({
      errorMessage: errorMessageFetchAcademicYears,
    })

  useEffect(() => {
    const currentYear = academicYear ?? selectedAcademicYear

    setSelectedAcademicYear(currentYear)
    refetchCommunityData()
  }, [academicYear, selectedAcademicYear, setSelectedAcademicYear])

  useEffect(() => {
    const fetchUserCommunities = async () => {
      try {
        const fetchedCommunities =
          selectedAcademicYear > 0
            ? await fetchCommunities({
                page: page,
                pageSize: pageSize,
                academicYear: selectedAcademicYear,
                orderBy: sortCommunityBy,
                search: searchQuery === '' ? undefined : searchQuery,
              })
            : initialCommunities
        setCommunities(fetchedCommunities)
      } catch (e) {
        const errorObject = (await extractedErrorObject(e)) ?? {
          code: 'Unknown',
          message:
            (e as unknown as Error).message ?? errorMessageFetchUserCommunities,
        }
        setSnackbarMessage?.(errorObject.message)
        setSnackbarSeverity?.(SnackbarSeverity.Error)
        setSnackbarState?.(true)
      } finally {
        setIsLoading(false)
        setTriggerRefetch(false)
      }
    }

    //to avoid double load, check if triggerRefetch or is initial load
    if (
      (communities === initialCommunities || triggerRefetch) &&
      selectedAcademicYear >= 0
    ) {
      fetchUserCommunities()
    }
    setTriggerRefetch(false)
  }, [
    errorMessageFetchUserCommunities,
    triggerRefetch,
    page,
    pageSize,
    selectedAcademicYear,
    searchQuery,
    sortCommunityBy,
    communities,
    initialCommunities,
    setSnackbarMessage,
    setSnackbarSeverity,
    setSnackbarState,
  ]) // TODO: Update props to use hook in https://projekt202.atlassian.net/browse/CCP1-2382 so we don't shallow compare props

  const isAMemberOfAnyCommunity = useMemo(
    () =>
      isCommunityMemberOrManagerDuringAnyAcademicYear(
        communities,
        MemberType.member
      ),
    [communities]
  )

  const isAManagerOfAnyCommunity = useMemo(
    () =>
      isCommunityMemberOrManagerDuringAnyAcademicYear(
        communities,
        MemberType.manager
      ),
    [communities]
  )

  const refetchCommunityData = () => {
    // Trigger refetching data
    setTriggerRefetch(true)
  }

  const handleAcademicYearSearch = useCallback(
    (academicYear: number): void => {
      //prevent trigger a refetch on first render
      if (academicYear > 0) {
        setSelectedAcademicYear(academicYear)
        updateAcademicYear(academicYear)
        setPage(1)
        setTableFooterPage(firstFooterPage)
        // Trigger refresh when filtering by year
        refetchCommunityData()
      }
    },
    [setSelectedAcademicYear, updateAcademicYear]
  )

  const handleSearch = (query: string): void => {
    updateSearch(query)
    // Trigger refresh on search
    refetchCommunityData()
  }

  const resetTablePagination = (newPage: number): void => {
    setTableFooterPage(newPage)
  }

  const handleRowsPerPage = (rowAmount: number): void => {
    setPageSize(rowAmount)
    // Trigger refresh on rows per page change
    refetchCommunityData()
  }

  const onPageChange = useCallback((page: number): void => {
    setPage(page)
    // Trigger refresh when changing page
    refetchCommunityData()
  }, [])

  const handleSortOptions = useCallback(
    (
      sortProperty?: string,
      sortOrder?: string,
      sortByLastName?: boolean
    ): void => {
      //prevent trigger a refetch on first render
      if (sortProperty !== 'none') {
        setSortCommunityBy([`${sortProperty} ${sortOrder}`])
        //sort by firstName and lastName if selected options is sort by: sr last name
        if (sortByLastName) {
          const sortByFirstName = 'firstName'
          setSortCommunityBy([
            `${sortProperty} ${sortOrder}`,
            `${sortByFirstName} ${sortOrder}`,
          ])
        }
        setPage(1)
        setTableFooterPage(firstFooterPage)
        // Trigger refresh when sort options change
        refetchCommunityData()
      }
    },
    []
  )

  const updateSelectedYear = (year: number): void => {
    if (year > 0) {
      setSelectedAcademicYear(year)
      setTriggerRefetch(true)
    }
  }

  const AddCommunityFAB = () => (
    <Can I="create" on="Community" ability={permissionAbility}>
      <ResponsiveFab
        icon={AddIcon}
        onClick={() => setIsModalOpen(true)}
        spacingFudgeFactor={addIconFudgeFactor}
        iconLabel={t('Communities.AddCommunityButton.IconLabel', 'Add')}
        textLabel={t('Communities.AddCommunityButton.TextLabel', 'Community')}
        fullLabel={t(
          'Communities.AddCommunityButton.FullLabel',
          'Add Community'
        )}
      />
    </Can>
  )

  const modalConfirmationBody = (
    <Typography variant="body1" component="p" align="center">
      {t(
        'Community.Additions.ConfirmationModal.Body',
        'Continue this process ONLY if the community you need to add does not already exist in CC Connected.'
      )}
    </Typography>
  )

  const modalConfirmationButton = (
    <ActionButtons primaryButtonLabel={ContainedButtonVariant.Ok} />
  )

  const handleConfirmationModal = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsModalOpen(false)
    navigate({ pathname: '/communities/add-community' })
  }

  const modalConfirmationProps = {
    isOpen: isModalOpen,
    dialogContent: modalConfirmationBody,
    dialogActions: modalConfirmationButton,
    handleFormSubmit: handleConfirmationModal,
  }

  if (isLoading) {
    return (
      <>
        {AddCommunityFAB()}
        <LoadingProgress />
      </>
    )
  }

  return (
    <>
      {location.pathname === '/communities' && (
        <>
          {AddCommunityFAB()}
          {<ConfirmationModal {...modalConfirmationProps} />}
          <CommunitiesTab
            communitiesEnrolled={communities.memberCommunities}
            communitiesIManage={communities.managedCommunities}
            academicYears={academicYears}
            updateSelectedYear={updateSelectedYear}
            memberOfCommunity={isAMemberOfAnyCommunity}
            managerOfCommunity={isAManagerOfAnyCommunity}
            selectedYear={selectedAcademicYear}
            handleAcademicYearSearch={handleAcademicYearSearch}
            handleSearch={handleSearch}
            handleSortOptions={handleSortOptions}
            onPageChange={onPageChange}
            handleRowsPerPage={handleRowsPerPage}
            pageSize={pageSize}
            totalCount={communities?.pagination?.totalCount ?? -1}
            resetTablePagination={resetTablePagination}
            tableFooterPage={tableFooterPage}
            roles={roles}
          />
        </>
      )}
      {/* 
        Needed to render the Children components within /communities 
        The community details do not need the Page component, so that's why it's outside the Page
      */}
      <Outlet />
    </>
  )
}

export default UserCommunities
