import React from 'react'
import { Box, TextField } from '@mui/material'
import Header, { HeaderVariant } from '../Elements/Header'
import CardFormHeader from '../Card/CardFormHeader'
import { useTranslation } from 'react-i18next'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { styled } from '@mui/system'
import Paper from '@mui/material/Paper'
import { useTranscriptContext } from '../Context/TranscriptContext'

export interface TranscriptNameCardProps {
  isFieldDisabled?: boolean
  transcriptName?: string
}

const TranscriptNameCardPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(4, 4, 5.5),
}))

export const TranscriptNameCard: React.FC<TranscriptNameCardProps> = ({
  isFieldDisabled,
  transcriptName,
}) => {
  const { t } = useTranslation()
  const showOnDesktop = useShowOnDesktop()
  const {
    updateTranscriptDetails,
    transcriptDetails,
    transcriptValidity,
    updateFormValidity,
  } = useTranscriptContext()
  return (
    <TranscriptNameCardPaper>
      <section aria-labelledby="transcript-name-header">
        <CardFormHeader
          header={
            <Header
              id="transcript-name-header"
              component={'h2'}
              variant={HeaderVariant.Card}
              headerName={t(
                'Transcripts.TranscriptNameHeader.Header.TranscriptInformation',
                'Transcript Information'
              )}
            />
          }
        />
        <Box
          display="flex"
          flexDirection={{ xs: 'column', lg: 'row' }}
          gap={{ xs: 2 }}
        >
          <TextField
            label={t(
              'Transcripts.TranscriptNameHeader.Field.TranscriptName',
              'Transcript Name'
            )}
            variant="filled"
            disabled={isFieldDisabled}
            sx={{
              mx: showOnDesktop ? 2 : 0,
              marginRight: 2,
              maxWidth: '48%',
            }}
            value={transcriptName}
            fullWidth
            onChange={(e) => {
              updateFormValidity({
                ...transcriptValidity,
                transcriptName: { input: true },
              })
              updateTranscriptDetails({
                ...transcriptDetails,
                transcriptName: e.target.value,
              })
            }}
            helperText={
              !transcriptValidity.transcriptName.input
                ? t(
                    'Transcripts.TranscriptNameCard.Error.TranscriptName',
                    'Transcript Name is required.'
                  )
                : null
            }
            error={!transcriptValidity.transcriptName.input}
            required
          />
          {isFieldDisabled && (
            <TextField
              label={t(
                'Transcripts.StudentInformationHeader.Field.RequestStatus',
                'Parchment Request Status'
              )}
              variant="filled"
              disabled
              sx={{
                mx: showOnDesktop ? 2 : 0,
              }}
              value={transcriptDetails.requestStatus ?? ''}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          )}
        </Box>
      </section>
    </TranscriptNameCardPaper>
  )
}

export default TranscriptNameCard
