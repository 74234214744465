/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Grants the ability to (actionCode) (scopeCode) (resourceCode), e.g., “logout any User”
 * @export
 * @interface Grant
 */
export interface Grant {
  /**
   *
   * @type {string}
   * @memberof Grant
   */
  actionCode: string
  /**
   *
   * @type {string}
   * @memberof Grant
   */
  scopeCode: GrantScopeCodeEnum
  /**
   *
   * @type {string}
   * @memberof Grant
   */
  resourceCode: string
}

/**
 * @export
 * @enum {string}
 */
export enum GrantScopeCodeEnum {
  Any = 'any',
  TheirTeams = "their team's",
}

export function GrantFromJSON(json: any): Grant {
  return GrantFromJSONTyped(json, false)
}

export function GrantFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Grant {
  if (json === undefined || json === null) {
    return json
  }
  return {
    actionCode: json['actionCode'],
    scopeCode: json['scopeCode'],
    resourceCode: json['resourceCode'],
  }
}

export function GrantToJSON(value?: Grant | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    actionCode: value.actionCode,
    scopeCode: value.scopeCode,
    resourceCode: value.resourceCode,
  }
}
