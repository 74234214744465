/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  AgreementWebhookRequestBodyAgreementDeviceInfo,
  AgreementWebhookRequestBodyAgreementDeviceInfoFromJSON,
  AgreementWebhookRequestBodyAgreementDeviceInfoToJSON,
  AgreementWebhookRequestBodyAgreementDocumentsInfo,
  AgreementWebhookRequestBodyAgreementDocumentsInfoFromJSON,
  AgreementWebhookRequestBodyAgreementDocumentsInfoToJSON,
  AgreementWebhookRequestBodyAgreementExternalId,
  AgreementWebhookRequestBodyAgreementExternalIdFromJSON,
  AgreementWebhookRequestBodyAgreementExternalIdToJSON,
  AgreementWebhookRequestBodyAgreementParticipantSetsInfo,
  AgreementWebhookRequestBodyAgreementParticipantSetsInfoFromJSON,
  AgreementWebhookRequestBodyAgreementParticipantSetsInfoToJSON,
  AgreementWebhookRequestBodyAgreementPostSignOption,
  AgreementWebhookRequestBodyAgreementPostSignOptionFromJSON,
  AgreementWebhookRequestBodyAgreementPostSignOptionToJSON,
  AgreementWebhookRequestBodyAgreementVaultingInfo,
  AgreementWebhookRequestBodyAgreementVaultingInfoFromJSON,
  AgreementWebhookRequestBodyAgreementVaultingInfoToJSON,
  Css,
  CssFromJSON,
  CssToJSON,
} from './'

/**
 *
 * @export
 * @interface AgreementWebhookRequestBodyAgreement
 */
export interface AgreementWebhookRequestBodyAgreement {
  /**
   * Agreement Id
   * @type {string}
   * @memberof AgreementWebhookRequestBodyAgreement
   */
  id?: string
  /**
   *  The agreement name
   * @type {string}
   * @memberof AgreementWebhookRequestBodyAgreement
   */
  name?: string
  /**
   * Agreement signature type
   * @type {string}
   * @memberof AgreementWebhookRequestBodyAgreement
   */
  signatureType?: string
  /**
   * Agreement status
   * @type {string}
   * @memberof AgreementWebhookRequestBodyAgreement
   */
  status?: string
  /**
   *
   * @type {Array<Css>}
   * @memberof AgreementWebhookRequestBodyAgreement
   */
  ccs?: Array<Css>
  /**
   *
   * @type {AgreementWebhookRequestBodyAgreementDeviceInfo}
   * @memberof AgreementWebhookRequestBodyAgreement
   */
  deviceInfo?: AgreementWebhookRequestBodyAgreementDeviceInfo
  /**
   *
   * @type {boolean}
   * @memberof AgreementWebhookRequestBodyAgreement
   */
  documentVisibilityEnabled?: boolean
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBodyAgreement
   */
  createdDate?: string
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBodyAgreement
   */
  expirationTime?: string
  /**
   *
   * @type {AgreementWebhookRequestBodyAgreementExternalId}
   * @memberof AgreementWebhookRequestBodyAgreement
   */
  externalId?: AgreementWebhookRequestBodyAgreementExternalId
  /**
   *
   * @type {AgreementWebhookRequestBodyAgreementPostSignOption}
   * @memberof AgreementWebhookRequestBodyAgreement
   */
  postSignOption?: AgreementWebhookRequestBodyAgreementPostSignOption
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBodyAgreement
   */
  firstReminderDelay?: string
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBodyAgreement
   */
  locale?: string
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBodyAgreement
   */
  message?: string
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBodyAgreement
   */
  reminderFrequency?: string
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBodyAgreement
   */
  senderEmail?: string
  /**
   *
   * @type {AgreementWebhookRequestBodyAgreementVaultingInfo}
   * @memberof AgreementWebhookRequestBodyAgreement
   */
  vaultingInfo?: AgreementWebhookRequestBodyAgreementVaultingInfo
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBodyAgreement
   */
  workflowId?: string
  /**
   *
   * @type {AgreementWebhookRequestBodyAgreementParticipantSetsInfo}
   * @memberof AgreementWebhookRequestBodyAgreement
   */
  participantSetsInfo?: AgreementWebhookRequestBodyAgreementParticipantSetsInfo
  /**
   *
   * @type {AgreementWebhookRequestBodyAgreementDocumentsInfo}
   * @memberof AgreementWebhookRequestBodyAgreement
   */
  documentsInfo?: AgreementWebhookRequestBodyAgreementDocumentsInfo
}

export function AgreementWebhookRequestBodyAgreementFromJSON(
  json: any
): AgreementWebhookRequestBodyAgreement {
  return AgreementWebhookRequestBodyAgreementFromJSONTyped(json, false)
}

export function AgreementWebhookRequestBodyAgreementFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementWebhookRequestBodyAgreement {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    signatureType: !exists(json, 'signatureType')
      ? undefined
      : json['signatureType'],
    status: !exists(json, 'status') ? undefined : json['status'],
    ccs: !exists(json, 'ccs')
      ? undefined
      : (json['ccs'] as Array<any>).map(CssFromJSON),
    deviceInfo: !exists(json, 'deviceInfo')
      ? undefined
      : AgreementWebhookRequestBodyAgreementDeviceInfoFromJSON(
          json['deviceInfo']
        ),
    documentVisibilityEnabled: !exists(json, 'documentVisibilityEnabled')
      ? undefined
      : json['documentVisibilityEnabled'],
    createdDate: !exists(json, 'createdDate') ? undefined : json['createdDate'],
    expirationTime: !exists(json, 'expirationTime')
      ? undefined
      : json['expirationTime'],
    externalId: !exists(json, 'externalId')
      ? undefined
      : AgreementWebhookRequestBodyAgreementExternalIdFromJSON(
          json['externalId']
        ),
    postSignOption: !exists(json, 'postSignOption')
      ? undefined
      : AgreementWebhookRequestBodyAgreementPostSignOptionFromJSON(
          json['postSignOption']
        ),
    firstReminderDelay: !exists(json, 'firstReminderDelay')
      ? undefined
      : json['firstReminderDelay'],
    locale: !exists(json, 'locale') ? undefined : json['locale'],
    message: !exists(json, 'message') ? undefined : json['message'],
    reminderFrequency: !exists(json, 'reminderFrequency')
      ? undefined
      : json['reminderFrequency'],
    senderEmail: !exists(json, 'senderEmail') ? undefined : json['senderEmail'],
    vaultingInfo: !exists(json, 'vaultingInfo')
      ? undefined
      : AgreementWebhookRequestBodyAgreementVaultingInfoFromJSON(
          json['vaultingInfo']
        ),
    workflowId: !exists(json, 'workflowId') ? undefined : json['workflowId'],
    participantSetsInfo: !exists(json, 'participantSetsInfo')
      ? undefined
      : AgreementWebhookRequestBodyAgreementParticipantSetsInfoFromJSON(
          json['participantSetsInfo']
        ),
    documentsInfo: !exists(json, 'documentsInfo')
      ? undefined
      : AgreementWebhookRequestBodyAgreementDocumentsInfoFromJSON(
          json['documentsInfo']
        ),
  }
}

export function AgreementWebhookRequestBodyAgreementToJSON(
  value?: AgreementWebhookRequestBodyAgreement | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    signatureType: value.signatureType,
    status: value.status,
    ccs:
      value.ccs === undefined
        ? undefined
        : (value.ccs as Array<any>).map(CssToJSON),
    deviceInfo: AgreementWebhookRequestBodyAgreementDeviceInfoToJSON(
      value.deviceInfo
    ),
    documentVisibilityEnabled: value.documentVisibilityEnabled,
    createdDate: value.createdDate,
    expirationTime: value.expirationTime,
    externalId: AgreementWebhookRequestBodyAgreementExternalIdToJSON(
      value.externalId
    ),
    postSignOption: AgreementWebhookRequestBodyAgreementPostSignOptionToJSON(
      value.postSignOption
    ),
    firstReminderDelay: value.firstReminderDelay,
    locale: value.locale,
    message: value.message,
    reminderFrequency: value.reminderFrequency,
    senderEmail: value.senderEmail,
    vaultingInfo: AgreementWebhookRequestBodyAgreementVaultingInfoToJSON(
      value.vaultingInfo
    ),
    workflowId: value.workflowId,
    participantSetsInfo:
      AgreementWebhookRequestBodyAgreementParticipantSetsInfoToJSON(
        value.participantSetsInfo
      ),
    documentsInfo: AgreementWebhookRequestBodyAgreementDocumentsInfoToJSON(
      value.documentsInfo
    ),
  }
}
