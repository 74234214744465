/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ChangeEmailConfirmTokenRequestBody
 */
export interface ChangeEmailConfirmTokenRequestBody {
  /**
   * The 16-digit one-time token sent to the email address via the `sendTokenForChangeEmail` route.
   * @type {string}
   * @memberof ChangeEmailConfirmTokenRequestBody
   */
  token: string
}

export function ChangeEmailConfirmTokenRequestBodyFromJSON(
  json: any
): ChangeEmailConfirmTokenRequestBody {
  return ChangeEmailConfirmTokenRequestBodyFromJSONTyped(json, false)
}

export function ChangeEmailConfirmTokenRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChangeEmailConfirmTokenRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    token: json['token'],
  }
}

export function ChangeEmailConfirmTokenRequestBodyToJSON(
  value?: ChangeEmailConfirmTokenRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    token: value.token,
  }
}
