import React, { PropsWithChildren, useCallback, useRef, useState } from 'react'
import { Breadcrumb } from '../Elements/DynamicBreadcrumbs'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Event } from '../../swagger'

const warnDeveloper = <T,>(propertyName?: T) => {
  const placeHold =
    propertyName === undefined ? '.' : ` with the value ${propertyName}.`
  console.warn(
    `The EventContext.resetContextToDefaults was called${placeHold} Did you forget to use a EventProvider?`
  )
}

export const defaultEventContextValue = {
  breadcrumbs: [{ label: 'Events' }] as Breadcrumb[],

  updateBreadcrumbs: (eventName?: string): void => {
    warnDeveloper(eventName)
  },

  eventDetails: undefined as Event | undefined,

  updateEventDetails: (eventDetails?: Event): void => {
    warnDeveloper(eventDetails)
  },

  resetContextToDefaults: (): void => {
    warnDeveloper()
  },
}

const EventContext = React.createContext(defaultEventContextValue)

export const useEventContext = (): typeof defaultEventContextValue =>
  React.useContext(EventContext)

export type TestEventConfig = Partial<typeof defaultEventContextValue>

export interface TestEventConfigWithProps extends PropsWithChildren {
  testConfig?: TestEventConfig
}

export const EventProvider: React.FC<TestEventConfigWithProps> = ({
  testConfig,
  children,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const eventsTranslated = t('Event.Events', 'Events')
  const defaultBreadcrumbs = useRef([{ label: eventsTranslated }])

  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>(
    defaultBreadcrumbs.current
  )

  const updateBreadcrumbs = useCallback(
    (eventName?: string) => {
      setBreadcrumbs([
        {
          label: eventsTranslated,
          onClick: () => {
            navigate({ pathname: '/events' })
          },
        },
        {
          label: eventName,
        },
      ])
    },
    [eventsTranslated, navigate]
  )

  const [eventDetails, setEventDetails] = useState<Event | undefined>(undefined)

  const updateEventDetails = (eventDetails?: Event): void => {
    setEventDetails(eventDetails)
  }

  const resetContextToDefaults = (): void => {
    setBreadcrumbs(defaultBreadcrumbs.current)
    setEventDetails(undefined)
  }

  const value = {
    breadcrumbs,
    updateBreadcrumbs,
    eventDetails,
    updateEventDetails,
    resetContextToDefaults,
    ...testConfig,
  } as typeof defaultEventContextValue

  return <EventContext.Provider value={value}>{children}</EventContext.Provider>
}
