/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The various program types
 * @export
 * @enum {string}
 */
export enum ProgramType {
  Foundations = 'Foundations',
  Scribblers = 'Scribblers',
  Essentials = 'Essentials',
  ChallengeA = 'Challenge A',
  ChallengeB = 'Challenge B',
  ChallengeI = 'Challenge I',
  ChallengeIi = 'Challenge II',
  ChallengeIii = 'Challenge III',
  ChallengeIv = 'Challenge IV',
}

export function ProgramTypeFromJSON(json: any): ProgramType {
  return ProgramTypeFromJSONTyped(json, false)
}

export function ProgramTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProgramType {
  return json as ProgramType
}

export function ProgramTypeToJSON(value?: ProgramType | null): any {
  return value as any
}
