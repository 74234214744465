/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ContentAsset, ContentAssetFromJSON, ContentAssetToJSON } from './'

/**
 *
 * @export
 * @interface LearningPath
 */
export interface LearningPath {
  /**
   * The title for the Learning Path.
   * @type {string}
   * @memberof LearningPath
   */
  title: string
  /**
   * The description of the Learning Path.
   * @type {string}
   * @memberof LearningPath
   */
  description: string
  /**
   * All the assets within a learning path.
   * @type {Array<ContentAsset>}
   * @memberof LearningPath
   */
  assets: Array<ContentAsset>
}

export function LearningPathFromJSON(json: any): LearningPath {
  return LearningPathFromJSONTyped(json, false)
}

export function LearningPathFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LearningPath {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: json['title'],
    description: json['description'],
    assets: (json['assets'] as Array<any>).map(ContentAssetFromJSON),
  }
}

export function LearningPathToJSON(value?: LearningPath | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: value.title,
    description: value.description,
    assets: (value.assets as Array<any>).map(ContentAssetToJSON),
  }
}
