import React from 'react'
import { InvoiceInfo } from '../../swagger'
import RowActions, { Action } from '../Table/RowActions'
import { useTranslation } from 'react-i18next'
import { InvoicePaymentModalVariant } from '../Modals/InvoicePaymentModal'
import {
  Preview as PreviewIcon,
  Send as SendIcon,
  LocalOffer as LocalOfferIcon,
  Payments as PaymentsIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material'
import { useAuth } from '../Routes/AuthProvider'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'

interface InvoiceDirectorMenuProps {
  billingUserKey: number
  isTutorBilling: boolean
  invoice: InvoiceInfo
  handlePayment: (
    billingUserKey: number,
    isTutorBilling: boolean,
    variantToShow: InvoicePaymentModalVariant,
    invoiceKey: number
  ) => void
  parentEmail: string
  navigateToInvoice: (showSendInvoiceButton: boolean) => void
  handleViewSentHistory: () => void
  addTour?: boolean
  handleTourFinished?: (value: boolean) => void
}

export type statusTour = {
  stepOne: boolean
  stepTwo: boolean
}

export interface InvoiceDetails {
  parentEmail: string
  invoiceKey: number
  billingUserKey: number
  isTutorBilling: boolean
  showResendInvoiceInMenu: boolean
}

export const tourConfig = {
  joyRideTourId: 'joyride-Invoice-menus-complete',
  tourClassForDirectorMenu: `InvoiceDirectorMenu-tour`,
  tourClassForInvoiceTableMenu: 'TuitionBillingTable-tour',
  invoiceStepsCompletionStatus: 'invoiceStepsCompletionStatus',
}

const showSendInvoiceButton = true
export const InvoiceDirectorMenu: React.FC<InvoiceDirectorMenuProps> = ({
  billingUserKey,
  isTutorBilling,
  invoice,
  handlePayment,
  parentEmail,
  navigateToInvoice,
  handleViewSentHistory,
  addTour,
  handleTourFinished,
}): React.JSX.Element => {
  const showOnDesktop = useShowOnDesktop()
  const handleFinishTour = () => {
    /**
     * IF the first billing Info has payments in its invoice, the tour will complete in one step
     * otherwise, we need to wait after the first billing Info has a payment
     */

    if ((invoice.payments ?? [])?.length > 0) {
      localStorage.setItem(
        tourConfig.invoiceStepsCompletionStatus,
        JSON.stringify({ stepOne: true, stepTwo: true })
      )
      handleTourFinished?.(!showOnDesktop)
    } else {
      localStorage.setItem(
        tourConfig.invoiceStepsCompletionStatus,
        JSON.stringify({ stepOne: true, stepTwo: false })
      )
    }
  }
  const { t } = useTranslation()
  const { permissionAbility } = useAuth()

  const canSendTuitionInvoice = permissionAbility.can(
    'sendTuitionInvoice',
    'Payment'
  )

  const canAddTuitionPayment = permissionAbility.can(
    'addTuitionPayment',
    'Payment'
  )

  const canAddTuitionCredit = permissionAbility.can(
    'addTuitionCredit',
    'Payment'
  )

  const invoiceDetails = {
    parentEmail,
    invoiceKey: invoice.invoiceKey,
    billingUserKey,
    isTutorBilling,
    showResendInvoiceInMenu: !!invoice.lastSentEmailDate,
  }

  const items: Action<InvoiceDetails>[] = [
    {
      actionName: t('Invoice.DirectorMenu.Action.View', 'View Invoice'),
      actionKey: 'viewInvoice',
      actionFunction: (invoiceDetails) => {
        invoiceDetails && navigateToInvoice(!showSendInvoiceButton)
      },
      menuItemIcon: <PreviewIcon />,
      hide: () => !canSendTuitionInvoice,
    },
    {
      actionName: t(
        'Invoice.DirectorMenu.Action.SendOrResendInvoice',
        'Send Invoice'
      ),
      actionKey: 'sendOrResendInvoice',
      dynamicActionName: {
        fieldNameUseForFlip: 'showResendInvoiceInMenu',
        switchableActionsNames: [
          {
            actionName: t(
              'Invoice.DirectorMenu.Action.SendOrResendInvoice',
              'Send Invoice'
            ),
            flipValue: false,
          },
          {
            actionName: t(
              'Invoice.DirectorMenu.Action.SendOrResendInvoice',
              'Resend Invoice'
            ),
            flipValue: true,
          },
        ],
      },
      actionFunction: (invoiceDetails) => {
        invoiceDetails && navigateToInvoice(showSendInvoiceButton)
      },
      menuItemIcon: <SendIcon />,
      hide: () => !canSendTuitionInvoice,
    },
    {
      actionName: t(
        'Invoice.DirectorMenu.Action.EnterDiscount',
        'Enter Discount'
      ),
      actionKey: 'enterDiscount',
      actionFunction: (invoiceDetails) =>
        invoiceDetails &&
        handlePayment(
          invoiceDetails.billingUserKey,
          invoiceDetails.isTutorBilling,
          InvoicePaymentModalVariant.AddDiscount,
          invoice.invoiceKey
        ),
      menuItemIcon: <LocalOfferIcon />,
      hide: () => !canAddTuitionCredit,
    },
    {
      actionName: t(
        'Invoice.DirectorMenu.Action.EnterPayment',
        'Enter Payment'
      ),
      actionKey: 'enterPayment',
      actionFunction: (invoiceDetails) =>
        invoiceDetails &&
        handlePayment(
          invoiceDetails.billingUserKey,
          invoiceDetails.isTutorBilling,
          InvoicePaymentModalVariant.AddPayment,
          invoice.invoiceKey
        ),
      menuItemIcon: <PaymentsIcon />,
      hide: () => !canAddTuitionPayment,
    },
    {
      actionName: t(
        'Invoice.DirectorMenu.Action.ViewSentHistory',
        'View Sent History'
      ),
      actionKey: 'viewSentHistory',
      actionFunction: () => handleViewSentHistory(),
      menuItemIcon: <VisibilityIcon />,
      hide: () => false,
    },
  ]
  // Tour config
  const isTabInvitesTourCompleted = localStorage.getItem(
    tourConfig.joyRideTourId
  )
  const stepsForInvoice = localStorage.getItem(
    tourConfig.invoiceStepsCompletionStatus
  )

  const parsedSteps = !!stepsForInvoice
    ? (JSON.parse(stepsForInvoice as string) as unknown as statusTour)
    : { stepOne: false, stepTwo: false }

  /**
   * validations to be displayed
   * if an invoice exists but there are no records in the table
   * (shows only the first step)
   */

  const rowActionsTourSteps = !parsedSteps.stepOne
    ? [
        {
          target: `.${tourConfig.tourClassForDirectorMenu}`,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
          title: t(
            'InvoiceDirectorMenu.Tour.StepZero.Title',
            `Discount, Payment & Invoice button have moved!`
          ),
          content: t(
            'InvoiceDirectorMenu.Tour.StepZero.Content',
            `Click here to see the buttons.`
          ),
          disableBeacon: true,
          disableScrolling: false,
        },
      ]
    : []

  // if an invoice exists and there is at least one record in the table add the second step
  if (!!invoice.invoiceKey && (invoice.payments?.length ?? 0) > 0) {
    rowActionsTourSteps.push({
      target: `.${tourConfig.tourClassForInvoiceTableMenu}`,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      title: t(
        'TuitionBillingTable.Tour.StepOne.Title',
        `View button has moved!`
      ),
      content: t(
        'TuitionBillingTable.Tour.StepOne.Content',
        `Click here to see new Edit button.`
      ),
      disableBeacon: true,
      disableScrolling: false,
    })
  }
  /**
   * Takes care of adjusting the tour when there is no payment,
   * displays the first step and loads information in Local Storage
   * to inform that we have only presented one step, once there is a
   *  payment the second step is presented to the user.
   */

  return (
    <RowActions
      elementsCount={items.length}
      actions={items}
      row={invoiceDetails}
      joyRideTourId={addTour ? tourConfig.joyRideTourId : undefined}
      rowActionsTourSteps={rowActionsTourSteps}
      isTourCompleted={addTour ? !!isTabInvitesTourCompleted : undefined}
      tourClass={addTour ? tourConfig.tourClassForDirectorMenu : undefined}
      rowId={1}
      scrollToFirstStep
      onCompleteTour={handleFinishTour}
    />
  )
}
