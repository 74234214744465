/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ProgramInvite
 */
export interface ProgramInvite {
  /**
   * Program the invite is extended for. To be valid, the program must be published, have started within the last year, and visible to the inviting user. To be permitted, the user must also have a valid invite Family grant over the program. Cf. fetchInviteFamilyOptions.
   * @type {number}
   * @memberof ProgramInvite
   */
  programKey: number
  /**
   * Number of offered student spots for this program on the invite. Must be at least 1.
   * @type {number}
   * @memberof ProgramInvite
   */
  offeredSpots: number
}

export function ProgramInviteFromJSON(json: any): ProgramInvite {
  return ProgramInviteFromJSONTyped(json, false)
}

export function ProgramInviteFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProgramInvite {
  if (json === undefined || json === null) {
    return json
  }
  return {
    programKey: json['programKey'],
    offeredSpots: json['offeredSpots'],
  }
}

export function ProgramInviteToJSON(value?: ProgramInvite | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    programKey: value.programKey,
    offeredSpots: value.offeredSpots,
  }
}
