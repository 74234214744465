/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  AgreementWebhookRequestBodyAgreementDocumentsInfoSignedDocumentInfo,
  AgreementWebhookRequestBodyAgreementDocumentsInfoSignedDocumentInfoFromJSON,
  AgreementWebhookRequestBodyAgreementDocumentsInfoSignedDocumentInfoToJSON,
  Documents,
  DocumentsFromJSON,
  DocumentsToJSON,
  SupportDocuments,
  SupportDocumentsFromJSON,
  SupportDocumentsToJSON,
} from './'

/**
 *
 * @export
 * @interface AgreementWebhookRequestBodyAgreementDocumentsInfo
 */
export interface AgreementWebhookRequestBodyAgreementDocumentsInfo {
  /**
   *
   * @type {Array<Documents>}
   * @memberof AgreementWebhookRequestBodyAgreementDocumentsInfo
   */
  documents?: Array<Documents>
  /**
   *
   * @type {Array<SupportDocuments>}
   * @memberof AgreementWebhookRequestBodyAgreementDocumentsInfo
   */
  supportingDocuments?: Array<SupportDocuments>
  /**
   *
   * @type {AgreementWebhookRequestBodyAgreementDocumentsInfoSignedDocumentInfo}
   * @memberof AgreementWebhookRequestBodyAgreementDocumentsInfo
   */
  signedDocumentInfo?: AgreementWebhookRequestBodyAgreementDocumentsInfoSignedDocumentInfo
}

export function AgreementWebhookRequestBodyAgreementDocumentsInfoFromJSON(
  json: any
): AgreementWebhookRequestBodyAgreementDocumentsInfo {
  return AgreementWebhookRequestBodyAgreementDocumentsInfoFromJSONTyped(
    json,
    false
  )
}

export function AgreementWebhookRequestBodyAgreementDocumentsInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementWebhookRequestBodyAgreementDocumentsInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    documents: !exists(json, 'documents')
      ? undefined
      : (json['documents'] as Array<any>).map(DocumentsFromJSON),
    supportingDocuments: !exists(json, 'supportingDocuments')
      ? undefined
      : (json['supportingDocuments'] as Array<any>).map(
          SupportDocumentsFromJSON
        ),
    signedDocumentInfo: !exists(json, 'signedDocumentInfo')
      ? undefined
      : AgreementWebhookRequestBodyAgreementDocumentsInfoSignedDocumentInfoFromJSON(
          json['signedDocumentInfo']
        ),
  }
}

export function AgreementWebhookRequestBodyAgreementDocumentsInfoToJSON(
  value?: AgreementWebhookRequestBodyAgreementDocumentsInfo | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    documents:
      value.documents === undefined
        ? undefined
        : (value.documents as Array<any>).map(DocumentsToJSON),
    supportingDocuments:
      value.supportingDocuments === undefined
        ? undefined
        : (value.supportingDocuments as Array<any>).map(SupportDocumentsToJSON),
    signedDocumentInfo:
      AgreementWebhookRequestBodyAgreementDocumentsInfoSignedDocumentInfoToJSON(
        value.signedDocumentInfo
      ),
  }
}
