import React, { useState } from 'react'
import CardFormHeader from './CardFormHeader'
import Header, { HeaderVariant } from '../Elements/Header'
import { Box, Card, TableContainer, Typography, useTheme } from '@mui/material'
import TableHeaders from '../Interfaces/TableHeaders'
import LedgerTable, { LedgerCell, LedgerRow } from '../Table/LedgerTable'
import { useTranslation } from 'react-i18next'
import {
  InviteTabFlowStages,
  useAccountContext,
} from '../Context/AccountContext'
import { useMountEffect } from '../../hooks/useMountEffect'
import PaymentSelection from '../Elements/PaymentSelection'
import PersonAdd from '../../Images/person_add_96x96.svg'
import PersonAddSelected from '../../Images/person_add_96x96_selected.svg'
import Checklist from '../../Images/checklist_96x96.svg'
import ChecklistSelected from '../../Images/checklist_96x96_selected.svg'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import { useNavigate } from 'react-router'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { UTCYearString } from '../../utils/dateUtility'

const InvitationSummaryCard: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const showOnDesktop = useShowOnDesktop()

  /**
   * Students ought to come from fetchStudentsForFamily
   * whereas out enrollmentInvite information should be passed from
   * a list of enrollmentInvites. We can set the AccountContext to have a
   * selectedEnrollmentInvite, too, since other steps in this flow will use
   * that same info.
   */
  const { students, selectedEnrollmentInvite, updateBreadcrumbs } =
    useAccountContext()

  const programInformationHeaders: TableHeaders[] = [
    {
      align: 'left',
      label: t(`InvitationSummaryCard.TableHeader.Program`, 'Program'),
      id: 'Program',
      render: (
        <Typography
          fontWeight="bold"
          variant="subtitle2"
          color={theme.palette.textOrIcon.onBackground}
        >
          {t(`InvitationSummaryCard.TableHeader.Program`, 'Program')}
        </Typography>
      ),
    },
    {
      align: 'left',
      label: t(`InvitationSummaryCard.TableHeader.Community`, 'Community'),
      id: 'Community',
      render: (
        <Typography
          fontWeight="bold"
          variant="subtitle2"
          color={theme.palette.textOrIcon.onBackground}
        >
          {t(`InvitationSummaryCard.TableHeader.Community`, 'Community')}
        </Typography>
      ),
    },
    {
      align: 'left',
      label: t(
        `InvitationSummaryCard.TableHeader.AcademicYear`,
        'Academic Year'
      ),
      id: 'Academic Year',
      render: (
        <Typography
          fontWeight="bold"
          variant="subtitle2"
          color={theme.palette.textOrIcon.onBackground}
        >
          {t(`InvitationSummaryCard.TableHeader.AcademicYear`, 'Academic Year')}
        </Typography>
      ),
    },
    {
      align: 'center',
      label: t(
        `InvitationSummaryCard.TableHeader.OfferedSpots`,
        'Offered Spots'
      ),
      id: 'Offered Spots',
      render: (
        <Typography
          fontWeight="bold"
          variant="subtitle2"
          color={theme.palette.textOrIcon.onBackground}
        >
          {t(`InvitationSummaryCard.TableHeader.OfferedSpots`, 'Offered Spots')}
        </Typography>
      ),
    },
  ]

  const childrenInformationHeaders: TableHeaders[] = [
    {
      align: 'left',
      label: t(`InvitationSummaryCard.TableHeader.Children`, 'Children'),
      id: 'Children',
      render: (
        <Typography
          fontWeight="bold"
          variant="subtitle2"
          color={theme.palette.textOrIcon.onBackground}
        >
          {t(`InvitationSummaryCard.TableHeader.Children`, 'Children')}
        </Typography>
      ),
    },
  ]

  const programInformationRows =
    selectedEnrollmentInvite?.programs.map((program) => {
      return {
        cells: [
          {
            align: 'left',
            content: program.programType,
            cssTextProps: { pl: theme.spacing(2) },
          } as LedgerCell,
          {
            align: 'left',
            content: selectedEnrollmentInvite.communityName,
          } as LedgerCell,
          {
            align: 'left',
            /**
             * We really ought to always have a semester date if we're inviting
             * Perhaps a FIXME: would be updating the EnrollmentInvite schema
             */
            content: UTCYearString(program.semesterOneStartDate ?? new Date())
              ,
          } as LedgerCell,
          {
            align: 'center',
            content: `${program.offeredSpots}`,
          } as LedgerCell,
        ],
      } as LedgerRow
    }) ?? [] // Because our selection can be empty (in test) give a default of empty.

  useMountEffect(() => {
    updateBreadcrumbs(InviteTabFlowStages.InvitationSummary)
  })

  const [actionSelection, setActionSelection] = useState({
    addChildren: false,
    assignChildrenToPrograms: false,
  })

  const toggleSelection = (opts: { add: boolean }) => {
    const { add } = opts
    setActionSelection(({ addChildren, assignChildrenToPrograms }) => ({
      addChildren: add === addChildren ? false : add,
      assignChildrenToPrograms:
        !add === assignChildrenToPrograms ? false : !add,
    }))
  }

  const AddChildrenImage = () => (
    <img
      alt={t('InvitationSummaryCard.AltText.AddChildren', 'Add Children')}
      src={actionSelection.addChildren ? PersonAddSelected : PersonAdd}
    />
  )

  const AssignChildrenImage = () => (
    <img
      alt={t('InvitationSummaryCard.AltText.AssignChildren', 'Assign Children')}
      src={
        actionSelection.assignChildrenToPrograms ? ChecklistSelected : Checklist
      }
    />
  )

  const handleNext = () => {
    if (actionSelection.addChildren) {
      navigate({ pathname: '/account/invites/add-children' })
    } else if (actionSelection.assignChildrenToPrograms) {
      navigate({ pathname: '/account/invites/assign-children' })
    }
  }

  return (
    <Card>
      <CardFormHeader
        header={
          <Header
            headerName={t(
              `InvitationSummaryCard.TableHeader.InvitationSummary`,
              'Invitation Summary'
            )}
            id="invitation-summary"
            component="h3"
            variant={HeaderVariant.Card}
          />
        }
      />
      {/* Section of the Invitation Summary containing tables for Program and children information */}
      <section aria-labelledby="invitation-summary">
        <TableContainer
          id="program-information-container"
          sx={{
            mx: theme.spacing(3),
            width: 'auto',
          }}
        >
          <LedgerTable
            tableHeaders={programInformationHeaders}
            ariaLabel="Program Information"
            rows={programInformationRows}
          />
        </TableContainer>
        <TableContainer
          id="children-information-container"
          sx={{
            mx: theme.spacing(3),
            width: 'auto',
          }}
        >
          <LedgerTable
            tableHeaders={childrenInformationHeaders}
            ariaLabel="Children Information"
            rows={students.map((student) => {
              return {
                cells: [
                  {
                    align: 'left',
                    content: student.firstName,
                    cssTextProps: { pl: theme.spacing(2) },
                  } as LedgerCell,
                ],
              } as LedgerRow
            })}
          />
        </TableContainer>
      </section>
      {/* Section of the Invitation Summary containing tables for Program and children information */}
      <CardFormHeader
        header={
          <Header
            headerName={t(
              `InvitationSummaryCard.TableHeader.ChooseAnAction`,
              'Choose an Action'
            )}
            id="choose-an-action"
            component="h3"
            variant={HeaderVariant.Card}
          />
        }
      />
      <section aria-labelledby="choose-an-action">
        <Box
          display="flex"
          m={3}
          flexDirection={showOnDesktop ? 'row' : 'column'}
        >
          <Box mr={3} width={'100%'} mb={showOnDesktop ? 0 : 3}>
            <PaymentSelection
              header={t(
                'InvitationSummaryCard.Selection.AddChildren.Header',
                'Add Children'
              )}
              onClick={() => toggleSelection({ add: true })}
              isSelected={actionSelection.addChildren}
              subheader={t(
                'InvitationSummaryCard.Selection.AddChildren.Subheader',
                'Update children before program assignment.'
              )}
              image={AddChildrenImage()}
            />
          </Box>
          <Box width={'100%'}>
            <PaymentSelection
              header={t(
                'InvitationSummaryCard.Selection.AssignChildrenToPrograms.Header',
                'Assign Children to Programs'
              )}
              onClick={() => toggleSelection({ add: false })}
              isSelected={actionSelection.assignChildrenToPrograms}
              subheader={t(
                'InvitationSummaryCard.Selection.AssignChildrenToPrograms.Subheader',
                'Select a child for each program in the invitation or decline the invitation.'
              )}
              image={AssignChildrenImage()}
            />
          </Box>
        </Box>
        <Box
          justifyContent={'center'}
          display="flex"
          m={`0 ${theme.spacing(3)} ${theme.spacing(3)}`}
        >
          <ContainedButton
            id="Next"
            variant={ContainedButtonVariant.Next}
            disabled={
              !actionSelection.addChildren &&
              !actionSelection.assignChildrenToPrograms
            }
            onClick={handleNext}
            css={{
              width: showOnDesktop ? '50%' : '100%',
            }}
          />
        </Box>
      </section>
    </Card>
  )
}

export default InvitationSummaryCard
