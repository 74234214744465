import { Box, Typography, useTheme } from '@mui/material'
import { PDFViewer } from '@react-pdf/renderer'
import TranscriptPDF from './TranscriptPDF/TranscriptPDF'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useFetchTranscriptInBatch } from '../../hooks/useFetchTranscriptInBatch'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import {
  TranscriptFlowStages,
  useTranscriptContext,
} from '../Context/TranscriptContext'
import { Page } from '../Elements/PageMargins'
import { useMountEffect } from '../../hooks/useMountEffect'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../Buttons/OutlinedButton'
import { LoadingContext } from '../Context/LoadingContext'
import LoadingProgress from '../Elements/LoadingProgress'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import { getCommaSeparatedList } from '../../utils/getCommaSeparatedList'
import studentKeyToStudentIdForTranscript from '../../utils/studentKeyToStudentIdForTranscript'
import { useAuth } from '../Routes/AuthProvider'

export const csvHeaders =
  'Student First Name,Student Last Name,Student Id,Birth Date,Grad Year,Email Address'

export const PDFTranscriptsPreview: React.FC = () => {
  const { userDetails } = useAuth()

  const location = useLocation()
  const theme = useTheme()
  const { breadcrumbs, updateBreadcrumbs } = useTranscriptContext()
  const { loadingIds } = useContext(LoadingContext)
  const navigate = useNavigate()

  const transcriptKeys = location.state?.transcriptKeys
    ? location.state?.transcriptKeys
    : []

  const { transcripts, loadingId } = useFetchTranscriptInBatch({
    transcriptKeys,
  })

  const pageHeight = 'calc(90vh - 24px - 48px - 96px)'

  useMountEffect(() => {
    updateBreadcrumbs(TranscriptFlowStages.Print)
  })

  const [downloadUrl, setDownloadUrl] = useState<string | undefined>()

  useEffect(() => {
    if (!!transcripts) {
      const csvRows: string[] = [
        // Our initial CSV rows are just the headers
        csvHeaders,
        ...transcripts.map((transcript) => {
          return getCommaSeparatedList([
            `${transcript.student.firstName}`,
            `${transcript.student.lastName}`,
            studentKeyToStudentIdForTranscript(transcript.student.studentKey),
            `${transcript.student.dateOfBirth}`, // Birth Date
            `${transcript.dateGraduation}`, // Grad Year
            `${transcript.studentEmail}`, // Email Address
          ])
        }),
      ]

      const csvRowsWithBreaks = csvRows.join('\n')
      const blob = new Blob([csvRowsWithBreaks], {
        type: 'text/csv',
      })
      const url = URL.createObjectURL(blob)
      setDownloadUrl(url)
    }
  }, [transcripts])

  if (loadingIds.has(loadingId)) {
    return (
      <Page>
        <LoadingProgress />
      </Page>
    )
  }

  return (
    <Page>
      <DynamicBreadcrumbs breadcrumbs={breadcrumbs} />
      {/* Should we use more_vert? */}
      <Box pb={2}>
        <ContainedButton
          variant={ContainedButtonVariant.DownloadParchmentLearnerCsv}
          id="Download Parchment Learner CSV"
          // This just names the download, but we can provide something less generic
          download="download.csv"
          href={downloadUrl}
          disabled={!downloadUrl}
        />
      </Box>
      <Box display="flex" justifyContent="center">
        {!!transcripts && transcripts.length !== 0 && downloadUrl ? (
          <PDFViewer
            style={{
              // Calc the 90% of the view height less the Dynamic Breadcrumbs Padding and the Page margins, and oc the button
              height: 'calc(90vh - 24px - 48px - 96px - 54px)',
              width: '70vw',
            }}
          >
            <TranscriptPDF
              transcripts={transcripts}
              actingAs={userDetails.actingAs}
            />
          </PDFViewer>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height={pageHeight}
            gap={theme.spacing(2)}
          >
            <Typography>No transcript information was found.</Typography>
            <OutlinedButton
              id="goBackPDF"
              variant={OutlinedButtonVariant.GoBack}
              onClick={() => navigate(-1)}
            />
          </Box>
        )}
      </Box>
    </Page>
  )
}
