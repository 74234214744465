/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  Dashboard,
  DashboardFromJSON,
  DashboardStatistics,
  DashboardStatisticsFromJSON,
  DashboardStatisticsToJSON,
  DashboardTextColor,
  DashboardTextColorFromJSON,
  DashboardTextColorToJSON,
  DashboardToJSON,
  LicensingDue,
  LicensingDueFromJSON,
  LicensingDueToJSON,
  ProgramDashboard,
  ProgramDashboardFromJSON,
  ProgramDashboardToJSON,
} from './'

/**
 * Dashboard information for directors
 * @export
 * @interface FetchDashboardResponseDirectorDashboard
 */
export interface FetchDashboardResponseDirectorDashboard {
  /**
   * Outstanding tuition that director has to handle.
   * @type {Array<Dashboard>}
   * @memberof FetchDashboardResponseDirectorDashboard
   */
  outstandingTuition?: Array<Dashboard>
  /**
   * Pending enrollments that director has to handle.
   * @type {Array<Dashboard>}
   * @memberof FetchDashboardResponseDirectorDashboard
   */
  pendingEnrollments?: Array<Dashboard>
  /**
   * Incomplete/Pending Enrollments that director has to handle.
   * @type {Array<Dashboard>}
   * @memberof FetchDashboardResponseDirectorDashboard
   */
  outstandingInvitations?: Array<Dashboard>
  /**
   * Programs that director directs.
   * @type {Array<ProgramDashboard>}
   * @memberof FetchDashboardResponseDirectorDashboard
   */
  myPrograms?: Array<ProgramDashboard>
  /**
   *
   * @type {LicensingDue}
   * @memberof FetchDashboardResponseDirectorDashboard
   */
  licensing?: LicensingDue
  /**
   *
   * @type {DashboardStatistics}
   * @memberof FetchDashboardResponseDirectorDashboard
   */
  statistics?: DashboardStatistics
  /**
   *
   * @type {DashboardTextColor}
   * @memberof FetchDashboardResponseDirectorDashboard
   */
  textColor?: DashboardTextColor
}

export function FetchDashboardResponseDirectorDashboardFromJSON(
  json: any
): FetchDashboardResponseDirectorDashboard {
  return FetchDashboardResponseDirectorDashboardFromJSONTyped(json, false)
}

export function FetchDashboardResponseDirectorDashboardFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FetchDashboardResponseDirectorDashboard {
  if (json === undefined || json === null) {
    return json
  }
  return {
    outstandingTuition: !exists(json, 'outstandingTuition')
      ? undefined
      : (json['outstandingTuition'] as Array<any>).map(DashboardFromJSON),
    pendingEnrollments: !exists(json, 'pendingEnrollments')
      ? undefined
      : (json['pendingEnrollments'] as Array<any>).map(DashboardFromJSON),
    outstandingInvitations: !exists(json, 'outstandingInvitations')
      ? undefined
      : (json['outstandingInvitations'] as Array<any>).map(DashboardFromJSON),
    myPrograms: !exists(json, 'myPrograms')
      ? undefined
      : (json['myPrograms'] as Array<any>).map(ProgramDashboardFromJSON),
    licensing: !exists(json, 'licensing')
      ? undefined
      : LicensingDueFromJSON(json['licensing']),
    statistics: !exists(json, 'statistics')
      ? undefined
      : DashboardStatisticsFromJSON(json['statistics']),
    textColor: !exists(json, 'textColor')
      ? undefined
      : DashboardTextColorFromJSON(json['textColor']),
  }
}

export function FetchDashboardResponseDirectorDashboardToJSON(
  value?: FetchDashboardResponseDirectorDashboard | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    outstandingTuition:
      value.outstandingTuition === undefined
        ? undefined
        : (value.outstandingTuition as Array<any>).map(DashboardToJSON),
    pendingEnrollments:
      value.pendingEnrollments === undefined
        ? undefined
        : (value.pendingEnrollments as Array<any>).map(DashboardToJSON),
    outstandingInvitations:
      value.outstandingInvitations === undefined
        ? undefined
        : (value.outstandingInvitations as Array<any>).map(DashboardToJSON),
    myPrograms:
      value.myPrograms === undefined
        ? undefined
        : (value.myPrograms as Array<any>).map(ProgramDashboardToJSON),
    licensing: LicensingDueToJSON(value.licensing),
    statistics: DashboardStatisticsToJSON(value.statistics),
    textColor: DashboardTextColorToJSON(value.textColor),
  }
}
