/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  Payment,
  PaymentFromJSON,
  PaymentToJSON,
  TuitionPayments1,
  TuitionPayments1FromJSON,
  TuitionPayments1ToJSON,
} from './'

/**
 *
 * @export
 * @interface TuitionPaymentResponse
 */
export interface TuitionPaymentResponse {
  /**
   *
   * @type {Payment}
   * @memberof TuitionPaymentResponse
   */
  payment: Payment
  /**
   * The tuition payments for the family
   * @type {Array<TuitionPayments1>}
   * @memberof TuitionPaymentResponse
   */
  tuitionPayments: Array<TuitionPayments1>
}

export function TuitionPaymentResponseFromJSON(
  json: any
): TuitionPaymentResponse {
  return TuitionPaymentResponseFromJSONTyped(json, false)
}

export function TuitionPaymentResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TuitionPaymentResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    payment: PaymentFromJSON(json['payment']),
    tuitionPayments: (json['tuitionPayments'] as Array<any>).map(
      TuitionPayments1FromJSON
    ),
  }
}

export function TuitionPaymentResponseToJSON(
  value?: TuitionPaymentResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    payment: PaymentToJSON(value.payment),
    tuitionPayments: (value.tuitionPayments as Array<any>).map(
      TuitionPayments1ToJSON
    ),
  }
}
