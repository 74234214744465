/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The role, to be granted to the receiver, that the sender role is able to send an invitation to contract to within the region. This role directly relates to an agreement template to be used for the contracting process.
 * @export
 * @interface RegionContractingHierarchyReceiverRole
 */
export interface RegionContractingHierarchyReceiverRole {
  /**
   * Unique identifier of the role.
   * @type {number}
   * @memberof RegionContractingHierarchyReceiverRole
   */
  roleKey: number
  /**
   * Unique name of the role.
   * @type {string}
   * @memberof RegionContractingHierarchyReceiverRole
   */
  roleName: string
}

export function RegionContractingHierarchyReceiverRoleFromJSON(
  json: any
): RegionContractingHierarchyReceiverRole {
  return RegionContractingHierarchyReceiverRoleFromJSONTyped(json, false)
}

export function RegionContractingHierarchyReceiverRoleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RegionContractingHierarchyReceiverRole {
  if (json === undefined || json === null) {
    return json
  }
  return {
    roleKey: json['roleKey'],
    roleName: json['roleName'],
  }
}

export function RegionContractingHierarchyReceiverRoleToJSON(
  value?: RegionContractingHierarchyReceiverRole | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    roleKey: value.roleKey,
    roleName: value.roleName,
  }
}
