/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  Address,
  AddressFromJSON,
  AddressToJSON,
  GradingScale,
  GradingScaleFromJSON,
  GradingScaleToJSON,
  StudentForTranscript,
  StudentForTranscriptFromJSON,
  StudentForTranscriptToJSON,
  TranscriptParchmentRequestStatus,
  TranscriptParchmentRequestStatusFromJSON,
  TranscriptParchmentRequestStatusToJSON,
  TranscriptYear,
  TranscriptYearFromJSON,
  TranscriptYearToJSON,
} from './'

/**
 *
 * @export
 * @interface Transcript
 */
export interface Transcript {
  /**
   * The unique identifier of the transcript.
   * @type {number}
   * @memberof Transcript
   */
  transcriptKey: number
  /**
   * The name of the transcript. Can be whatever the user wants it to be.
   * @type {string}
   * @memberof Transcript
   */
  transcriptName: string
  /**
   *
   * @type {StudentForTranscript}
   * @memberof Transcript
   */
  student: StudentForTranscript
  /**
   * email of the student
   * @type {string}
   * @memberof Transcript
   */
  studentEmail?: string
  /**
   *
   * @type {Address}
   * @memberof Transcript
   */
  schoolAddress: Address
  /**
   * The name of the school the transcript it from.
   * @type {string}
   * @memberof Transcript
   */
  schoolName?: string
  /**
   * Boolean indicating if the school address is the same as the student's address.
   * @type {boolean}
   * @memberof Transcript
   */
  studentAddressSameAsSchool?: boolean
  /**
   *
   * @type {Address}
   * @memberof Transcript
   */
  studentAddress?: Address
  /**
   *
   * @type {GradingScale}
   * @memberof Transcript
   */
  gradingScale?: GradingScale
  /**
   * Date the student entered high school.
   * @type {Date}
   * @memberof Transcript
   */
  dateHighSchoolEntry?: Date
  /**
   * Date the student graduated.
   * @type {Date}
   * @memberof Transcript
   */
  dateGraduation?: Date
  /**
   * Summary of the transcript. Can be whatever the user wants.
   * @type {string}
   * @memberof Transcript
   */
  summary?: string
  /**
   *
   * @type {TranscriptYear}
   * @memberof Transcript
   */
  transcriptYear1?: TranscriptYear
  /**
   *
   * @type {TranscriptYear}
   * @memberof Transcript
   */
  transcriptYear2?: TranscriptYear
  /**
   *
   * @type {TranscriptYear}
   * @memberof Transcript
   */
  transcriptYear3?: TranscriptYear
  /**
   *
   * @type {TranscriptYear}
   * @memberof Transcript
   */
  transcriptYear4?: TranscriptYear
  /**
   * Date user requested transcript to be sent to parchment
   * @type {Date}
   * @memberof Transcript
   */
  dateRequestedSendToParchment?: Date
  /**
   * Date transcript was sent to parchment
   * @type {Date}
   * @memberof Transcript
   */
  dateSentToParchment?: Date
  /**
   * total credits for the entire transcript, this field is calculated on the frontend
   * @type {number}
   * @memberof Transcript
   */
  totalCredits?: number
  /**
   * GPA of the entire transcript, this field is calculated on the frontend
   * @type {number}
   * @memberof Transcript
   */
  gpa?: number
  /**
   *
   * @type {TranscriptParchmentRequestStatus}
   * @memberof Transcript
   */
  requestStatus?: TranscriptParchmentRequestStatus
}

export function TranscriptFromJSON(json: any): Transcript {
  return TranscriptFromJSONTyped(json, false)
}

export function TranscriptFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Transcript {
  if (json === undefined || json === null) {
    return json
  }
  return {
    transcriptKey: json['transcriptKey'],
    transcriptName: json['transcriptName'],
    student: StudentForTranscriptFromJSON(json['student']),
    studentEmail: !exists(json, 'studentEmail')
      ? undefined
      : json['studentEmail'],
    schoolAddress: AddressFromJSON(json['schoolAddress']),
    schoolName: !exists(json, 'schoolName') ? undefined : json['schoolName'],
    studentAddressSameAsSchool: !exists(json, 'studentAddressSameAsSchool')
      ? undefined
      : json['studentAddressSameAsSchool'],
    studentAddress: !exists(json, 'studentAddress')
      ? undefined
      : AddressFromJSON(json['studentAddress']),
    gradingScale: !exists(json, 'gradingScale')
      ? undefined
      : GradingScaleFromJSON(json['gradingScale']),
    dateHighSchoolEntry: !exists(json, 'dateHighSchoolEntry')
      ? undefined
      : new Date(json['dateHighSchoolEntry']),
    dateGraduation: !exists(json, 'dateGraduation')
      ? undefined
      : new Date(json['dateGraduation']),
    summary: !exists(json, 'summary') ? undefined : json['summary'],
    transcriptYear1: !exists(json, 'transcriptYear1')
      ? undefined
      : TranscriptYearFromJSON(json['transcriptYear1']),
    transcriptYear2: !exists(json, 'transcriptYear2')
      ? undefined
      : TranscriptYearFromJSON(json['transcriptYear2']),
    transcriptYear3: !exists(json, 'transcriptYear3')
      ? undefined
      : TranscriptYearFromJSON(json['transcriptYear3']),
    transcriptYear4: !exists(json, 'transcriptYear4')
      ? undefined
      : TranscriptYearFromJSON(json['transcriptYear4']),
    dateRequestedSendToParchment: !exists(json, 'dateRequestedSendToParchment')
      ? undefined
      : new Date(json['dateRequestedSendToParchment']),
    dateSentToParchment: !exists(json, 'dateSentToParchment')
      ? undefined
      : new Date(json['dateSentToParchment']),
    totalCredits: !exists(json, 'totalCredits')
      ? undefined
      : json['totalCredits'],
    gpa: !exists(json, 'gpa') ? undefined : json['gpa'],
    requestStatus: !exists(json, 'requestStatus')
      ? undefined
      : TranscriptParchmentRequestStatusFromJSON(json['requestStatus']),
  }
}

export function TranscriptToJSON(value?: Transcript | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    transcriptKey: value.transcriptKey,
    transcriptName: value.transcriptName,
    student: StudentForTranscriptToJSON(value.student),
    studentEmail: value.studentEmail,
    schoolAddress: AddressToJSON(value.schoolAddress),
    schoolName: value.schoolName,
    studentAddressSameAsSchool: value.studentAddressSameAsSchool,
    studentAddress: AddressToJSON(value.studentAddress),
    gradingScale: GradingScaleToJSON(value.gradingScale),
    dateHighSchoolEntry:
      value.dateHighSchoolEntry === undefined
        ? undefined
        : value.dateHighSchoolEntry.toISOString().substr(0, 10),
    dateGraduation:
      value.dateGraduation === undefined
        ? undefined
        : value.dateGraduation.toISOString().substr(0, 10),
    summary: value.summary,
    transcriptYear1: TranscriptYearToJSON(value.transcriptYear1),
    transcriptYear2: TranscriptYearToJSON(value.transcriptYear2),
    transcriptYear3: TranscriptYearToJSON(value.transcriptYear3),
    transcriptYear4: TranscriptYearToJSON(value.transcriptYear4),
    dateRequestedSendToParchment:
      value.dateRequestedSendToParchment === undefined
        ? undefined
        : value.dateRequestedSendToParchment.toISOString().substr(0, 10),
    dateSentToParchment:
      value.dateSentToParchment === undefined
        ? undefined
        : value.dateSentToParchment.toISOString().substr(0, 10),
    totalCredits: value.totalCredits,
    gpa: value.gpa,
    requestStatus: TranscriptParchmentRequestStatusToJSON(value.requestStatus),
  }
}
