/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  EditStudentRequestBody,
  EditStudentRequestBodyFromJSON,
  EditStudentRequestBodyToJSON,
} from '../models'
import * as runtime from '../runtime'

export interface EditStudentRequest extends runtime.BaseRequestParameters {
  body?: EditStudentRequestBody
}

/**
 *
 */
export class StudentApi extends runtime.BaseAPI {
  /**
   * Calling this endpoint will trigger the update of a student when a user is the parent of the student    or a user has permission action: Edit, resource: Student, scope: any or  the student belongs to a program where     a user has permission action: Edit, resource: Student, scope: their team\'s
   * Update a student information
   */
  async editStudentRaw(
    requestParameters: EditStudentRequest
  ): Promise<runtime.ApiResponse<EditStudentRequestBody>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/students`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: EditStudentRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EditStudentRequestBodyFromJSON(jsonValue)
    )
  }

  /**
   * Calling this endpoint will trigger the update of a student when a user is the parent of the student    or a user has permission action: Edit, resource: Student, scope: any or  the student belongs to a program where     a user has permission action: Edit, resource: Student, scope: their team\'s
   * Update a student information
   */
  async editStudent(
    requestParameters: EditStudentRequest
  ): Promise<EditStudentRequestBody> {
    const response = await this.editStudentRaw(requestParameters)
    return await response.value()
  }
}
