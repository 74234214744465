import React, { useState } from 'react'
import CardFormHeader from '../Card/CardFormHeader'
import Header, { HeaderVariant } from '../Elements/Header'
import RowActions, { Action } from '../Table/RowActions'
import { Clear, ControlPointDuplicate } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import ConfirmationModal from '../Modals/ConfirmationModal'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import ActionButtons from '../Buttons/ActionButtons'
import { TextButtonVariant } from '../Buttons/TextButton'

const actionCssProps = {
  display: 'flex',
  gap: '10px',
}

interface ClassInformationHeaderProps {
  /*
   * Function ran when PrepopulateFields action is clicked
   */
  prepopulateFields: () => void
  /*
   * Function ran when clearAllFields action is clicked
   */
  clearAllFields: () => void
  /*
   * Whether action buttons will be shown or not
   */
  isFieldDisabled?: boolean
}

const ClassInformationHeader: React.FC<ClassInformationHeaderProps> = ({
  prepopulateFields,
  clearAllFields,
  isFieldDisabled,
}) => {
  const { t } = useTranslation()
  const [
    showClearAllFieldsConfirmationModal,
    setShowClearAllFieldsConfirmationModal,
  ] = useState(false)

  const handleShowClearAllFieldsConfirmationDialog = () => {
    setShowClearAllFieldsConfirmationModal(true)
  }

  const handleConfirmationCancel = () => {
    setShowClearAllFieldsConfirmationModal(false)
  }

  const handleClearAllFields = () => {
    clearAllFields()
    setShowClearAllFieldsConfirmationModal(false)
  }

  const ClassInformationHeaderActions: Action<string>[] = [
    {
      actionName: t(
        'Transcripts.ClassInformationHeader.Actions.PrepopulateFields',
        'Prepopulate Fields'
      ),
      actionFunction: prepopulateFields,
      actionKey: 'prepopulateFields',
      actionIcon: <ControlPointDuplicate />,
      cssProps: actionCssProps,
    },
    {
      actionName: t(
        'Transcripts.ClassInformationHeader.Actions.ClearAllFields',
        'Clear All Fields'
      ),
      actionFunction: handleShowClearAllFieldsConfirmationDialog,
      actionKey: 'handleShowClearAllFieldsConfirmationDialog',
      actionIcon: <Clear />,
      cssProps: actionCssProps,
    },
  ]

  const clearAllTranscriptYearFieldsConfirmationProps = {
    isOpen: showClearAllFieldsConfirmationModal,
    dialogTitle: `${t(
      'Transcripts.TranscriptClassInformation.ConfirmationModal.Header',
      'Clear All Fields'
    )}`,
    dialogContent: (
      <Typography variant="body1" component="p" align="center">
        {t(
          'Transcripts.TranscriptClassInformation.ConfirmationModal.Body',
          'Are you sure you want to clear out all transcript fields?'
        )}
      </Typography>
    ),
    dialogActions: (
      <ActionButtons
        primaryButtonLabel={ContainedButtonVariant.YesImSure}
        secondaryButtonLabel={TextButtonVariant.NoCancel}
        secondaryClick={handleConfirmationCancel}
        useBaseButton
      />
    ),
    handleFormSubmit: handleClearAllFields,
  }

  return (
    <>
      <ConfirmationModal {...clearAllTranscriptYearFieldsConfirmationProps} />
      <CardFormHeader
        header={
          <Header
            id="transcript-class-information"
            headerName={t(
              'Transcripts.TranscriptClassClassInformation.Header',
              'Transcript Class Information'
            )}
            component="h2"
            variant={HeaderVariant.Card}
          />
        }
        buttons={
          !isFieldDisabled && (
            <RowActions
              actions={ClassInformationHeaderActions}
              elementsCount={ClassInformationHeaderActions.length}
            />
          )
        }
        headerContainerProps={{ marginInline: 0 }}
        maintainRow
      />
    </>
  )
}

export default ClassInformationHeader
