import React, { useRef, useEffect } from 'react'
import TitleContext from '../../TitleContext'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useLocation, Outlet } from 'react-router'
import Box from '@mui/material/Box'
import { FileType, FileTypeIcon } from '../Elements/FileTypeResource'
import ContentDetailsCard from '../Card/ContentDetailsCard'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../Buttons/OutlinedButton'
import { contentApi, extractedErrorObject } from '../../api/swagger'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import NoPermission from '../Elements/NoPermission'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { Categories } from '../../utils/searchAndFilterEnums'
import { useTheme } from '@mui/material/styles'
import { useFetchWidenAsset } from '../../hooks/useFetchWidenAsset'

export const BusinessContent: React.FC = () => {
  const { t } = useTranslation()
  const title = t('Business.BusinessContent.Title', 'Business')
  const navigate = useNavigate()
  const { useTitleEffect } = React.useContext(TitleContext)
  useTitleEffect(title)
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()
  const { BusinessContent } = useLoadingIds()
  const theme = useTheme()

  const { assetKey } = useParams<{
    assetKey: string
  }>()

  const location = useLocation()
  const {
    state: routerState,
  }: {
    state: {
      assetKeysInLearningPath: string[]
    }
  } = location

  const nextAssetKey = useRef<string>()
  const prevAssetKey = useRef<string>()

  // If the assetKey was not provided, the call should fail and we'll know it.
  const parsedAssetKey = parseInt(`${assetKey}`)

  // Fetch Business Content
  // FIXME: This hook should be mocked in the tests
  const {
    contentAsset: businessContent,
    fetchAsset,
    refetch,
    isLoading,
    errorFetchingContent: errorFetchingBusinessContent,
  } = useFetchWidenAsset({
    assetKey: parsedAssetKey,
    loadingId: BusinessContent.fetchContentDetail,
  })

  useEffect(() => {
    fetchAsset()
  }, [fetchAsset, parsedAssetKey])

  const averageStarRating = businessContent?.avgStarRating ?? 0

  const handleStarRatingUpdate = async (rating: number) => {
    try {
      await contentApi.starRating({
        body: { widenAssetKey: parsedAssetKey, starRating: rating },
      })
    } catch (error) {
      const errorObject = (await extractedErrorObject(error)) ?? {
        code: 'UnknownError',
        message:
          (error as unknown as Error).message ??
          t('BusinessContent.StarRating.Error', 'Failed to update Star Rating'),
      }
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarMessage(errorObject.message)
      setSnackbarState(true)
    }

    // This is to re-fetch the new value so that the star rating component updates when a user clicks a new rating
    refetch()
  }

  const navigateToBusiness = () => {
    navigate(
      {
        pathname: '/business',
      },
      {
        /** Navigation Options */
      }
    )
  }

  const handleLearningPathNavigation = (value: 'next' | 'prev') => {
    navigate(
      {
        pathname: `/business/business-content/${
          value === 'next' ? nextAssetKey.current : prevAssetKey.current
        }`,
      },
      {
        /** Navigation Options */
        state: {
          assetKeysInLearningPath,
        },
      }
    )
  }

  const { assetKeysInLearningPath } = routerState || {}
  const isViewingInOrder = !!assetKeysInLearningPath

  if (isViewingInOrder) {
    const currentIndex = assetKeysInLearningPath.indexOf(`${assetKey}`)
    nextAssetKey.current = assetKeysInLearningPath[currentIndex + 1]
    prevAssetKey.current = assetKeysInLearningPath[currentIndex - 1]
  }

  const {
    title: contentTitle,
    description,
    businessCategory,
    filetype,
    viewOnly,
    embedUrl,
    isFavorited,
  } = businessContent ?? {}

  return (
    <>
      {!errorFetchingBusinessContent ? (
        <>
          <DynamicBreadcrumbs
            breadcrumbs={[
              {
                label: t('Business.LearningPath.BreadCrumb', 'Business'),
                onClick: navigateToBusiness,
              },
              {
                label: contentTitle,
              },
            ]}
          />
          <ContentDetailsCard
            assetKey={parsedAssetKey}
            filetype={filetype as FileType}
            viewOnly={viewOnly ?? false}
            embedUrl={embedUrl}
            icon={<FileTypeIcon fileType={filetype as FileType} large />}
            iconBackground="dark"
            title={contentTitle ?? ''}
            description={description ?? ''}
            extraTextAfterRating={`Category: ${businessCategory as Categories}`}
            starRating={averageStarRating}
            starRatingOnChange={(
              event: React.SyntheticEvent<Element, Event>,
              rating: number | null
            ) => {
              if (rating && rating >= 1) {
                handleStarRatingUpdate(rating ?? 0)
              }
            }}
            actionArea={
              isViewingInOrder && (
                <Box
                  mr={{ xs: 0, sm: -1 }}
                  flexDirection="column"
                  display="flex"
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      gap: theme.spacing(1.5),
                    },
                  }}
                >
                  {nextAssetKey.current && (
                    <OutlinedButton
                      id="next"
                      variant={OutlinedButtonVariant.Next}
                      onClick={() => handleLearningPathNavigation('next')}
                    />
                  )}
                  {prevAssetKey.current && (
                    <OutlinedButton
                      id="prev"
                      variant={OutlinedButtonVariant.Previous}
                      onClick={() => handleLearningPathNavigation('prev')}
                    />
                  )}
                </Box>
              )
            }
            isFavorite={isFavorited ?? false}
          />
        </>
      ) : (
        <NoPermission
          isLoading={isLoading}
          content={t(
            'Business.BusinessContent.NoPermission',
            `Sorry, you do not have permission to view this content.`
          )}
        />
      )}
      <Outlet />
    </>
  )
}

export default BusinessContent
