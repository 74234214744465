/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface TransferEnrollmentRequestParams
 */
export interface TransferEnrollmentRequestParams {
  /**
   * Unique identifier of the student being transferred, this is used to get the enrollment that will be transferred.
   * @type {number}
   * @memberof TransferEnrollmentRequestParams
   */
  studentKey: number
  /**
   * Unique identifier of the program where the student is coming from, in combination the studentKey and the programKey will used to get the enrollment .
   * @type {number}
   * @memberof TransferEnrollmentRequestParams
   */
  programKey: number
  /**
   * Unique identifier of the program which the student will be transferred into.
   * @type {number}
   * @memberof TransferEnrollmentRequestParams
   */
  targetProgramKey: number
}

export function TransferEnrollmentRequestParamsFromJSON(
  json: any
): TransferEnrollmentRequestParams {
  return TransferEnrollmentRequestParamsFromJSONTyped(json, false)
}

export function TransferEnrollmentRequestParamsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TransferEnrollmentRequestParams {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentKey: json['studentKey'],
    programKey: json['programKey'],
    targetProgramKey: json['targetProgramKey'],
  }
}

export function TransferEnrollmentRequestParamsToJSON(
  value?: TransferEnrollmentRequestParams | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    studentKey: value.studentKey,
    programKey: value.programKey,
    targetProgramKey: value.targetProgramKey,
  }
}
