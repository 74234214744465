import React, { CSSProperties, useState } from 'react'
import {
  IconButton,
  Menu,
  MenuItem,
  Icon,
  ListItemIcon,
  useTheme,
} from '@mui/material'
import Joyride, { CallBackProps, STATUS } from 'react-joyride'
import { JoyrideTooltip } from '../Elements/JoyrideTooltip'

export interface Action<T> {
  /**
   * The name of the action that will be displayed to the user
   */
  actionName: string

  /**
   * The action that will be performed when the user click it.
   */
  actionFunction: (row?: T) => void

  /**
   * unique key provide foreach action name it can be the row key with the actionName
   */
  actionKey: string
  /**
   * It showed the action as disable or enable depending on the value passed
   */
  disabled?: boolean

  /** A method to let us use row data to determine whether an action is hidden */
  hide?: (row?: T) => boolean

  /**
   * If provided, specifies CSS style to be applied to the actions
   */
  cssProps?: CSSProperties

  /**
   * Used to define the ActionName when the ActionName is passed empty
   */
  dynamicActionName?: DynamicActionNames

  /**
   * If provided, add an icon to MenuItem
   */
  menuItemIcon?: JSX.Element
  /**
   * MUI Icon that shows next to the Action
   */
  actionIcon?: React.JSX.Element
}

interface DynamicActionNames {
  /**
   * The field name that is passed in the row that can
   * be used to determine the actionName
   */
  fieldNameUseForFlip: string

  /**
   * actionNames that can show on the screen
   * and the values that should trigger the name display
   */
  switchableActionsNames: {
    /**
     * The actionName to display
     */
    actionName: string
    /**
     * The value that will display this menu item name
     */
    flipValue: boolean | number | string | undefined
  }[]
}

export interface rowActionsTourStepsType {
  target: string
  content: string
  disableBeacon: boolean
  disableScrolling: boolean
  styles?: {
    options: {
      zIndex: number
    }
  }
  title?: string
}

/* Define the Props interface for the ActionList component*/
export interface ActionListProps<T> {
  actions: Action<T>[]
  rowId?: number
  row?: T
  onCompleteTour?: () => void
  elementsCount: number
  isTourCompleted?: boolean
  joyRideTourId?: string
  rowActionsTourSteps?: rowActionsTourStepsType[]
  tourClass?: string
  scrollToFirstStep?: boolean
}

/**
 * This Component creates a more vert Icon button that can be used in a table row.
 * The button displays a list of actions that are clickable and perform an action.
 *
 * The actionName can be passed directly or you can get it 
 *  dynamically depending on the row value.
 * The row is all the information needed to perform the action 
 *  function
 * Params information
 * The required fields for the component are actions
 * The `actions` is an array object Action which contains: 
 * Action requires
 *   -`actionName`:  this is the name that will be displayed 
 *  on the list.
 *   -  `actionFunction`: callback when actionName is selected,
 *   -  `actionKey`: combined with actionName for a unique identifier
 * Action Optional fields
 * - `dynamicActionName`:  this should be used when we 
 * need an actionName to display on the list dynamically depending 
 * on a value in the row. All the fields in the param are required
 * The dynamicActionName requires:
 * -`fieldNameUseForFlip`: this value should match the object index 
 * name in the row, and will be used as a wildcard to know which 
 * ActionName to display.
 *- `switchableActionsNames`: this contains an object with an ActionName
  and flipValue, this is used to know which name will be displayed.
 * @param param actions
 * @param optional row 
 * @returns ReactElement
 */
const RowActions = <T,>({
  actions,
  row,
  rowId,
  onCompleteTour,
  elementsCount,
  isTourCompleted = true,
  joyRideTourId,
  rowActionsTourSteps,
  tourClass,
  scrollToFirstStep,
}: ActionListProps<T>): JSX.Element => {
  const theme = useTheme()
  /**
   * anchorEl state variable is used to manage the anchor element for
   *  the Menu component from Material-UI.
   **/
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  /**
   * Function to handle click on MoreVert icon
   **/
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget)
    } else {
      handleClose()
    }
  }

  /**
   * Function to handle close of the menu
   **/
  const handleClose = () => {
    setAnchorEl(null)
  }

  const getActionName = (
    actionName: string,
    dynamicActionsNames: DynamicActionNames | undefined,
    row: T | undefined
  ) => {
    if (dynamicActionsNames && row) {
      // the row is always an object
      const rowObject = row as { [key: string]: unknown }
      /**
       * Look up in the row for the value we are going to use
       *  to flip the menu item name
       */
      const flipValue = rowObject[dynamicActionsNames.fieldNameUseForFlip]

      /**
       * search in the switchableActionNames array, and use
       * the flipValue to determine which name can be displayed
       */
      let newActionName
      dynamicActionsNames.switchableActionsNames.forEach(
        (actionNameToSwitch) => {
          /**
           * It checks if the value in the row is the expected value to flip the
           * menu item
           */
          if (actionNameToSwitch.flipValue === flipValue) {
            newActionName = actionNameToSwitch.actionName
          }
        }
      )
      return newActionName
    }
    return actionName
  }
  const hasMoreElements = elementsCount > 1
  const visibleActions = actions.filter(({ hide }) => !hide?.(row))
  const [runTour, setRunTour] = useState(true)

  const showTour =
    (!isTourCompleted && hasMoreElements && !!rowId && rowId === 1) ||
    (!isTourCompleted && !hasMoreElements)

  const joyrideCallback = (data: CallBackProps) => {
    const { status } = data
    // If adding more steps, reference the "SpeedDialMenu" implementation
    switch (status) {
      case STATUS.FINISHED:
        localStorage.setItem(joyRideTourId as string, 'true')
        setRunTour(false)
        onCompleteTour && onCompleteTour()
        break
      default:
        break
    }
  }

  const ReturnTour = () => {
    return (
      <Joyride
        steps={rowActionsTourSteps}
        run={runTour && !isTourCompleted}
        callback={joyrideCallback}
        tooltipComponent={JoyrideTooltip}
        scrollToFirstStep={scrollToFirstStep}
      />
    )
  }

  return (
    <>
      {/* MoreVert IconButton */}
      {/* if joyRide is going to be shown it will be only for the first element */}

      {showTour && ReturnTour()}

      <IconButton
        aria-label="action-menu"
        disableRipple
        onClick={handleClick}
        size="large"
        sx={{ width: theme.spacing(3) }}
        disabled={visibleActions.length === 0}
        className={tourClass ?? ''}
      >
        <Icon>more_vert</Icon>
      </IconButton>

      {/* Menu with actions */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        {/* Map through the actions and create MenuItems */}
        {actions.map(
          ({
            actionName,
            actionFunction,
            actionKey,
            disabled,
            hide,
            cssProps,
            dynamicActionName,
            menuItemIcon,
            actionIcon,
          }) => {
            if (hide?.(row)) return null
            /**
             * Disable the row if we determine it is by some row logic
             * OR by the disabled property. It's too complicated to try
             * and use both.
             */
            return (
              <MenuItem
                key={actionKey}
                onClick={() => {
                  actionFunction(row)
                  handleClose()
                }}
                disabled={disabled}
                sx={{
                  ...cssProps,
                }}
              >
                {menuItemIcon && <ListItemIcon>{menuItemIcon}</ListItemIcon>}
                {actionIcon && <>{actionIcon}</>}
                {getActionName(actionName, dynamicActionName, row)}
              </MenuItem>
            )
          }
        )}
      </Menu>
    </>
  )
}

export default RowActions
