import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CardFormHeader from '../Card/CardFormHeader'
import Header, { HeaderProps, HeaderVariant } from '../Elements/Header'
import AddressModal, { AddressModalProps } from '../Address/AddressModal'
import { styled } from '@mui/system'
import { Address } from '../../swagger/models/Address'
import { IconButton } from '@mui/material'
import { Edit } from '@mui/icons-material'
import AddressForm from '../Address/AddressForm'

interface AddressCardProps extends Pick<AddressModalProps, 'onAddressConfirm'> {
  isEditMode: boolean
  address: Address
  headerProps: Pick<HeaderProps, 'headerName' | 'id'>
  buttonLabel?: string
  additionalInformation?: ReactElement
  formHeading?: ReactElement
  /**
   * In the case of using the modal as an edit platform for a given
   * address in a form (like the transcripts form) we can skip the reset.
   */
  skipModalAddressReset?: boolean
  /**
   * A message which, if present, will hide the address fields and modal
   * so we can inform the user of an address related message and not worry
   * about the data.
   *
   * Currently used to help reset the initialAddress for AddressModal
   * when using studentAddressSameAsSchool on transcripts
   *
   */
  addressMessage?: string | ReactElement
}

const AddressCardPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(4, 4, 5.5),
}))

const AddressCard: React.FC<AddressCardProps> = ({
  address,
  isEditMode,
  onAddressConfirm,
  headerProps,
  buttonLabel,
  additionalInformation,
  formHeading,
  skipModalAddressReset,
  addressMessage,
}) => {
  const { t } = useTranslation()

  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false)

  /** Check if all fields (except locationName) are empty  */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { locationName, ...remainingAddressFields } = address
  const indexableAddress = remainingAddressFields as unknown as {
    [key: string]: string
  }
  const noLocationInfo = Object.keys(remainingAddressFields).every(
    (it) => !indexableAddress[it]
  )

  return (
    <>
      {(!addressMessage ||
        (addressMessage && !(typeof addressMessage === 'string'))) && (
        <AddressModal
          disableGeocoding
          isOpen={isAddressModalOpen}
          onClose={() => setIsAddressModalOpen(false)}
          initialAddress={address}
          onAddressConfirm={onAddressConfirm}
          skipModalAddressReset={skipModalAddressReset}
        />
      )}
      <AddressCardPaper>
        {formHeading}
        <section aria-labelledby={headerProps.id}>
          <CardFormHeader
            maintainRow
            header={
              <Header
                id={headerProps.id}
                headerName={headerProps.headerName}
                component="h2"
                variant={HeaderVariant.Card}
              />
            }
            buttons={
              isEditMode && (
                <IconButton
                  aria-label={buttonLabel}
                  onClick={() => {
                    setIsAddressModalOpen(true)
                  }}
                >
                  <Edit />
                </IconButton>
              )
            }
          />
          <Box
            mb={1.5}
            display="flex"
            alignItems="center"
            color="textOrIcon.tableHeader"
          >
            {additionalInformation}
          </Box>
          {noLocationInfo ? (
            !addressMessage ||
            (!!addressMessage && typeof addressMessage === 'string') ? (
              <Box pt={2} color="textOrIcon.tableHeader">
                <Typography variant="subtitle1">
                  {addressMessage ??
                    t(
                      'Settings.Profile.Location.Empty',
                      'No location has been entered'
                    )}
                </Typography>
              </Box>
            ) : (
              addressMessage
            )
          ) : (
            <AddressForm
              fullWidthFields
              addressInformation={address}
              /** It feels weird to say just `isFieldDisabled`, though that works */
              isFieldDisabled={true}
              /** Provide a single option since these fields are disabled and this is display only */
              countryOptions={[
                { name: address.countryCode, countryCode: address.countryCode },
              ]}
            />
          )}
        </section>
      </AddressCardPaper>
    </>
  )
}

export default AddressCard
