/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface AgreementTemplateSelectOption
 */
export interface AgreementTemplateSelectOption {
  /**
   * **DEPRECATED** Unique identifier for the agreementTemplate which corresponds with the Adobe Sign documentId for a Library Document.
   * @type {string}
   * @memberof AgreementTemplateSelectOption
   */
  agreementTemplateId: string
  /**
   * **DEPRECATED** The name of the agreementTemplates.
   * @type {string}
   * @memberof AgreementTemplateSelectOption
   */
  agreementTemplateName: string
}

export function AgreementTemplateSelectOptionFromJSON(
  json: any
): AgreementTemplateSelectOption {
  return AgreementTemplateSelectOptionFromJSONTyped(json, false)
}

export function AgreementTemplateSelectOptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementTemplateSelectOption {
  if (json === undefined || json === null) {
    return json
  }
  return {
    agreementTemplateId: json['agreementTemplateId'],
    agreementTemplateName: json['agreementTemplateName'],
  }
}

export function AgreementTemplateSelectOptionToJSON(
  value?: AgreementTemplateSelectOption | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    agreementTemplateId: value.agreementTemplateId,
    agreementTemplateName: value.agreementTemplateName,
  }
}
