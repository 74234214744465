/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface RegionCreate
 */
export interface RegionCreate {
  /**
   * The name assigned to the region. This is required for region creation.
   * @type {string}
   * @memberof RegionCreate
   */
  name: string
  /**
   * The currency code used for the region. ISO-4217 standard codes.
   * @type {string}
   * @memberof RegionCreate
   */
  currency?: string
  /**
   * A number indicating which set of curriculum a region is using.
   * @type {number}
   * @memberof RegionCreate
   */
  cycle?: number
  /**
   * The number indicating the month semester one begins. Indexed from 0 to 11. This is required for creation.
   * @type {number}
   * @memberof RegionCreate
   */
  semesterOneStartMonth: number
  /**
   * The number indicating the day semester one begins. This is required for creation.
   * @type {number}
   * @memberof RegionCreate
   */
  semesterOneStartDay: number
  /**
   * The number indicating the month semester two begins. Indexed from 0 to 11. This is required for creation.
   * @type {number}
   * @memberof RegionCreate
   */
  semesterTwoStartMonth: number
  /**
   * The number indicating the day semester two begins. This is required for creation.
   * @type {number}
   * @memberof RegionCreate
   */
  semesterTwoStartDay: number
  /**
   * The unique identifier of the region that is a parent of this region. This is required for creation.
   * @type {number}
   * @memberof RegionCreate
   */
  parentRegionsKey?: number
  /**
   * Boolean indicating if the programs and communities under this region should show up in public searches, such as 'Community Search'.
   * @type {boolean}
   * @memberof RegionCreate
   */
  isPrivate: boolean
  /**
   * The actor assigned as the region manager. This is required for creation.
   * @type {number}
   * @memberof RegionCreate
   */
  actorKey?: number
  /**
   * Boolean indicating if the external automated contracting process within the 'Add a Team Member' process should be bypassed.
   * @type {boolean}
   * @memberof RegionCreate
   */
  bypassAutomatedAgreements: boolean
  /**
   * Link to discourse forums url
   * @type {string}
   * @memberof RegionCreate
   */
  discourseUrl: string
  /**
   * A year when cycle 1 starts
   * @type {number}
   * @memberof RegionCreate
   */
  aYCycleStartYear: number
  /**
   * Boolean indicating whether or not cash payments are allowed for the region
   * @type {boolean}
   * @memberof RegionCreate
   */
  achAllowed?: boolean
  /**
   * Days before semester one start date when the color of licensing due changes to NORMAL
   * @type {number}
   * @memberof RegionCreate
   */
  s1LicensingDueOffset: number
  /**
   * Days after semester one start date when the color of licensing due changes to RED
   * @type {number}
   * @memberof RegionCreate
   */
  s1LicensingLateOffset: number
  /**
   * Days before semester two start date when the color of licensing due changes to NORMAL
   * @type {number}
   * @memberof RegionCreate
   */
  s2LicensingDueOffset: number
  /**
   * Days after semester two start date when the color of licensing due changes to RED
   * @type {number}
   * @memberof RegionCreate
   */
  s2LicensingLateOffset: number
}

export function RegionCreateFromJSON(json: any): RegionCreate {
  return RegionCreateFromJSONTyped(json, false)
}

export function RegionCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RegionCreate {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    currency: !exists(json, 'currency') ? undefined : json['currency'],
    cycle: !exists(json, 'cycle') ? undefined : json['cycle'],
    semesterOneStartMonth: json['semesterOneStartMonth'],
    semesterOneStartDay: json['semesterOneStartDay'],
    semesterTwoStartMonth: json['semesterTwoStartMonth'],
    semesterTwoStartDay: json['semesterTwoStartDay'],
    parentRegionsKey: !exists(json, 'parentRegionsKey')
      ? undefined
      : json['parentRegionsKey'],
    isPrivate: json['isPrivate'],
    actorKey: !exists(json, 'actorKey') ? undefined : json['actorKey'],
    bypassAutomatedAgreements: json['bypassAutomatedAgreements'],
    discourseUrl: json['discourseUrl'],
    aYCycleStartYear: json['AYCycleStartYear'],
    achAllowed: !exists(json, 'achAllowed') ? undefined : json['achAllowed'],
    s1LicensingDueOffset: json['S1LicensingDueOffset'],
    s1LicensingLateOffset: json['S1LicensingLateOffset'],
    s2LicensingDueOffset: json['S2LicensingDueOffset'],
    s2LicensingLateOffset: json['S2LicensingLateOffset'],
  }
}

export function RegionCreateToJSON(value?: RegionCreate | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    currency: value.currency,
    cycle: value.cycle,
    semesterOneStartMonth: value.semesterOneStartMonth,
    semesterOneStartDay: value.semesterOneStartDay,
    semesterTwoStartMonth: value.semesterTwoStartMonth,
    semesterTwoStartDay: value.semesterTwoStartDay,
    parentRegionsKey: value.parentRegionsKey,
    isPrivate: value.isPrivate,
    actorKey: value.actorKey,
    bypassAutomatedAgreements: value.bypassAutomatedAgreements,
    discourseUrl: value.discourseUrl,
    AYCycleStartYear: value.aYCycleStartYear,
    achAllowed: value.achAllowed,
    S1LicensingDueOffset: value.s1LicensingDueOffset,
    S1LicensingLateOffset: value.s1LicensingLateOffset,
    S2LicensingDueOffset: value.s2LicensingDueOffset,
    S2LicensingLateOffset: value.s2LicensingLateOffset,
  }
}
