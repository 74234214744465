/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  Permission,
  PermissionFromJSON,
  Role,
  RoleFromJSON,
  ServerStatus,
  ServerStatusFromJSON,
} from '../models'
import * as runtime from '../runtime'

/**
 *
 */
export class MetaApi extends runtime.BaseAPI {
  /**
   * Permissions are actions or capabilities, represented by their `actionCode`, and are grouped by the type of thing they apply to, represented by their `resourceCode`.  A permission has a scope: it can either be granted to a role globally (scope `any`), without restriction, or it can be restricted to the reporting hierarchy (scope `their team\'s`) and resources owned by those persons. Scope is only relevant where the actor hierarchy or region-community-program-enrollment relationships come into play. It bears repeating that it is the _actors_ that form a hierarchy, not the users.  Permissions are granted to roles, which are then applied to users for a time period by way of an actor. Or, following the relationships the other way, a user is allowed to act as a role with some set of permissions from some date to another date under supervision of another actor. (This actor concept is most visible in the Teams endpoints.)  Some structured permissions expected to be configured elsewhere are not listed by this endpoint, notably `TeamInvite` permissions configured by editing the relevant Region, to which the permission is scoped.
   * Lists the permissions that can be granted, or not, to a role.
   */
  async fetchPermissionsRaw(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<runtime.ApiResponse<Array<Permission>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/permissions`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(PermissionFromJSON)
    )
  }

  /**
   * Permissions are actions or capabilities, represented by their `actionCode`, and are grouped by the type of thing they apply to, represented by their `resourceCode`.  A permission has a scope: it can either be granted to a role globally (scope `any`), without restriction, or it can be restricted to the reporting hierarchy (scope `their team\'s`) and resources owned by those persons. Scope is only relevant where the actor hierarchy or region-community-program-enrollment relationships come into play. It bears repeating that it is the _actors_ that form a hierarchy, not the users.  Permissions are granted to roles, which are then applied to users for a time period by way of an actor. Or, following the relationships the other way, a user is allowed to act as a role with some set of permissions from some date to another date under supervision of another actor. (This actor concept is most visible in the Teams endpoints.)  Some structured permissions expected to be configured elsewhere are not listed by this endpoint, notably `TeamInvite` permissions configured by editing the relevant Region, to which the permission is scoped.
   * Lists the permissions that can be granted, or not, to a role.
   */
  async fetchPermissions(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<Array<Permission>> {
    const response = await this.fetchPermissionsRaw(requestParameters)
    return await response.value()
  }

  /**
   * Permissions are grouped by the type of thing they apply to. Permissions are granted to roles, which are then applied to users. A permission can either be granted to a role globally, without restriction, or it can be restricted to the reporting hierarchy.
   * Lists the roles that a user can play.
   */
  async fetchRolesRaw(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<runtime.ApiResponse<Array<Role>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/roles`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(RoleFromJSON)
    )
  }

  /**
   * Permissions are grouped by the type of thing they apply to. Permissions are granted to roles, which are then applied to users. A permission can either be granted to a role globally, without restriction, or it can be restricted to the reporting hierarchy.
   * Lists the roles that a user can play.
   */
  async fetchRoles(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<Array<Role>> {
    const response = await this.fetchRolesRaw(requestParameters)
    return await response.value()
  }

  /**
   * This endpoint is pinged automatically by monitoring systems to see whether the service is up and able to serve responses. It also provides useful info about what git rev is currently deployed to us humans.  If you see any response status other than 200, that is not good.  This endpoint is also available at `/` for everyone\'s convenience.
   * Answers with the current version, unless things are busted.
   */
  async healthcheckRaw(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<runtime.ApiResponse<ServerStatus>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/healthcheck`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ServerStatusFromJSON(jsonValue)
    )
  }

  /**
   * This endpoint is pinged automatically by monitoring systems to see whether the service is up and able to serve responses. It also provides useful info about what git rev is currently deployed to us humans.  If you see any response status other than 200, that is not good.  This endpoint is also available at `/` for everyone\'s convenience.
   * Answers with the current version, unless things are busted.
   */
  async healthcheck(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<ServerStatus> {
    const response = await this.healthcheckRaw(requestParameters)
    return await response.value()
  }
}
