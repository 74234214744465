/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  DefaultFeeStructures,
  DefaultFeeStructuresFromJSON,
  DefaultFeeStructuresToJSON,
  ProgramType,
  ProgramTypeFromJSON,
  ProgramTypeToJSON,
} from './'

/**
 *
 * @export
 * @interface RegionFeeSetting
 */
export interface RegionFeeSetting {
  /**
   *
   * @type {ProgramType}
   * @memberof RegionFeeSetting
   */
  programType: ProgramType
  /**
   *
   * @type {Array<DefaultFeeStructures>}
   * @memberof RegionFeeSetting
   */
  defaultFeeStructures: Array<DefaultFeeStructures>
}

export function RegionFeeSettingFromJSON(json: any): RegionFeeSetting {
  return RegionFeeSettingFromJSONTyped(json, false)
}

export function RegionFeeSettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RegionFeeSetting {
  if (json === undefined || json === null) {
    return json
  }
  return {
    programType: ProgramTypeFromJSON(json['programType']),
    defaultFeeStructures: (json['defaultFeeStructures'] as Array<any>).map(
      DefaultFeeStructuresFromJSON
    ),
  }
}

export function RegionFeeSettingToJSON(value?: RegionFeeSetting | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    programType: ProgramTypeToJSON(value.programType),
    defaultFeeStructures: (value.defaultFeeStructures as Array<any>).map(
      DefaultFeeStructuresToJSON
    ),
  }
}
