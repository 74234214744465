/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface CommunityListingSupportRepresentative
 */
export interface CommunityListingSupportRepresentative {
  /**
   * The first name provided by the user.
   * @type {string}
   * @memberof CommunityListingSupportRepresentative
   */
  firstName?: string
  /**
   * The last name provided by the user.
   * @type {string}
   * @memberof CommunityListingSupportRepresentative
   */
  lastName?: string
}

export function CommunityListingSupportRepresentativeFromJSON(
  json: any
): CommunityListingSupportRepresentative {
  return CommunityListingSupportRepresentativeFromJSONTyped(json, false)
}

export function CommunityListingSupportRepresentativeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommunityListingSupportRepresentative {
  if (json === undefined || json === null) {
    return json
  }
  return {
    firstName: !exists(json, 'firstName') ? undefined : json['firstName'],
    lastName: !exists(json, 'lastName') ? undefined : json['lastName'],
  }
}

export function CommunityListingSupportRepresentativeToJSON(
  value?: CommunityListingSupportRepresentative | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    firstName: value.firstName,
    lastName: value.lastName,
  }
}
