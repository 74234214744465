/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface CreateStudents
 */
export interface CreateStudents {
  /**
   * The first name of the student.
   * @type {string}
   * @memberof CreateStudents
   */
  firstName: string
  /**
   * The date of birth of the student.
   * @type {Date}
   * @memberof CreateStudents
   */
  dateOfBirth?: Date
  /**
   * The number indicating student's month of birth. Indexed from 0 to 11.
   * @type {number}
   * @memberof CreateStudents
   */
  birthMonth: number
  /**
   * The number indicating student's year of birth.
   * @type {number}
   * @memberof CreateStudents
   */
  birthYear: number
  /**
   * The userKey of the student's parent
   * @type {number}
   * @memberof CreateStudents
   */
  parentUserKey: number
}

export function CreateStudentsFromJSON(json: any): CreateStudents {
  return CreateStudentsFromJSONTyped(json, false)
}

export function CreateStudentsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateStudents {
  if (json === undefined || json === null) {
    return json
  }
  return {
    firstName: json['firstName'],
    dateOfBirth: !exists(json, 'dateOfBirth')
      ? undefined
      : new Date(json['dateOfBirth']),
    birthMonth: json['birthMonth'],
    birthYear: json['birthYear'],
    parentUserKey: json['parentUserKey'],
  }
}

export function CreateStudentsToJSON(value?: CreateStudents | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    firstName: value.firstName,
    dateOfBirth:
      value.dateOfBirth === undefined
        ? undefined
        : value.dateOfBirth.toISOString().substr(0, 10),
    birthMonth: value.birthMonth,
    birthYear: value.birthYear,
    parentUserKey: value.parentUserKey,
  }
}
