/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The possible validity types. Invitation is only valid if type "Valid" is returned. "Completed" means the family has finished enrolling their students with this invitation, and a message should be shown to the user stating this."DoesNotExist" means that the passed in uuid does not belong to any existing enrollmentInvites or agreement. "Cancelled" means that the agreement was cancelled by the sender and can no longer be accepted. "Expired" means the expire date is in the past, and the invite can no longer be accepted. "NotValid" is a catchall other errors that may not pertain to the other validity types.
 * @export
 * @enum {string}
 */
export enum InviteValidity {
  Valid = 'Valid',
  DoesNotExist = 'DoesNotExist',
  Cancelled = 'Cancelled',
  Expired = 'Expired',
  NotValid = 'NotValid',
  Completed = 'Completed',
  Bypassed = 'Bypassed',
}

export function InviteValidityFromJSON(json: any): InviteValidity {
  return InviteValidityFromJSONTyped(json, false)
}

export function InviteValidityFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InviteValidity {
  return json as InviteValidity
}

export function InviteValidityToJSON(value?: InviteValidity | null): any {
  return value as any
}
