/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 3d22d589 (Thu Sep 19 20:41:25 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  CashAmount,
  CashAmountFromJSON,
  CashAmountToJSON,
  EnrollmentDiscountCode,
  EnrollmentDiscountCodeFromJSON,
  EnrollmentDiscountCodeToJSON,
  ProgramType,
  ProgramTypeFromJSON,
  ProgramTypeToJSON,
} from './'

/**
 *
 * @export
 * @interface EnrollmentDiscount
 */
export interface EnrollmentDiscount {
  /**
   *
   * @type {EnrollmentDiscountCode}
   * @memberof EnrollmentDiscount
   */
  code: EnrollmentDiscountCode
  /**
   *
   * @type {ProgramType}
   * @memberof EnrollmentDiscount
   */
  programType?: ProgramType
  /**
   *
   * @type {CashAmount}
   * @memberof EnrollmentDiscount
   */
  total: CashAmount
  /**
   *
   * @type {string}
   * @memberof EnrollmentDiscount
   */
  status: EnrollmentDiscountStatusEnum
}

/**
 * @export
 * @enum {string}
 */
export enum EnrollmentDiscountStatusEnum {
  Applied = 'APPLIED',
  Paid = 'PAID',
}

export function EnrollmentDiscountFromJSON(json: any): EnrollmentDiscount {
  return EnrollmentDiscountFromJSONTyped(json, false)
}

export function EnrollmentDiscountFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnrollmentDiscount {
  if (json === undefined || json === null) {
    return json
  }
  return {
    code: EnrollmentDiscountCodeFromJSON(json['code']),
    programType: !exists(json, 'programType')
      ? undefined
      : ProgramTypeFromJSON(json['programType']),
    total: CashAmountFromJSON(json['total']),
    status: json['status'],
  }
}

export function EnrollmentDiscountToJSON(
  value?: EnrollmentDiscount | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    code: EnrollmentDiscountCodeToJSON(value.code),
    programType: ProgramTypeToJSON(value.programType),
    total: CashAmountToJSON(value.total),
    status: value.status,
  }
}
