import { OperationIds } from '../swagger/operationIdEnum'

/** ATTENTION: Keep it alphabetic, please.
 *
 * The convention for naming is PascalCase name of the file with index signatures of
 * the OperationId of an endpoint being called to a string, which should have the value
 * of the operationId.
 */
export const useLoadingIds = (): {
  AccountContext: {
    [OperationIds.FetchStudentsForFamily]: string
    [OperationIds.FetchCountries]: string
    [OperationIds.FetchEnrollmentInvites]: string
  }
  AssignChildrenCard: {
    [OperationIds.ValidatePotentialEnrollments]: string
  }
  AuthFormCard: {
    [OperationIds.SendTokenForSignup]: string
  }
  AuthProvider: {
    [OperationIds.Logout]: string
  }
  BillingTab: { [OperationIds.FetchBilling]: string }
  Business: {
    [OperationIds.FetchBusinessContent]: string
    [OperationIds.FetchBusinessContentOptions]: string
  }
  BusinessContent: {
    [OperationIds.FetchContentDetail]: string
  }
  ContentApi: {
    [OperationIds.FetchContentTagOptions]: string
  }
  CommunityDetailsTab: {
    [OperationIds.FetchCommunity]: string
  }
  CommunityFamiliesTab: {
    [OperationIds.FetchAllFamilies]: string
  }
  CommunityProgramsTab: {
    [OperationIds.FetchAllPrograms]: string
  }
  DashboardTab: {
    [OperationIds.FetchDashboard]: string
  }
  Enrollments: {
    [OperationIds.UpdateLicensingEnrollments]: string
    [OperationIds.CreateLicensingEnrollment]: string
    [OperationIds.RemoveLicensingEnrollments]: string
  }
  EnrollmentSummaryCard: {
    [OperationIds.CompleteEnrollmentInvite]: string
  }
  EnrollmentsTable: {
    [OperationIds.UpdateStudentTutorAssignments]: string
    [OperationIds.UpdateEnrollmentStatus]: string
  }
  Events: {
    [OperationIds.FetchUsers]: string
    [OperationIds.FetchTimezones]: string
    [OperationIds.FetchEventRegistrants]: string
    [OperationIds.FetchEvents]: string
  }
  Families: {
    [OperationIds.FetchStudentsForFamily]: string
    [OperationIds.FetchFamilyTuitionBilling]: string
  }
  InviteContext: {
    [OperationIds.CompleteAgreement]: string
    [OperationIds.FetchInviteValidity]: string
    [OperationIds.Logout]: string
  }
  InviteForm: {
    [OperationIds.ConfirmTokenForSignup]: string
    [OperationIds.Logout]: string
    [OperationIds.SendTokenForSignup]: string
  }
  InviteFamilyFormCard: {
    [OperationIds.FetchUserProfile]: string
    [OperationIds.InviteFamily]: string
  }
  EnrollmentsInviteTable: {
    [OperationIds.FetchEnrollmentInvites]: string
    [OperationIds.ResendInvitation]: string
    [OperationIds.CancelInvitation]: string
  }
  InvoiceCard: {
    [OperationIds.SendTuitionInvoice]: string
  }
  InvoiceDetails: {
    [OperationIds.FetchTuitionInvoice]: string
  }
  LearningCenter: {
    [OperationIds.FetchContentDetail]: string
  }
  Login: {
    [OperationIds.Login]: string
  }
  Meta: {
    [OperationIds.FetchRoles]: string
    [OperationIds.FetchPermissions]: string
  }
  PaymentModal: {
    [OperationIds.FetchStates]: string
  }
  ProfileTab: {
    [OperationIds.FetchStudentsForFamily]: string
    // TODO: Remove use in profile tab in lieu of UserContext
    [OperationIds.FetchUserCommunities]: string
    [OperationIds.EditUserProfile]: string
    [OperationIds.EditStudent]: OperationIds.EditStudent
  }
  ProgramDetails: {
    [OperationIds.FetchEnrollments]: string
    [OperationIds.FetchProgram]: string
  }
  Regions: {
    [OperationIds.FetchRegions]: string
    [OperationIds.FetchRegion]: string
    [OperationIds.FetchRegionContractingHierarchies]: string
    [OperationIds.FetchRegionFees]: string
    [OperationIds.FetchRegionOptions]: string
  }
  RegistrantsTable: {
    [OperationIds.UpdateRegistrant]: string
  }
  TeamMemberCard: {
    [OperationIds.InviteTeamMember]: string
  }
  TranscriptFormCard: {
    [OperationIds.CreateTranscript]: string
    [OperationIds.UpdateTranscript]: string
    [OperationIds.FetchGradingScales]: string
    [OperationIds.SendTranscriptToParchment]: string
  }
  UpdateChildrenInfoCard: {
    [OperationIds.EditStudent]: string
  }
  UpdateParentInfoCard: {
    [OperationIds.EditUserProfile]: string
  }
  UseFetchTranscripts: {
    [OperationIds.FetchTranscripts]: string
    [OperationIds.FetchGradingScales]: string
  }
  UseDeleteTranscript: {
    [OperationIds.DeleteTranscript]: string
  }
  UserAccounts: {
    [OperationIds.FetchUsers]: string
    [OperationIds.FetchRoles]: string
    [OperationIds.FetchUser]: string
    [OperationIds.FetchBilling]: string
    [OperationIds.FetchPaymentOptions]: string
    [OperationIds.FetchUserProfile]: string
  }
  UserContext: {
    [OperationIds.FetchUserCommunities]: string
  }
} => {
  return {
    AccountContext: {
      [OperationIds.FetchStudentsForFamily]:
        OperationIds.FetchStudentsForFamily,
      [OperationIds.FetchCountries]: OperationIds.FetchCountries,
      [OperationIds.FetchEnrollmentInvites]:
        OperationIds.FetchEnrollmentInvites,
    },
    AssignChildrenCard: {
      [OperationIds.ValidatePotentialEnrollments]:
        OperationIds.ValidatePotentialEnrollments,
    },
    AuthFormCard: {
      [OperationIds.SendTokenForSignup]: OperationIds.SendTokenForSignup,
    },
    AuthProvider: {
      [OperationIds.Logout]: OperationIds.Logout,
    },
    BillingTab: {
      [OperationIds.FetchBilling]: OperationIds.FetchBilling,
    },
    Business: {
      [OperationIds.FetchBusinessContent]: OperationIds.FetchBusinessContent,
      [OperationIds.FetchBusinessContentOptions]:
        OperationIds.FetchBusinessContentOptions,
    },
    BusinessContent: {
      [OperationIds.FetchContentDetail]: OperationIds.FetchContentDetail,
    },
    ContentApi: {
      [OperationIds.FetchContentTagOptions]:
        OperationIds.FetchContentTagOptions,
    },
    CommunityDetailsTab: {
      [OperationIds.FetchCommunity]: OperationIds.FetchCommunity,
    },
    CommunityFamiliesTab: {
      [OperationIds.FetchAllFamilies]: OperationIds.FetchAllFamilies,
    },
    CommunityProgramsTab: {
      [OperationIds.FetchAllPrograms]: OperationIds.FetchAllPrograms,
    },
    DashboardTab: {
      [OperationIds.FetchDashboard]: OperationIds.FetchDashboard,
    },
    Enrollments: {
      [OperationIds.UpdateLicensingEnrollments]:
        OperationIds.UpdateLicensingEnrollments,
      [OperationIds.CreateLicensingEnrollment]:
        OperationIds.CreateLicensingEnrollment,
      [OperationIds.RemoveLicensingEnrollments]:
        OperationIds.RemoveLicensingEnrollments,
    },
    EnrollmentSummaryCard: {
      [OperationIds.CompleteEnrollmentInvite]:
        OperationIds.CompleteEnrollmentInvite,
    },
    EnrollmentsTable: {
      [OperationIds.UpdateStudentTutorAssignments]:
        OperationIds.UpdateStudentTutorAssignments,
      [OperationIds.UpdateEnrollmentStatus]:
        OperationIds.UpdateEnrollmentStatus,
    },
    Events: {
      [OperationIds.FetchUsers]: OperationIds.FetchUsers,
      [OperationIds.FetchTimezones]: OperationIds.FetchTimezones,
      [OperationIds.FetchEventRegistrants]: OperationIds.FetchEventRegistrants,
      [OperationIds.FetchEvents]: OperationIds.FetchEvents,
    },
    Families: {
      [OperationIds.FetchStudentsForFamily]:
        OperationIds.FetchStudentsForFamily,
      [OperationIds.FetchFamilyTuitionBilling]:
        OperationIds.FetchFamilyTuitionBilling,
    },
    InviteContext: {
      [OperationIds.CompleteAgreement]: OperationIds.CompleteAgreement,
      [OperationIds.FetchInviteValidity]: OperationIds.FetchInviteValidity,
      [OperationIds.Logout]: OperationIds.Logout,
    },
    InviteForm: {
      [OperationIds.SendTokenForSignup]: OperationIds.SendTokenForSignup,
      [OperationIds.ConfirmTokenForSignup]: OperationIds.ConfirmTokenForSignup,
      [OperationIds.Logout]: OperationIds.Logout,
    },
    InviteFamilyFormCard: {
      [OperationIds.FetchUserProfile]: OperationIds.FetchUserProfile,
      [OperationIds.InviteFamily]: OperationIds.InviteFamily,
    },
    EnrollmentsInviteTable: {
      [OperationIds.FetchEnrollmentInvites]:
        OperationIds.FetchEnrollmentInvites,
      [OperationIds.ResendInvitation]: OperationIds.ResendInvitation,
      [OperationIds.CancelInvitation]: OperationIds.CancelInvitation,
    },
    InvoiceCard: {
      [OperationIds.SendTuitionInvoice]: OperationIds.SendTuitionInvoice,
    },
    InvoiceDetails: {
      [OperationIds.FetchTuitionInvoice]: OperationIds.FetchTuitionInvoice,
    },
    LearningCenter: {
      [OperationIds.FetchContentDetail]: OperationIds.FetchContentDetail,
    },
    Login: {
      [OperationIds.Login]: OperationIds.Login,
    },
    Meta: {
      [OperationIds.FetchRoles]: OperationIds.FetchRoles,
      [OperationIds.FetchPermissions]: OperationIds.FetchPermissions,
    },
    PaymentModal: {
      [OperationIds.FetchStates]: OperationIds.FetchStates,
    },
    ProfileTab: {
      [OperationIds.FetchStudentsForFamily]:
        OperationIds.FetchStudentsForFamily,
      [OperationIds.FetchUserCommunities]: OperationIds.FetchUserCommunities,
      [OperationIds.EditUserProfile]: OperationIds.EditUserProfile,
      [OperationIds.EditStudent]: OperationIds.EditStudent,
    },
    ProgramDetails: {
      [OperationIds.FetchEnrollments]: OperationIds.FetchEnrollments,
      [OperationIds.FetchProgram]: OperationIds.FetchProgram,
    },
    Regions: {
      [OperationIds.FetchRegions]: OperationIds.FetchRegions,
      [OperationIds.FetchRegion]: OperationIds.FetchRegion,
      [OperationIds.FetchRegionContractingHierarchies]:
        OperationIds.FetchRegionContractingHierarchies,
      [OperationIds.FetchRegionFees]: OperationIds.FetchRegionFees,
      [OperationIds.FetchRegionOptions]: OperationIds.FetchRegionOptions,
    },
    RegistrantsTable: {
      [OperationIds.UpdateRegistrant]: OperationIds.UpdateRegistrant,
    },
    TeamMemberCard: {
      [OperationIds.InviteTeamMember]: OperationIds.InviteTeamMember,
    },
    TranscriptFormCard: {
      [OperationIds.CreateTranscript]: OperationIds.CreateTranscript,
      [OperationIds.UpdateTranscript]: OperationIds.UpdateTranscript,
      [OperationIds.FetchGradingScales]: OperationIds.FetchGradingScales,
      [OperationIds.SendTranscriptToParchment]:
        OperationIds.SendTranscriptToParchment,
    },
    UpdateChildrenInfoCard: {
      [OperationIds.EditStudent]: OperationIds.EditStudent,
    },
    UpdateParentInfoCard: {
      [OperationIds.EditUserProfile]: OperationIds.EditUserProfile,
    },
    UseFetchTranscripts: {
      [OperationIds.FetchTranscripts]: OperationIds.FetchTranscripts,
      [OperationIds.FetchGradingScales]: OperationIds.FetchGradingScales,
    },
    UseDeleteTranscript: {
      [OperationIds.DeleteTranscript]: OperationIds.DeleteTranscript,
    },
    UserAccounts: {
      [OperationIds.FetchUsers]: OperationIds.FetchUsers,
      [OperationIds.FetchRoles]: OperationIds.FetchRoles,
      [OperationIds.FetchUser]: OperationIds.FetchUser,
      [OperationIds.FetchBilling]: OperationIds.FetchBilling,
      [OperationIds.FetchPaymentOptions]: OperationIds.FetchPaymentOptions,
      [OperationIds.FetchUserProfile]: OperationIds.FetchUserProfile,
    },
    UserContext: {
      [OperationIds.FetchUserCommunities]: OperationIds.FetchUserCommunities,
    },
  }
}
