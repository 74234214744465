import React from 'react'
import { Box, TextField } from '@mui/material'
import { FormDivider } from '../Elements/FormDivider'
import Header, { HeaderVariant } from '../Elements/Header'
import CardFormHeader from '../Card/CardFormHeader'
import { useTranslation } from 'react-i18next'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { useTranscriptContext } from '../Context/TranscriptContext'

export interface SchoolInformationHeaderProps {
  isFieldDisabled?: boolean
}

export const SchoolInformationHeader: React.FC<
  SchoolInformationHeaderProps
> = ({ isFieldDisabled }) => {
  const { t } = useTranslation()
  const showOnDesktop = useShowOnDesktop()
  const {
    updateTranscriptDetails,
    transcriptDetails,
    transcriptValidity,
    updateFormValidity,
  } = useTranscriptContext()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateFormValidity({
      ...transcriptValidity,
      [event.target.name]: { input: true },
    })
    updateTranscriptDetails({
      ...transcriptDetails,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <section aria-labelledby="school-information-header">
      <CardFormHeader
        header={
          <Header
            id="school-information-header"
            component={'h2'}
            variant={HeaderVariant.Card}
            headerName={t(
              'Transcripts.SchoolInformationHeader.Header.SchoolInformation',
              'School Information'
            )}
          />
        }
      />
      <Box width={showOnDesktop ? '48%' : 'auto'}>
        <TextField
          InputLabelProps={{ shrink: true }}
          value={transcriptDetails.schoolName}
          helperText={
            !transcriptValidity.schoolName.input
              ? t(
                  'Transcripts.SchoolInformationHeader.Error.SchoolName',
                  'School Name is Required.'
                )
              : undefined
          }
          error={!transcriptValidity.schoolName.input}
          id="school name"
          name="schoolName"
          label={t(
            'Transcripts.SchoolInformationHeader.Field.SchoolName',
            'School Name'
          )}
          variant="filled"
          disabled={isFieldDisabled}
          sx={{
            mx: showOnDesktop ? 2 : 0,
          }}
          fullWidth
          onChange={handleChange}
          required
        />
      </Box>
      <FormDivider />
    </section>
  )
}

export default SchoolInformationHeader
