import React from 'react'
import { transcriptPdfStyles, boldFont } from './TranscriptPDFStyles'
import { TranscriptYear } from '../../../swagger'

interface ClassInformationPDFProps {
  transcriptYear?: TranscriptYear
  yearGPA: string
  yearCredits: string
  grade: number
}
const ClassInformationPDF: React.FC<ClassInformationPDFProps> = ({
  transcriptYear,
  yearGPA,
  yearCredits,
  grade,
}) => {
  const hasNotes = !!transcriptYear?.notes

  return (
    <div
      style={{
        ...transcriptPdfStyles.box,
        fontSize: '9px',
        paddingTop: '1px',
        marginTop: 0,
        marginBottom: 0,
        paddingBottom: hasNotes ? '4px' : '12px',
      }}
    >
      <div style={transcriptPdfStyles.informationRow}>
        <div>Grade: {grade}th</div>
        <div
          style={{
            position: 'absolute',
            left: '210px',
          }}
        >
          {transcriptYear?.academicYear ?? ''}-
          {transcriptYear
            ? transcriptYear?.academicYear
              ? transcriptYear.academicYear + 1
              : ''
            : ''}
        </div>
      </div>
      <div
        style={{
          ...transcriptPdfStyles.informationRow,
          textDecoration: 'underline',
        }}
      >
        <div style={{ fontFamily: boldFont }}>Class</div>
        <div style={{ paddingLeft: '118px', fontFamily: boldFont }}>Grade</div>
        <div style={{ paddingLeft: '55px', fontFamily: boldFont }}>Credits</div>
      </div>
      <div>
        {transcriptYear?.transcriptYearCourseWork?.map((course, index) => {
          return (
            <div
              style={transcriptPdfStyles.informationRow}
              key={(course.transcriptYearCourseWorkKey ?? index).toString()}
            >
              <div style={{ maxWidth: '130px' }}>{course.courseName}</div>
              <div style={{ position: 'absolute', left: '150px' }}>
                {course.letterGrade}
              </div>
              <div style={{ position: 'absolute', left: '238px' }}>
                {course.credits.toFixed(1)}
              </div>
            </div>
          )
        })}
      </div>
      <div
        style={{
          flexDirection: 'row',
        }}
      >
        <div
          style={{ fontFamily: boldFont, position: 'absolute', left: '105px' }}
        >
          GPA
        </div>
        <div
          style={{ position: 'absolute', left: '142px', fontFamily: boldFont }}
        >
          {yearGPA}
        </div>
        <div
          style={{
            position: 'absolute',
            left: '176px',
            fontFamily: boldFont,
            width: '100%',
          }}
        >
          Total Credits
        </div>
        <div
          style={{
            position: 'absolute',
            left: '233px',
            fontFamily: boldFont,
          }}
        >
          {yearCredits}
        </div>
      </div>
      <div style={{ marginTop: 'auto' }}>
        {hasNotes && (
          <div style={{ paddingTop: '1px', fontFamily: boldFont }}>NOTES:</div>
        )}
        <div style={{ paddingTop: '2px' }}>{transcriptYear?.notes ?? ''}</div>
      </div>
    </div>
  )
}

export default ClassInformationPDF
